import { action } from 'typesafe-actions'

import { SubscriptionOptionsActionConsts } from './types'

import { SubscriptionOptionsData } from '../../../interfaces/subscription-options'

export const getSubscriptionOptionsPending = () =>
  action(SubscriptionOptionsActionConsts.GET_SUBSCRIPTION_OPTIONS_PENDING)

export const getSubscriptionOptionsFulfilled = (subscriptionOptions: SubscriptionOptionsData) =>
  action(SubscriptionOptionsActionConsts.GET_SUBSCRIPTION_OPTIONS_FULFILLED, subscriptionOptions)

export const getSubscriptionOptionsFailed = (error: string) =>
  action(SubscriptionOptionsActionConsts.GET_SUBSCRIPTION_OPTIONS_FAILED, error)
