import React from 'react'

import { IconList } from './list'

import { IconProps } from '../../interfaces'
import { ButtonIcon, IconContainer, Label } from '../../styled'

export default function Icon ({ name, data, onClose, onClick }: IconProps) {
  const { path, viewBox = '0 0 24 24', color, e } = IconList[name]

  const handleOnButtonClicked = () => {
    onClick?.(name)
    e(encodeURIComponent(data.url), data.text, data.title)
    onClose()
  }

  return (
    <IconContainer>
      <ButtonIcon type="button" onClick={handleOnButtonClicked} aria-label={name} color={color}>
        <svg fill="white" viewBox={viewBox}>
          {path}
        </svg>
      </ButtonIcon>
      <Label>{name}</Label>
    </IconContainer>
  )
}
