import { Reducer } from 'redux'

import { SubscriptionCodesActionTypes, SubscriptionCodesState, SubscriptionCodesActionConsts } from './types'

import { SignOutActionType } from '../auth'

export const initialState: SubscriptionCodesState = {
  list: [],
  isFetching: false,
  isValid: undefined,
  error: ''
}

export const subscriptionCodeReducer:
Reducer<SubscriptionCodesState, SubscriptionCodesActionTypes | SignOutActionType> = (
  state = initialState,
  action
): SubscriptionCodesState => {
  switch (action.type) {
    case SubscriptionCodesActionConsts.GET_SUBSCRIPTION_CODE_PENDING:
      return {
        ...state,
        isFetching: true
      }
    case SubscriptionCodesActionConsts.GET_SUBSCRIPTION_CODE_FULFILLED:
      return {
        ...state,
        isFetching: false,
        list: action.payload
      }
    case SubscriptionCodesActionConsts.GET_SUBSCRIPTION_CODE_FAILED:
      return {
        ...state,
        isFetching: false,
        error: action.payload
      }
    case SubscriptionCodesActionConsts.CREATE_SUBSCRIPTION_CODE_PENDING:
      return {
        ...state,
        isFetching: true
      }
    case SubscriptionCodesActionConsts.CREATE_SUBSCRIPTION_CODE_FULFILLED:
      return {
        ...state,
        isFetching: false,
        list: [action.payload, ...state.list]
      }
    case SubscriptionCodesActionConsts.CREATE_SUBSCRIPTION_CODE_FAILED:
      return {
        ...state,
        isFetching: false,
        error: action.payload
      }
    case SubscriptionCodesActionConsts.VERIFY_SUBSCRIPTION_CODE_PENDING:
      return {
        ...state,
        isFetching: true
      }
    case SubscriptionCodesActionConsts.VERIFY_SUBSCRIPTION_CODE_FULFILLED:
      return {
        ...state,
        isFetching: false,
        isValid: action.payload
      }
    case SubscriptionCodesActionConsts.VERIFY_SUBSCRIPTION_CODE_FAILED:
      return {
        ...state,
        isFetching: false,
        isValid: false,
        error: action.payload
      }
    default:
      return state
  }
}
