import styled from 'styled-components/native'

import Caption from '@hedgit/lib/components/typography/caption'

export const ChartContent = styled.View`
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  width: 140px;
  height: 192px;
  margin: 10px;
`

export const CircleContent = styled.View`
  margin-top: 14px;
  align-items: center;
  width: 206px;
  height: 105px;
`

export const ChartTextContent = styled.View`
  text-align: center;
  color: #313C4D;
  width: 130px;
`

export const LegendText = styled(Caption)`
  flex-wrap: wrap;
  width: 130px;
  color: #838A94;
  line-height: 17px;
  text-align: justify;
`
