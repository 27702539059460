import { useToast } from 'react-native-toast-notifications'
import { useTranslation } from 'react-i18next'

import { Status } from '@hedgit/lib/enums/status'

import { useSelector } from 'store'

const useSuspendedToast = () => {
  const status = useSelector((state) => state.auth.currentUser?.status)
  const toast = useToast()
  const { t } = useTranslation()

  const isSuspended = status === Status.suspended

  const showToast = (message: string) => {
    toast.show(message, {
      type: 'success',
      successColor: 'gray',
      placement: 'bottom',
      duration: 6000,
      animationType: 'slide-in'
    })
  }

  const showSuspendedBrokerToast = () => {
    showToast(t('Suspended.ToastMessage'))
  }

  const showSuspendedFarmerToast = () => {
    showToast(t('Suspended.ToastMessage'))
  }

  return {
    isSuspended,
    showSuspendedBrokerToast,
    showSuspendedFarmerToast
  }
}

export default useSuspendedToast
