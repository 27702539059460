import styled from 'styled-components/native'

import Subtitle from '../../typography/subtitle'
import { theme } from '../../../theme'

interface ColorProps {
  color: string;
}

interface VisibilityProps {
  visibility?: 'visible' | 'hidden';
}

export const Content = styled.View`
  border-radius: 5px;
  justify-content: center;
  align-self: center;
  margin-top: 10px;
  width: -webkit-fill-available;
`

export const CardContainer = styled.View`
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 40px;
  border-radius: 15px;
  background-color: ${theme.colors.white};
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
`

export const Code = styled.Text<ColorProps>`
  font-size: 18px;
  font-weight: 900;
  font-family: 'Lato';
  margin-right: 2px;
  margin-left: 2px;
  text-align: center;
  flex: 1;
  color: ${props => props.color};
`
export const Status = styled.Text<ColorProps>`
  font-size: 13px;
  text-transform: lowercase;
  font-weight: 900;
  font-family: 'Lato';
  margin-right: 2px;
  margin-left: 2px;
  text-align: center;
  flex: 1;
  color: ${props => props.color};
`

export const DateText = styled(Subtitle)<VisibilityProps>`
  font-size: 7px;
  font-weight: 400;
  text-align: center;
  align-self: center;
  flex: 1;
  color: ${props => props.theme.colors.dark};
  visibility: ${props => props.visibility};
`

export const ExpirationDateCodeContainer = styled.View<VisibilityProps>`
  flex-direction: row;
  text-align: center;
  flex: 1.3;
  visibility: ${props => props.visibility};
`

export const ShareButtonContainer = styled.TouchableOpacity<VisibilityProps>`
  margin-right: 5px;
  margin-left: 5px;
  visibility: ${props => props.visibility};
`
