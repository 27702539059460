import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

const formFields = [
  'firstName',
  'lastName',
  'email',
  'country',
  'state',
  'city',
  'postalCode',
  'address'
]

export const useFormFields = () => {
  const { t } = useTranslation()

  const userFields = useMemo(() => ([
    {
      name: 'firstName' as const,
      testID: 'set-firstName-input',
      label: t('Settings.form.firstName.label'),
      placeholder: t('Settings.form.firstName.placeholder')
    },
    {
      name: 'lastName' as const,
      testID: 'set-lastName-input',
      label: t('Settings.form.lastName.label'),
      placeholder: t('Settings.form.lastName.placeholder')
    },
    {
      name: 'email' as const,
      testID: 'set-email-input',
      label: t('Settings.form.email.label'),
      placeholder: t('Settings.form.email.placeholder'),
      keyboardType: 'email-address' as const
    }
  ]), [t])

  const billingInformationFields = useMemo(() => ([
    {
      name: 'billingInformation.country' as const,
      testID: 'set-country-input',
      label: t('Settings.form.country.label'),
      placeholder: t('Settings.form.country.placeholder'),
      disabled: true
    },
    {
      name: 'billingInformation.state' as const,
      testID: 'set-state-input',
      label: t('Settings.form.state.label'),
      placeholder: t('Settings.form.state.placeholder'),
      disabled: true
    },
    {
      name: 'billingInformation.city' as const,
      testID: 'set-city-input',
      label: t('Settings.form.city.label'),
      placeholder: t('Settings.form.city.placeholder'),
      disabled: true
    },
    {
      name: 'billingInformation.postalCode' as const,
      testID: 'set-postalCode-input',
      label: t('Settings.form.postalCode.label'),
      placeholder: t('Settings.form.postalCode.placeholder'),
      keyboardType: 'numeric' as const,
      disabled: true
    },
    {
      name: 'billingInformation.address' as const,
      testID: 'set-address-input',
      label: t('Settings.form.address.label'),
      placeholder: t('Settings.form.address.placeholder'),
      disabled: true
    }
  ]), [t])

  return {
    userFields,
    billingInformationFields,
    formFields
  }
}
