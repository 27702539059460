/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import { XAxis, AreaChart, LineChart } from 'react-native-svg-charts'
import { View } from 'react-native'
import { theme } from '@hedgit/lib/theme'
import { Defs, LinearGradient, Stop } from 'react-native-svg'

import {
  LegendHistoricalText,
  LegendHistoricalDot,
  LegendHistoricalContainer
} from './styled'
import { Dots } from './dots'

import { Line } from '../../components/line'

interface LineChartProps {
  data: any;
  dates: any;
  data2: any;
  algorithm: string;
}

const lightBlue = theme.colors.primary + '80'
const Gradient = () => (
  <Defs key="gradients">
    <LinearGradient id="gradients" x1="0%" y1="0%" x2="0%" y2="100%">
      <Stop offset="0%" stopColor={lightBlue} stopOpacity={0.7} />
      <Stop offset="40%" stopColor={lightBlue} stopOpacity={0.0} />
    </LinearGradient>
  </Defs>
)

export const HistoricalPerformanceAlgorithmChart = ({
  data = [],
  dates = [],
  data2 = [],
  algorithm
} :LineChartProps) => {
  const max = Math.max(...data, ...data2)
  const min = Math.min(...data, ...data2)

  return (
    <View>
      <LegendHistoricalContainer>
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <LegendHistoricalDot iconColor={theme.colors.yellowDark} />
          <LegendHistoricalText>PROMEDIO</LegendHistoricalText>
        </View>
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <LegendHistoricalDot iconColor={theme.colors.lightBlue} />
          <LegendHistoricalText>{(algorithm).toUpperCase()}</LegendHistoricalText>
        </View>
      </LegendHistoricalContainer>
      <LineChart
        style={{
          height: 210,
          top: 35
        }}
        gridMin={min - 10}
        gridMax={max}
        yMax={max + 20}
        xMax={9}
        data={data2}
        contentInset={{ top: 10, bottom: 10, left: 32, right: 32 }}
      >
        <Line color={theme.colors.yellowDark} />
      </LineChart>
      <AreaChart
        style={{
          height: 210,
          position: 'absolute',
          top: 35,
          left: 0,
          bottom: 0,
          right: 0
        }}
        gridMin={min - 10}
        gridMax={max}
        yMax={max + 20}
        xMax={9}
        svg={{
          fill: 'url(#gradients)'
        }}
        data={data}
        contentInset={{ top: 10, bottom: 10, left: 32, right: 32 }}
      >
        <Line color={theme.colors.primary} />
        <Dots />
        <Gradient />
      </AreaChart>
      <XAxis
        style={{
          borderTopColor: theme.colors.lightGray,
          borderTopWidth: 1,
          marginBottom: 4,
          marginLeft: 17,
          marginRight: 17,
          paddingTop: 8,
          top: 35
        }}
        data={dates || []}
        formatLabel={(index) => {
          return dates[index]
        }}
        contentInset={{ left: 15, right: 15 }}
        svg={{ fontFamily: 'lato', fontSize: 10, fill: theme.colors.blue }}
      />
    </View>
  )
}
