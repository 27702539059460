import React, { useEffect, useCallback, useState } from 'react'
import { ActivityIndicator } from 'react-native'
import { FormControl, WarningOutlineIcon, ScrollView } from 'native-base'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import { NativeStackScreenProps } from '@react-navigation/native-stack'
import { Controller, useForm } from 'react-hook-form'
import useCustomToast from '@hedgit/web/src/hooks/use-custom-toast'
import * as yup from 'yup'
import { theme } from '@hedgit/lib/theme'

import { createSubscriptionByCode, getSubscriptions } from '@hedgit/lib/store/modules/subscriptions/thunks'

import { RootStackParamList } from '@hedgit/lib/interfaces/root-stack-params-list'

import { SharedInput } from '@hedgit/lib/components/shared/input'
import { CardSubscriptionPlanValid } from '@hedgit/lib/components/cards/subscription-plan-valid'
import { SubscriptionSuccessModal } from '@hedgit/lib/components/modals/subscription-success-modal'

import { useSelector, useThunkDispatch } from 'store'

import {
  Container,
  InputContainer,
  Button,
  Subtitle,
  Broker,
  SubContent,
  ButtonContainer,
  Image,
  ImageContainer,
  ViewContent
} from './styled'

type Props = NativeStackScreenProps<RootStackParamList, 'SubscriptionCodeValid'>

interface FormData {
  tons: number;
  code: string;
}

const { colors } = theme

const filterCharacter = (number: string | undefined) => {
  const filterCharacter = number?.replace(/[^\d]/g, '')
  return filterCharacter
}

const SubscriptionCodeValid = ({ route, navigation }: Props) => {
  const { pricingProgramId, code, broker, name } = route.params
  const { t } = useTranslation()

  const dispatch = useThunkDispatch()
  const { showToast } = useCustomToast()

  const pricingProgram = useSelector(state => state.pricingPrograms.list)
  const selectedPricingProgram = pricingProgram.find(pp => pp.id === pricingProgramId)
  const isLoading = useSelector(state => state.subscriptions.isFetching)
  const role = useSelector(state => state.auth.currentUser?.role)
  const steps = selectedPricingProgram?.algorithm.steps || 0
  const multiple = steps * 20
  const [openModal, setOpenModal] = useState<boolean>(false)

  const validationSchema = yup.object({
    tons: yup
      .number()
      .required(t('ErrorMessages.tons.required'))
      .positive(t('ErrorMessages.tons.positive'))
      .integer(t('ErrorMessages.tons.integer'))
      .typeError(t('ErrorMessages.tons.typeError'))
      .test({
        name: 'tons-multiple-validation',
        test: async (value, testContext) => {
          if (value && (value % multiple === 0)) return true
          return testContext.createError({ message: t('ErrorMessages.tons.test') + `${multiple}` })
        }
      })
  })

  const {
    control,
    handleSubmit: handleSubmitForm,
    formState: { errors },
    reset,
    clearErrors
  } = useForm<FormData>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: {
      tons: undefined
    },
    resolver: yupResolver(validationSchema)
  })

  const formReset = useCallback(() => {
    reset({
      tons: undefined
    })
  }, [reset])

  useEffect(() => {
    formReset()
  }, [formReset])

  const handleSubmit = async (data: FormData) => {
    const { tons } = data
    const { success } = await dispatch(
      createSubscriptionByCode({
        pricingProgram: pricingProgramId,
        code: code,
        tons: tons
      })
    )
    if (success) {
      await dispatch(getSubscriptions(role))
      return setOpenModal(true)
    }
    showToast(t('AlgorithmSubscription.subscriptionCode.error'), 'danger')
  }

  const onSuccess = () => {
    setOpenModal(false)
    navigation.navigate('NavigationTabs', { screen: 'PricingPrograms' })
  }

  return (
    <Container>
      <SubContent>
        <ScrollView>
          <ViewContent>
            <Subtitle>{t('AlgorithmSubscription.subscriptionCode.subtitle')}</Subtitle>
            <Broker>{broker}</Broker>
            <CardSubscriptionPlanValid />
            <InputContainer>
              <FormControl isInvalid={'tons' in errors}>
                <Controller
                  name="tons"
                  control={control}
                  render={({ field: { onChange, value, onBlur } }) => (
                    <SharedInput
                      testID="tons-input"
                      placeholder={t('AlgorithmSubscription.form.volume.placeholder')}
                      value={filterCharacter(value?.toString())}
                      maxLength={20}
                      onChange={onChange}
                      onFocus={() => clearErrors('tons')}
                      onBlur={onBlur}
                      withLabel={false}
                      keyboardType="numeric"
                    />
                  )}
                />
                <FormControl.ErrorMessage testID="als-volumeError-message" leftIcon={<WarningOutlineIcon size="xs" />}>
                  {errors.tons?.message}
                </FormControl.ErrorMessage>
              </FormControl>
            </InputContainer>
            <ButtonContainer disabled={isLoading}>
              <Button testID="als-subscribe-button" onPress={handleSubmitForm(handleSubmit)} disabled={isLoading}>
                {isLoading
                  ? <ActivityIndicator color={colors.white} />
                  : t('Components.button.subscribe')}
              </Button>
              <ImageContainer>
                <Image source={require('@hedgit/lib/components/sidebar/assets/icons/hedgit-icon-sm.png')} />
              </ImageContainer>
            </ButtonContainer>
          </ViewContent>
        </ScrollView>
      </SubContent>
      <SubscriptionSuccessModal
        brokerName={broker}
        algorithmName={name}
        onPress={onSuccess}
        visible={openModal}
        isXpPremium={false}
      />
    </Container>
  )
}

export default SubscriptionCodeValid
