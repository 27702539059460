import React from 'react'

import { Container, Icon, CardText } from './styled'

import AlgorithmIcon from '../../icons/algorithm'
import PricingProgramIcon from '../../icons/pricing-program'
import PricingSignalIcon from '../../icons/pricing-signal'
import { Entity } from '../../../enums/entity'
import { theme } from '../../../theme'
import NotificationsBellIcon from '../../icons/notifications-bell'
import SubsPlan from '../../icons/subs-plan'

interface EmptyCardProps {
  text?: string;
  entity: Entity;
  children?: React.ReactNode;
}

const { colors } = theme

const getIcon = (entity: Entity) => {
  if (entity === Entity.algorithm) {
    return (
      <AlgorithmIcon color={colors.lightGray} />
    )
  }
  if (entity === Entity.pricingProgram) {
    return (
      <PricingProgramIcon color={colors.lightGray} />
    )
  }
  if (entity === Entity.pricingSignal) {
    return (
      <PricingSignalIcon color={colors.lightGray} />
    )
  }
  if (entity === Entity.notifications) {
    return (
      <NotificationsBellIcon color={colors.lightGray} width={25} height={30} />
    )
  }
  if (entity === Entity.planType) {
    return (
      <SubsPlan />
    )
  }
  return null
}

const EmptyCard = ({
  text,
  entity,
  children
}: EmptyCardProps) => {
  return (
    <Container>
      <Icon>
        {getIcon(entity)}
      </Icon>
      <CardText>
        {children || `${text}`}
      </CardText>
    </Container>
  )
}

export default EmptyCard
