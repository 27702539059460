import styled from 'styled-components/native'

import Subtitle from '@hedgit/lib/components/typography/subtitle'

export const SectionTitle = styled(Subtitle)`
  padding: 4px 0px;
  border-bottom-width: 1px;
  border-bottom-color: #e9ebed;
  border-bottom-style: solid;
`

export const Button = styled.Button`
  font-size: 56px;
  font-weight: bold;
  padding: 10px;
`

export const Content = styled.View`
  background-color: #FFF;
  justify-content: center;
  flex: 1;`

export const SubContent = styled.View`
  justify-content: center;
  align-self: center;
  height: 100%;
  width: 100%;
  max-width: 600px;
`
