import React from 'react'
import { StyleProp, ViewStyle } from 'react-native'
import { useTranslation } from 'react-i18next'
import { getDate } from '@hedgit/lib/translations/getTranslation'

import {
  PricingCard,
  PricingCardContent,
  PSContentContainer,
  PricingCardContentBox,
  PSContentDoubles,
  PricingCardContentSimple,
  getPSStatusColor,
  CardBodyTitle,
  CardBodyText,
  CardBodyTitleSmall
} from './styled'
import CardHeader from './components/card-header'
import CardFooter from './components/card-footer'

import { GetMonthName } from '../../utils/format'
import { PricingSignalStatus } from '../../enums/pricing-signal-status'
import { PricingSignal, BrokerPricingSignal } from '../../interfaces/pricing-signal'
import { PricingProgramStatus } from '../../enums/pricing-program-status'
import { UserRole } from '../../enums/user-role'
import { getTranslation } from '../../translations/getTranslation'

interface Props {
  role: UserRole;
  state: PricingSignalStatus;
  programStatus?: PricingProgramStatus;
  pricingSignal?: PricingSignal | BrokerPricingSignal;
  gen?: number;
}

const PricingSignalCard = ({
  role,
  state,
  programStatus,
  pricingSignal,
  gen
}: Props) => {
  const { t } = useTranslation()

  const productLabel = [
    getTranslation(pricingSignal.subscription.pricingProgram.product.crop.name),
    GetMonthName(pricingSignal.subscription.pricingProgram.product.month),
    pricingSignal.subscription.pricingProgram.product.year
  ].join(' ')

  const color = getPSStatusColor(state, programStatus, gen)

  const tons = pricingSignal.tons

  const dateObj = new Date(pricingSignal.date)
  dateObj.setTime(dateObj.getTime() + dateObj.getTimezoneOffset() * 60 * 1000)

  const signalDate = getDate(dateObj)

  const algorithmName = getTranslation(pricingSignal.subscription.pricingProgram.algorithm.name).toUpperCase()

  const hasSignalsPending = (pricingSignal as BrokerPricingSignal).signals?.some(s => !s.executionPrice)
  const hasSignalsExecuted = (pricingSignal as BrokerPricingSignal).signals?.some(s => s.executionPrice)

  return (
    <PricingCard
      testID={`ps-${pricingSignal.id}-card`}
      state={pricingSignal.subscription.status}
      signalsPending={hasSignalsPending}
      signalsExecuted={hasSignalsExecuted}
    >
      <CardHeader
        role={role}
        product={productLabel}
        subtitle={signalDate}
        cardType="pricing-signal"
        iconColor={color}
        broker={pricingSignal.subscription.broker.lastName || pricingSignal.subscription.broker.firstName}
        tons={tons}
      />
      <PricingCardContent>
        <PSContentContainer
          signalStatus={state}
          programStatus={programStatus}
          gen={gen}
        >
          <PSContentDoubles style={{ borderRight: '1px solid white', padding: '15px' } as StyleProp<ViewStyle>}>
            <PricingCardContentBox>
              {
                algorithmName.length < 13
                  ? (
                    <CardBodyTitle>
                      {algorithmName}
                    </CardBodyTitle>
                    )
                  : (
                    <CardBodyTitleSmall>
                      {algorithmName}
                    </CardBodyTitleSmall>
                    )
              }
              <CardBodyText>
                {t('Components.card.algorithm').toUpperCase()}
              </CardBodyText>
            </PricingCardContentBox>
          </PSContentDoubles>
          <PSContentDoubles>
            <PricingCardContentBox>
              <CardBodyTitle>
                {t('Components.card.currency').toUpperCase()}
                {' '}
                {parseFloat(pricingSignal.referencePrice?.toFixed(2))}
              </CardBodyTitle>
              <CardBodyText>
                {t('Components.card.referencePrice').toUpperCase()}
              </CardBodyText>
            </PricingCardContentBox>
          </PSContentDoubles>
          <PricingCardContentSimple signalStatus={state} programStatus={programStatus} gen={gen}>
            <PricingCardContentBox>
              {
                state === 'pending'
                  ? (
                    <CardBodyTitle>
                      {t('Components.card.pending').toUpperCase()}
                    </CardBodyTitle>
                    )
                  : (
                      (
                        <CardBodyTitle>
                          {t('Components.card.currency').toUpperCase()}
                          {' '}
                          {pricingSignal.executionPrice}
                        </CardBodyTitle>
                      )
                    )
              }
              <CardBodyText>
                {t('Components.card.executionPrice').toUpperCase()}
              </CardBodyText>
            </PricingCardContentBox>
          </PricingCardContentSimple>
        </PSContentContainer>
      </PricingCardContent>
      <CardFooter
        entityType='pricing-signal'
        status={state}
        color={color}
        startDate={pricingSignal.subscription.pricingProgram.startDate}
        endDate={pricingSignal.subscription.pricingProgram.endDate}
      />
    </PricingCard>
  )
}

export default PricingSignalCard
