import { PlanName } from '../../../enums/plan-name'
import { theme } from '../../../theme'

export const planMap : {[K in PlanName]?: {color: string}} = {
  [PlanName.xpExperimental]: { color: theme.colors.blueGray },
  [PlanName.xpExperimentalPlus]: { color: theme.colors.blueGray },
  [PlanName.xpOriginator]: { color: theme.colors.greenLightTwo },
  [PlanName.xpOriginatorPlus]: { color: theme.colors.greenLightTwo },
  [PlanName.xpProfessional]: { color: theme.colors.primary },
  [PlanName.xpProfessionalPlus]: { color: theme.colors.primary },
  [PlanName.xpPremium]: { color: theme.colors.orange }
}
