import styled from 'styled-components'

export const ButtonSharer = styled.button`
  border: none;
  outline: none;
  background: none;
  padding: 0;
  cursor: pointer;
`

export const ButtonClose = styled.button`
  background: #edf2f7;
  cursor: pointer;
  padding: 0.75rem;
  display: block;
  width: 100%;
  border: 0;
  font-size: 1rem;
  font-family: 'Lato';
`

export const BackDropContainer = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  z-index: 1400;
  animation: rwsFade 0.5s;
  animation-fill-mode: both;

  @keyframes rwsFade {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`

export const HeaderModal = styled.header`
  padding: 1rem 1.25rem;
  font-size: 10px;
  font-size: 1rem;
  font-weight: 600;
  padding-bottom: 0;
  font-family: 'Lato';
`

export const SocialIconsContainer = styled.section`
  max-width: 24rem;
  width: 90%;
  background-color: white;
  border-radius: 0.5rem;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  animation: rwsfadeInUp 0.5s;
  animation-fill-mode: both;

  @keyframes rwsfadeInUp {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
`

export const SocialIconModal = styled.div`
  padding: 35px;
  display: flex;
  justify-content: space-evenly;
  gap: 1.25rem;
`

export const IconContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  text-align: center;
  align-items: center;
`

export const ButtonIcon = styled.button`
  width: 45px;
  height: 45px;
  cursor: pointer;
  border: 0;
  background: #1a78f6;
  padding: 0.75rem;
  border-radius: 0.5rem;
  font-size: 0;
  background-color: ${props => props.color};
`

export const Label = styled.h1`
  text-transform: capitalize;
  margin-top: 15px;
  font-weight: 300;
  font-size: 12px;
  font-family: 'Lato';
`
