import React, { memo, useCallback, useMemo } from 'react'

import { Backdrop } from './components/backdrop'
import { Portal } from './components/portal'
import { SocialIcons } from './components/social-icons'
import { useDisclosure } from './hooks/use-disclosure'
import { RWebShareProps, ValidSites } from './interfaces'
import { ButtonSharer } from './styled'

const defaultSites: ValidSites[] = ['mail', 'copy', 'whatsapp']

export const RWebShare = memo(({ children, closeText, data, sites, onClick, disableNative }: RWebShareProps) => {
  const { onOpen, onClose, isOpen } = useDisclosure()

  const shareData = useMemo(
    () => ({
      ...data,
      title: data.title || 'share',
      text: data.text || '',
      url: data.url || ''
    }),
    [data]
  )

  const handleOnClick = useCallback(async () => {
    if (window.navigator.share && !disableNative) {
      try {
        await window.navigator.share({
          title: shareData.title,
          text: shareData.text + '\n' + shareData.url,
          url: undefined
        })
        if (onClick) {
          onClick()
        }
      } catch (e) {
        console.warn(e)
      }
    } else {
      onOpen()
    }
  }, [disableNative, onClick, onOpen, shareData])

  return (
    <>
      <ButtonSharer type="button" onClick={handleOnClick} data-testid="share-component">
        {children}
      </ButtonSharer>

      {isOpen && (
        <Portal>
          <Backdrop onClose={onClose}>
            <SocialIcons
              onClose={onClose}
              sites={sites || defaultSites}
              data={shareData}
              closeText={closeText}
              onClick={onClick}
            />
          </Backdrop>
        </Portal>
      )}
    </>
  )
})
