import styled from 'styled-components/native'
import { theme } from '@hedgit/lib/theme'

import Title from '@hedgit/lib/components/typography/title'
import Caption from '@hedgit/lib/components/typography/caption'
import Button from '@hedgit/lib/components/typography/button-text'

export const Card = styled.View`
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 3px 6px #0000001A;
  margin-bottom: 6px;
  margin-top: 6px;
  width: 100%;
  height: 70px;
`
export const CardContent = styled.View`
  margin-top: 10px;
  margin-right: 19px;
  margin-left: 19px;
  flex-direction: row;
  justify-content: space-between;
`

export const AlgorithmName = styled(Title)`
  font-size: 18px;
`

export const AlgorithmDetailsText = styled(Caption)`
  margin-top: -5px;
`

export const SeeBox = styled.View`
  margin-top: 9px;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  background-color: ${theme.colors.primary};;
  width: 71px;
  height: 32px;
`

export const SeeText = styled(Button)`
  color: ${props => props.theme.colors.white};
`

export const DetailsContent = styled.View`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 2px;
`

export const DetailsText = styled.View`
  padding-left: 8px;
`
