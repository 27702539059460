import styled from 'styled-components/native'

export const Content = styled.View`
  background-color: #FFF;
  justify-content: center;
  flex: 1;
  overflow: visible;
`

export const SubContent = styled.View`
  justify-content: center;
  height: 100%;
  width: 100%;
  overflow: visible;
`
