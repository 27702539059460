import { ActionType } from 'typesafe-actions'

import * as actions from './actions'

export enum SubscriptionOptionsActionConsts {
  GET_SUBSCRIPTION_OPTIONS_PENDING='GET_SUBSCRIPTION_OPTIONS_PENDING',
  GET_SUBSCRIPTION_OPTIONS_FULFILLED='GET_SUBSCRIPTION_OPTIONS_FULFILLED',
  GET_SUBSCRIPTION_OPTIONS_FAILED='GET_SUBSCRIPTION_OPTIONS_FAILED',
}

export type SubscriptionOptionsActionTypes = ActionType<typeof actions>

export interface SubscriptionOptionsState {
  readonly hasPremiumXp: boolean;
  readonly premiumXpName?: string;
  readonly isFetching: boolean;
  readonly error: string;
}
