import React, { useEffect, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { View } from 'react-native'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormControl } from 'native-base'
import { useTranslation } from 'react-i18next'
import { theme } from '@hedgit/lib/theme'
import { HeaderLeftButton } from '@hedgit/web/src/components/header-left-button'
import { useThunkDispatch } from '@hedgit/web/src/store'
import useCustomToast from '@hedgit/web/src/hooks/use-custom-toast'

import { cleanVerifyAreaCode, signOutThunk } from '@hedgit/lib/store/modules/auth'

import { UpdateFarmerSchema } from '@hedgit/lib/utils/validations/user'

import { User } from '@hedgit/lib/interfaces/user'

import { SharedInput } from '@hedgit/lib/components/shared/input'
import { SharedButton } from '@hedgit/lib/components/shared/button'
import ErrorIcon from '@hedgit/lib/components/icons/error'
import H2 from '@hedgit/lib/components/typography/h2'

import {
  Container,
  InputsContainer,
  Requirements,
  ButtonContainer,
  Error,
  UserIcon,
  StyledBackground,
  Header,
  BackButtonContainer,
  UserText,
  AreaCodeAndPhoneContainer,
  AreaCodeContainer,
  PhoneContainer,
  CustomErrorWrapper,
  CustomErrorIconWrapper,
  CustomErrorText,
  FieldContainer
} from './styled'

import PencilIcon from '../icons/pencil'
import { phoneRegex } from '../../utils/regex'
import { post } from '../../utils/axios'
import { APIResponse } from '../../types/api'

export interface SettingsProps {
  error?: string;
  isFetching?: boolean;
  onSubmit: (data: User) => void;
  currentUser: User;
  clearError?: () => void;
}

export const formatNumber = (number: string) => {
  const formattedNumber = number.replace(/[^\d.]/g, '')
  return formattedNumber
}

const { colors } = theme

interface VerifyPhoneNumbersResponse {
  alreadyUsedPhoneNumbers: {
    phone: string;
  };
}

const SettingsFarmer = ({
  onSubmit,
  error,
  isFetching,
  currentUser,
  clearError
}: SettingsProps) => {
  const { t } = useTranslation()

  const [isPhoneChanged, setIsPhoneChanged] = useState(false)

  const dispatch = useThunkDispatch()
  const { showToast } = useCustomToast()

  const {
    control,
    handleSubmit: handleSubmitForm,
    formState: { errors, isDirty, isValid },
    clearErrors,
    watch,
    reset,
    setError
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      areaCode: ''
    },
    resolver: yupResolver(UpdateFarmerSchema)
  })

  const areaCode = watch('areaCode')
  const phone = watch('phone')

  useEffect(() => {
    return () => { dispatch(cleanVerifyAreaCode()) }
  }, [dispatch])

  const handleSubmit = async (data: User) => {
    try {
      clearErrors()
      const phoneNumber = `+549${data.areaCode}${data.phone}`

      const {
        data: { alreadyUsedPhoneNumbers }
      } = await post<APIResponse<VerifyPhoneNumbersResponse>>(
        '/auth/verify-phone-numbers',
        { phones: [phoneNumber || []] }
      )

      if (Object.values(alreadyUsedPhoneNumbers).length) {
        setError('phone', {
          type: 'value',
          message: t('Settings.alreadyUsedPhoneNumber')
        })
        showToast(t('Settings.updateFarmer.error'), 'danger')
      } else {
        await onSubmit({ ...data, phone: phoneNumber })
        const isPhoneNumberChanged = currentUser.phone !== phoneNumber
        if (isPhoneNumberChanged) {
          setIsPhoneChanged(true)
        } else {
          showToast(t('Settings.updateFarmer.success'), 'success')
        }
      }
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    if (isPhoneChanged) {
      dispatch(signOutThunk())
    }
  }, [isPhoneChanged, dispatch])

  useEffect(() => {
    if (currentUser) {
      reset({
        firstName: currentUser.firstName || '',
        lastName: currentUser.lastName || '',
        email: currentUser.email || '',
        areaCode: currentUser.areaCode || '',
        phone: currentUser.phone.replace('+549', '').slice(currentUser.areaCode.length) || ''
      })
    }
  }, [currentUser, reset])

  const handlePhoneInputFocus = () => {
    clearErrors('phone')
    clearError()
  }

  const initials = currentUser.firstName[0] + (currentUser.lastName[0] || currentUser.firstName[1])

  return (
    <View>
      <Header>
        <BackButtonContainer>
          <HeaderLeftButton />
        </BackButtonContainer>
        <H2>{t('Settings.title')}</H2>
      </Header>
      <StyledBackground />
      <Container>
        <UserIcon><UserText>{initials}</UserText></UserIcon>
        <InputsContainer>
          <FieldContainer>
            <FormControl isInvalid={'firstName' in errors}>
              <Controller
                name="firstName"
                control={control}
                defaultValue=""
                render={({ field: { value, onChange, onBlur } }) => (
                  <SharedInput
                    testID="su-farmer-firstName-input"
                    withLabel
                    onChange={onChange}
                    onFocus={() => clearErrors('firstName')}
                    onBlur={onBlur}
                    value={value}
                    keyboardType="default"
                    label={t('Settings.form.firstName.label')}
                    placeholder={t('SignUpFarmer.form.firstName.placeholder')}
                    endAdornment={<PencilIcon width={24} height={24} color='#adb1b8' />}
                  />
                )}
              />
              <FormControl.ErrorMessage
                testID="su-farmer-firstName-error-message"
                leftIcon={<ErrorIcon width={14} height={14} color={colors.red} />}
                _text={{ style: { color: colors.red, fontSize: 12, fontFamily: 'Lato' } }}
              >
                {errors.firstName?.message}
              </FormControl.ErrorMessage>
            </FormControl>
          </FieldContainer>
          <FieldContainer>
            <FormControl isInvalid={'lastName' in errors}>
              <Controller
                name="lastName"
                control={control}
                render={({ field: { value, onChange, onBlur } }) => (
                  <SharedInput
                    testID="su-farmer-lastName-input"
                    withLabel
                    onChange={onChange}
                    onFocus={() => clearErrors('lastName')}
                    onBlur={onBlur}
                    value={value}
                    keyboardType="default"
                    label={t('Settings.form.lastName.label')}
                    placeholder={t('SignUpFarmer.form.lastName.placeholder')}
                    endAdornment={<PencilIcon width={24} height={24} color='#adb1b8' />}
                  />
                )}
              />
              <FormControl.ErrorMessage
                testID="su-farmer-lastName-error-message"
                leftIcon={<ErrorIcon width={14} height={14} color={colors.red} />}
                _text={{ style: { color: colors.red, fontSize: 12, fontFamily: 'Lato' } }}
              >
                {errors.lastName?.message}
              </FormControl.ErrorMessage>
            </FormControl>
          </FieldContainer>
          <FieldContainer>
            <FormControl isInvalid={'email' in errors}>
              <Controller
                name="email"
                control={control}
                render={({ field: { value, onChange, onBlur } }) => (
                  <SharedInput
                    testID="su-farmer-email-input"
                    withLabel
                    onChange={onChange}
                    onFocus={() => clearErrors('email')}
                    value={value}
                    onBlur={onBlur}
                    keyboardType="email-address"
                    label={t('Settings.form.email.label')}
                    placeholder={t('SignUpFarmer.form.email.placeholder')}
                    endAdornment={<PencilIcon width={24} height={24} color='#adb1b8' />}
                  />
                )}
              />
              <FormControl.ErrorMessage
                testID="su-farmer-email-error-message"
                leftIcon={<ErrorIcon width={14} height={14} color={colors.red} />}
                _text={{ style: { color: colors.red, fontSize: 12, fontFamily: 'Lato' } }}
              >
                {errors.email?.message}
              </FormControl.ErrorMessage>
            </FormControl>
          </FieldContainer>
          <AreaCodeAndPhoneContainer>
            <AreaCodeContainer>
              <FieldContainer>
                <FormControl isInvalid={'areaCode' in errors}>
                  <Controller
                    name="areaCode"
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <SharedInput
                        testID="su-farmer-area-code-input"
                        maxLength={
                      phone.length === 8
                        ? 2
                        : phone.length === 7
                          ? 3
                          : phone.length === 6 ? 4 : 4
                    }
                        withLabel
                        value={formatNumber(value)}
                        onChange={onChange}
                        onFocus={() => clearErrors('areaCode')}
                        onBlur={onBlur}
                        keyboardType="numeric"
                        label={t('Settings.form.areaCode.label')}
                        placeholder={t('SignUpFarmer.form.areaCode.placeholder')}
                        endAdornment={<PencilIcon width={24} height={24} color='#adb1b8' />}
                      />
                    )}
                  />
                  {'areaCode' in errors && (
                  <CustomErrorWrapper testID="su-farmer-phone-error-message">
                    <CustomErrorIconWrapper>
                      <ErrorIcon width={14} height={14} color={colors.red} />
                    </CustomErrorIconWrapper>
                    <CustomErrorText>
                      {errors.areaCode?.message}
                    </CustomErrorText>
                  </CustomErrorWrapper>
                  )}
                </FormControl>
              </FieldContainer>
            </AreaCodeContainer>
            <PhoneContainer>
              <FieldContainer>
                <FormControl isInvalid={'phone' in errors}>
                  <Controller
                    name="phone"
                    control={control}
                    defaultValue=""
                    rules={{ pattern: { value: phoneRegex, message: t('SignUpFarmer.form.phoneRegexError') } }}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <SharedInput
                        leftComponent={15}
                        testID="su-farmer-phone-input"
                        maxLength={
                      areaCode.length === 2
                        ? 8
                        : areaCode.length === 3
                          ? 7
                          : areaCode.length === 4 ? 6 : 8
                    }
                        withLabel
                        value={formatNumber(value)}
                        onChange={onChange}
                        onFocus={handlePhoneInputFocus}
                        onBlur={onBlur}
                        keyboardType="numeric"
                        label={t('Settings.form.phone.label')}
                        placeholder={t('SignUpFarmer.form.phone.placeholder')}
                        endAdornment={<PencilIcon width={24} height={24} color='#adb1b8' />}
                      />
                    )}
                  />
                  <FormControl.ErrorMessage
                    testID="su-farmer-phone-error-message"
                    leftIcon={<ErrorIcon width={14} height={14} color={colors.red} />}
                    _text={{ style: { color: colors.red, fontSize: 12, fontFamily: 'Lato' } }}
                  >
                    {errors.phone?.type !== 'value' ? errors.phone?.message : null }
                  </FormControl.ErrorMessage>
                </FormControl>
              </FieldContainer>
            </PhoneContainer>
          </AreaCodeAndPhoneContainer>
          <Requirements style={{ marginBottom: '16px' }}>{t('Settings.deletingCurrentNumberFarmer')}</Requirements>
          <Error
            testID="su-farmer-errors-messages"
          >
            {errors.phone?.type === 'value' ? errors.phone?.message : error}
          </Error>
        </InputsContainer>
        <ButtonContainer>
          <SharedButton
            testID="set-submit-button"
            variant="primary"
            onPress={handleSubmitForm(handleSubmit)}
            loading={isFetching}
            disabled={!isDirty || !isValid}
          >
            {t('Settings.submitButton')}
          </SharedButton>
        </ButtonContainer>
      </Container>
      <View style={{ height: 40 }} />
    </View>
  )
}

export default SettingsFarmer
