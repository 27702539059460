import React from 'react'
import { t } from 'i18next'

import { CloseButton } from './close'
import { Header } from './header'
import Icon from './icon'

import { SocialIconsProps } from '../interfaces'
import { SocialIconModal, SocialIconsContainer } from '../styled'

export const SocialIcons = ({ onClose, sites, data, closeText, onClick }: SocialIconsProps) => (
  <SocialIconsContainer role="dialog" aria-modal="true">
    <Header title={t('Components.card.share.title')} />
    <SocialIconModal data-testid="social-icons-modal">
      {sites.map(name => (
        <Icon name={name} key={name} data={data} onClose={onClose} onClick={onClick} />
      ))}
    </SocialIconModal>
    <CloseButton onClose={onClose} closeText={closeText} />
  </SocialIconsContainer>
)
