import React from 'react'
import { View } from 'react-native'
import { useTranslation } from 'react-i18next'
import { NativeStackScreenProps } from '@react-navigation/native-stack'

import { UserRole } from '@hedgit/lib/enums/user-role'

import { RootStackParamList } from '@hedgit/lib/interfaces/root-stack-params-list'

import { ContactInformation } from '@hedgit/lib/components/shared/contact-information'
import { ContactInformationType } from '@hedgit/lib/components/shared/contact-information/contact-information'
import GoBackIcon from '@hedgit/lib/components/icons/go-back'

import { RootState, useSelector } from 'store'

import {
  Content,
  SubContent,
  SupportIcon,
  SupportContainer,
  Subtitle,
  Title,
  Background,
  Pressable,
  HeaderWrapper,
  ContactBox
} from './styled'

type Props = NativeStackScreenProps<RootStackParamList, 'Support'>

const Support = ({ navigation }: Props) => {
  const { t } = useTranslation()
  const userRole = useSelector((state: RootState) => state.auth.currentUser?.role)

  const subtitle = userRole !== UserRole.farmer ? t('Support.subtitle') : t('Support.subtitleFarmer')

  return (
    <Content>
      <SubContent>
        <SupportContainer>
          <HeaderWrapper>
            <Pressable testID='support-goBack-button' onPress={() => navigation.goBack()}>
              <GoBackIcon color='#313C4D' />
            </Pressable>
            <Title>{t('Support.title')}</Title>
          </HeaderWrapper>
          <Background />
          <SupportIcon />
          <Subtitle>{subtitle}</Subtitle>
        </SupportContainer>

        {userRole !== UserRole.farmer
          ? (
            <ContactBox>
              <View style={{ marginTop: '-34px' }}>
                <ContactInformation role={userRole} variant={ContactInformationType.phone} value="+54 9 341 2031522" />
              </View>
              <ContactInformation role={userRole} variant={ContactInformationType.message} value="+54 9 341 2031522" />
              <ContactInformation role={userRole} variant={ContactInformationType.email} value="soporte@hedgit.ai" />
            </ContactBox>
            )
          : (
            <ContactBox>
              <View style={{ marginTop: '-34px' }}>
                <ContactInformation
                  role={userRole}
                  variant={ContactInformationType.faq}
                  value="FAQ"
                />
              </View>
              <ContactInformation role={userRole} variant={ContactInformationType.email} value="soporte@hedgit.ai" />
            </ContactBox>
            )}
      </SubContent>
    </Content>
  )
}

export default Support
