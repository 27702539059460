import { Reducer } from 'redux'

import { SubscriptionPlansActionTypes, SubscriptionPlansState, SubscriptionPlanActionConsts } from './types'

export const initialState: SubscriptionPlansState = {
  activeSubscriptionPlan: null,
  isFetching: false,
  error: ''
}

export const subscriptionPlansReducer: Reducer<SubscriptionPlansState,
SubscriptionPlansActionTypes> = (
  state = initialState,
  action
): SubscriptionPlansState => {
  switch (action.type) {
    case SubscriptionPlanActionConsts.GET_SUBSCRIPTION_PLAN_PENDING:
      return {
        ...state,
        isFetching: true
      }
    case SubscriptionPlanActionConsts.GET_SUBSCRIPTION_PLAN_FULFILLED:
      return {
        ...state,
        isFetching: false,
        activeSubscriptionPlan: action.payload
      }
    case SubscriptionPlanActionConsts.GET_SUBSCRIPTION_PLAN_FAILED:
      return {
        ...state,
        isFetching: false,
        error: action.payload
      }
    default:
      return state
  }
}
