import React from 'react'
import { Switch } from 'native-base'
import { theme } from '@hedgit/lib/theme'

import { PricingSignal } from '@hedgit/lib/interfaces/pricing-signal'

import Body from '@hedgit/lib/components/typography/body'
import Title from '@hedgit/lib/components/typography/title'

import { ItemContainer, RightContainer, Input } from './styled'

export interface ExecutionValue {
  id: string;
  value: number;
  execute: boolean;
}

interface SegmentedItemProps {
  signal: PricingSignal;
  onChange: (value: ExecutionValue) => void;
  isSelected: boolean;
  value: number;
  error: boolean | undefined;
}

const SegmentedItem = ({ signal, onChange, isSelected, value, error }: SegmentedItemProps) => {
  return (
    <ItemContainer error={error}>
      <Switch
        value={isSelected}
        color={theme.colors.primary}
        onThumbColor={theme.colors.primary}
        offThumbColor={theme.colors.white}
        trackColor={{
          false: theme.colors.whiteLight,
          true: theme.colors.skyBlue
        }}
        onValueChange={() => onChange({
          execute: !isSelected,
          id: signal.id,
          value: parseFloat(value.toString())
        })}
        style={{ marginRight: 8 }}
      />
      <Title
        style={{
          fontWeight: '300',
          textAlign: 'left',
          fontSize: 14,
          color: isSelected ? theme.colors.primary : theme.colors.dark
        }}
      >
        {isSelected ? 'EJECUTA' : 'NO EJECUTA'}
      </Title>
      <RightContainer>
        <Body
          style={{
            lineHeight: 16,
            paddingLeft: 4
          }}
        >
          {signal.subscription.farmer.firstName + ' ' + signal.subscription.farmer.lastName}
        </Body>
        <Input
          active={isSelected}
          disabled={!isSelected}
          keyboardType="decimal-pad"
          maxLength={6}
          defaultValue={!value || value === 0 ? '' : value?.toString()}
          onBlur={({ nativeEvent: { target } }) => {
            onChange({
              execute: isSelected,
              id: signal.id,
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              value: parseFloat((target as any).value)
            })
          }}
        />
      </RightContainer>
    </ItemContainer>
  )
}

export default SegmentedItem
