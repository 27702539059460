import React, { useEffect, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { View } from 'react-native'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormControl } from 'native-base'
import { useTranslation } from 'react-i18next'
import { theme } from '@hedgit/lib/theme'
import { HeaderLeftButton } from '@hedgit/web/src/components/header-left-button'

import { UpdateUserSchema } from '@hedgit/lib/utils/validations/user'

import { UserRole } from '@hedgit/lib/enums/user-role'

import { User } from '@hedgit/lib/interfaces/user'

import { MultiplePhoneNumbersInput } from '@hedgit/lib/components/shared/multiple-phone-numbers-input'
import Title from '@hedgit/lib/components/typography/title'
import { SharedInput } from '@hedgit/lib/components/shared/input'
import { SharedButton } from '@hedgit/lib/components/shared/button'
import ErrorIcon from '@hedgit/lib/components/icons/error'
import H2 from '@hedgit/lib/components/typography/h2'

import { useFormFields } from './hooks'
import {
  Container,
  InputsContainer,
  Requirements,
  ButtonContainer,
  Error,
  FieldContainer,
  UserIcon,
  StyledBackground,
  Header,
  BackButtonContainer,
  UserText
} from './styled'

import PencilIcon from '../icons/pencil'
import { phoneNumberRegex } from '../../utils/regex'
import { post } from '../../utils/axios'
import { APIResponse } from '../../types/api'

export interface SettingsProps {
  error?: string;
  isFetching?: boolean;
  onSubmit: (data: User) => void;
  currentUser: User;
  role: UserRole;
}

const { colors } = theme

const validatePhoneNumbers = (values: string[]) =>
  values.every(v => v.length) &&
  values.every(v => phoneNumberRegex.test(v))

interface VerifyPhoneNumbersResponse {
  alreadyUsedPhoneNumbers: {
    [phone: string]: string;
  };
}

const Settings = ({
  onSubmit,
  error,
  isFetching,
  currentUser,
  role
}: SettingsProps) => {
  const { t } = useTranslation()

  const userPhones = [currentUser.phone, ...currentUser.secondaryPhones || []].map((p) => p.replace(
    '+549', ''
  ))

  const [phoneNumbers, setPhoneNumbers] = useState(userPhones)
  const [phoneErrors, setPhoneErrors] = useState<Record<number, string>>({})

  const isBroker = role === UserRole.broker
  const isBrokerFarmer = role === UserRole.broker_farmer

  const { userFields, billingInformationFields } = useFormFields()

  const {
    control,
    reset,
    handleSubmit: handleSubmitForm,
    formState: { errors },
    clearErrors
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: yupResolver(UpdateUserSchema)
  })

  const validPhoneNumbers = validatePhoneNumbers(phoneNumbers)

  const handleSubmit = async (data: User) => {
    try {
      clearErrors()
      if (validPhoneNumbers) {
        const phone = `+549${phoneNumbers[0]}`

        let secondaryPhones: string[] = []

        if (phoneNumbers.length > 1) {
          secondaryPhones = phoneNumbers.slice(1).map(p => `+549${p}`)
        }

        const {
          data: { alreadyUsedPhoneNumbers }
        } = await post<APIResponse<VerifyPhoneNumbersResponse>>(
          '/auth/verify-phone-numbers',
          { phones: [phone, ...secondaryPhones || []] }
        )

        if (Object.values(alreadyUsedPhoneNumbers).length) {
          const newErrors = Object.keys(alreadyUsedPhoneNumbers).reduce((accum, phone) => {
            const p = phone.replace('+549', '')
            accum[p.toString()] = t('Settings.alreadyUsedPhoneNumber')
            return accum
          }, {})
          setPhoneErrors((prevErrors) => ({
            ...prevErrors,
            ...newErrors
          }))
        } else {
          onSubmit({ ...data, phone, secondaryPhones })
        }
      }
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    if (currentUser) {
      const user = userFields.reduce((accum, field) => {
        accum[field.name] = currentUser[field.name]
        return accum
      }, {})

      const billingInformation = billingInformationFields.reduce((accum, field) => {
        const fieldName = field.name.split('.')[1]
        accum[fieldName] = currentUser.billingInformation?.[fieldName]
        return accum
      }, {})

      reset({ ...user, billingInformation })
    }
  }, [currentUser, reset, role, billingInformationFields, userFields])

  const initials = currentUser.firstName[0] + (currentUser.lastName[0] || currentUser.firstName[1])

  return (
    <View>
      <Header>
        <BackButtonContainer>
          <HeaderLeftButton />
        </BackButtonContainer>
        <H2>{t('Settings.title')}</H2>
      </Header>
      <StyledBackground />
      <Container>
        <UserIcon><UserText>{initials}</UserText></UserIcon>
        <InputsContainer>
          {userFields.map((field) => (
            <FieldContainer key={field.name}>
              <FormControl isInvalid={field.name in errors}>
                <Controller
                  name={field.name}
                  control={control}
                  render={({ field: { value, onChange, onBlur } }) => (
                    <SharedInput
                      testID={field.testID}
                      withLabel
                      label={field.label}
                      onChange={onChange}
                      onFocus={() => clearErrors(field.name)}
                      onBlur={onBlur}
                      value={value}
                      keyboardType={field.keyboardType || 'default'}
                      placeholder={field.placeholder}
                      endAdornment={<PencilIcon width={24} height={24} color='#adb1b8' />}
                    />
                  )}
                />
                <FormControl.ErrorMessage
                  testID="su-farmer-firstName-error-message"
                  leftIcon={<ErrorIcon width={14} height={14} color={colors.red} />}
                  _text={{
                    style: {
                      color: colors.red,
                      fontSize: 12,
                      fontFamily: 'Lato'
                    }
                  }}
                >
                  {errors[field.name]?.message}
                </FormControl.ErrorMessage>
              </FormControl>
            </FieldContainer>
          ))}
          <MultiplePhoneNumbersInput
            values={phoneNumbers}
            setValues={setPhoneNumbers}
            errors={phoneErrors}
            setErrors={setPhoneErrors}
          />
          <Requirements style={{ marginBottom: '16px' }}>{t('Settings.deletingCurrentNumber')}</Requirements>
          {(isBroker || isBrokerFarmer) && (
            <>
              <Title>
                {t('Settings.billingInformationLabel')}
              </Title>
              <Requirements style={{ marginBottom: '16px' }}>{t('Settings.billingInfoDisclaimer')}</Requirements>
              {billingInformationFields.map((field, index) => (
                <FieldContainer
                  key={field.name}
                  style={
                    index === billingInformationFields.length - 1 && {
                      marginBottom: 0
                    }
                  }
                >
                  <FormControl isInvalid={field.name in errors}>
                    <Controller
                      name={field.name}
                      control={control}
                      defaultValue=""
                      render={({ field: { value, onChange, onBlur } }) => (
                        <SharedInput
                          testID={field.testID}
                          withLabel
                          label={field.label}
                          value={value}
                          onChange={onChange}
                          onFocus={() => clearErrors(field.name)}
                          onBlur={onBlur}
                          keyboardType={field.keyboardType || 'default'}
                          placeholder={field.placeholder}
                          disabled={field.disabled}
                        />
                      )}
                    />
                    <FormControl.ErrorMessage
                      testID={field.testID + '-error'}
                      leftIcon={<ErrorIcon width={14} height={14} color={colors.red} />}
                      _text={{
                        style: {
                          color: colors.red,
                          fontSize: 12,
                          fontFamily: 'Lato'
                        }
                      }}
                    >
                      {errors[field.name]?.message}
                    </FormControl.ErrorMessage>
                  </FormControl>
                </FieldContainer>
              ))}
            </>
          )}
          <Error testID="set-error-messages">{error}</Error>
        </InputsContainer>
        <ButtonContainer>
          <SharedButton
            testID="set-submit-button"
            variant="primary"
            onPress={handleSubmitForm(handleSubmit)}
            loading={isFetching}
            disabled={!validPhoneNumbers}
          >
            {t('Settings.submitButton')}
          </SharedButton>
        </ButtonContainer>
      </Container>
      <View style={{ height: 40 }} />
    </View>
  )
}

export default Settings
