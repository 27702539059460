import { APIResponse } from '@hedgit/lib/types/api'
import { ThunkAction } from '@hedgit/lib/types/redux-thunk'

import { get, patch } from '@hedgit/lib/utils/axios'

import { BrokerPricingSignal, PricingSignal } from '@hedgit/lib/interfaces/pricing-signal'

import {
  getPricingSignalPending,
  getPricingSignalFulfilled,
  getPricingSignalFailed,
  getBrokerPricingSignalFulfilled,
  executePricingSignalsPending,
  executePricingSignalsFulfilled,
  executePricingSignalsFailed,
  getPricingSignalsByPricingProgramPending,
  getPricingSignalsByPricingProgramFulfilled,
  getPricingSignalsByPricingProgramFailed,
  getBrokerFarmerPricingSignalFulfilled,
  getPricingSignalsBySubscriptionPending,
  getPricingSignalsBySubscriptionFulfilled,
  getPricingSignalsBySubscriptionFailed
} from './actions'
import { ExecutePricingSignalsInfo, PricingSignalsActionTypes } from './types'

import { UserRole } from '../../../enums/user-role'

export interface GetPricingSignalsResponse {
  signalsAsFarmer: PricingSignal[];
  signalsAsBroker: BrokerPricingSignal[];
}

export const getPricingSignals =
  (role: UserRole = UserRole.farmer): ThunkAction<void, unknown, unknown, PricingSignalsActionTypes> => {
    return async (dispatch) => {
      dispatch(getPricingSignalPending())
      try {
        const response = await get<APIResponse<GetPricingSignalsResponse>>('/pricing-signals')
        if (role === UserRole.farmer) {
          dispatch(getPricingSignalFulfilled(response.data.signalsAsFarmer as PricingSignal[]))
        } else if (role === UserRole.broker) {
          dispatch(getBrokerPricingSignalFulfilled(response.data.signalsAsBroker as BrokerPricingSignal[]))
        } else if (role === UserRole.broker_farmer) {
          dispatch(getBrokerFarmerPricingSignalFulfilled(response.data))
        }
      } catch (error) {
        dispatch(getPricingSignalFailed(error as string))
      }
    }
  }

export interface ExecutePricingSignalsResponse {
  pricingProgram: {
    id: string;
    executionPrice: number;
    signalDate: string;
  };
}

export const executePricingSignals = (data: ExecutePricingSignalsInfo):
  ThunkAction<void, unknown, unknown, PricingSignalsActionTypes> => {
  return async (dispatch) => {
    dispatch(executePricingSignalsPending())
    try {
      const response =
        await patch<APIResponse<ExecutePricingSignalsResponse>>('/pricing-signals/actions/execute', data)
      dispatch(executePricingSignalsFulfilled(response.data))
    } catch (error) {
      dispatch(executePricingSignalsFailed(error as string))
    }
  }
}

interface GetPricingSignalsByIdResponse {
  pricingSignals: PricingSignal[];
}

export const getPricingSignalsByPricingProgram = (pricingProgramId: string):
  ThunkAction<void, unknown, unknown, PricingSignalsActionTypes> => {
  return async (dispatch) => {
    dispatch(getPricingSignalsByPricingProgramPending())
    try {
      const response =
        await get<APIResponse<GetPricingSignalsByIdResponse>>(`/pricing-programs/${pricingProgramId}/pricing-signals`)
      dispatch(getPricingSignalsByPricingProgramFulfilled({
        pricingProgramId,
        signals: response.data.pricingSignals
      }))
    } catch (error) {
      dispatch(getPricingSignalsByPricingProgramFailed(error as string))
    }
  }
}

interface GetPricingSignalsCSVResponse {
  pricingSignals: PricingSignal[];
}

export const getPricingSignalsToCsv =
  (): ThunkAction<void, unknown, unknown, PricingSignalsActionTypes> => {
    return async (dispatch) => {
      dispatch(getPricingSignalPending())
      try {
        const response = await get<APIResponse<GetPricingSignalsCSVResponse>>('/pricing-signals/signals-to-csv')
        dispatch(getPricingSignalFulfilled(response.data.pricingSignals as PricingSignal[]))
      } catch (error) {
        dispatch(getPricingSignalFailed(error as string))
      }
    }
  }

export const getPricingSignalsBySubscription = (subscriptionId: string):
  ThunkAction<void, unknown, unknown, PricingSignalsActionTypes> => {
  return async (dispatch) => {
    dispatch(getPricingSignalsBySubscriptionPending())
    try {
      const response =
        await get<APIResponse<GetPricingSignalsByIdResponse>>(`/subscriptions/${subscriptionId}/pricing-signals`)
      dispatch(getPricingSignalsBySubscriptionFulfilled({
        subscriptionId,
        signals: response.data.pricingSignals
      }))
    } catch (error) {
      dispatch(getPricingSignalsBySubscriptionFailed(error as string))
    }
  }
}
