import { ActionType } from 'typesafe-actions'

import { SubscriptionPlansDetails } from '@hedgit/lib/interfaces/subscription-plan'

import * as actions from './actions'

export enum SubscriptionPlanActionConsts {
  GET_SUBSCRIPTION_PLAN_PENDING='GET_SUBSCRIPTION_PLAN_PENDING',
  GET_SUBSCRIPTION_PLAN_FULFILLED='GET_SUBSCRIPTION_PLAN_FULFILLED',
  GET_SUBSCRIPTION_PLAN_FAILED='GET_SUBSCRIPTION_PLAN_FAILED',
}

export type SubscriptionPlansActionTypes = ActionType<typeof actions>

export interface SubscriptionPlansState {
  readonly activeSubscriptionPlan: SubscriptionPlansDetails;
  readonly isFetching: boolean;
  readonly error: string;
}
