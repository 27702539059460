/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import axios, { AxiosRequestConfig } from 'axios'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { getAuth } from '@firebase/auth'

import { setAuthToken, setIsSignIn, signOutThunk } from '@hedgit/lib/store/modules/auth'

import { patch } from '@hedgit/lib/utils/axios'

import { useDispatch, useSelector } from 'store'

const useAuthToken = () => {
  const dispatch = useDispatch()
  const [uid, setUid] = useState<string>('')
  const [refreshToken, setRefreshToken] = useState<string>('')
  const [shouldUpdateRefreshToken, setShouldUpdateRefreshToken] = useState<boolean>(false)
  const isSignIn = useSelector((store) => store.auth.isSignIn)

  const getInitialToken = async () => {
    try {
      const token = await AsyncStorage.getItem('auth_token')
      if (token) {
        dispatch(setAuthToken(token))
      }
    } catch (e) {
      console.log(e)
    }
  }

  const updateToken = (value: string) => {
    AsyncStorage.setItem('auth_token', value)
    dispatch(setAuthToken(value))
  }

  useEffect(() => {
    getInitialToken()
  }, [])

  const updateRefreshToken = async () => {
    try {
      const res = await patch('auth/user/actions/update-refresh-token', { uid, refreshToken })
      return res
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    if (shouldUpdateRefreshToken && refreshToken) {
      updateRefreshToken()
      setShouldUpdateRefreshToken(false)
    }
  }, [refreshToken, shouldUpdateRefreshToken])

  useEffect(() => {
    const unsubscribe = getAuth().onAuthStateChanged(async (user) => {
      try {
        if (user) {
          const newToken = await user?.getIdToken()
          updateToken(newToken)
          setUid(user.uid)
          setRefreshToken(user.refreshToken)
          if (isSignIn === null) {
            dispatch(setIsSignIn(true))
          }
        } else {
          dispatch(setIsSignIn(false))
          AsyncStorage.removeItem('auth_token')
          dispatch(setAuthToken(''))
        }
      } catch (error) {
        console.error(error)
      }
    })

    return () => unsubscribe()
  }, [isSignIn])

  useEffect(() => {
    axios.interceptors.response.use((res) => {
      if (res.headers.RefreshToken) {
        const newToken = res.headers.RefreshToken
        if (isSignIn === true) {
          updateToken(newToken)
        }
      }
      if (res.data?.data?.requestRefreshToken) {
        setShouldUpdateRefreshToken(true)
      }
      if (res.data.statusCode === 401 || res.data.error === 'Unauthorized') {
        dispatch(signOutThunk())
      }
      return res
    }, (err) => {
      if (err.response.status === 401 || err.response.statusText === 'Unauthorized') {
        dispatch(signOutThunk())
      }
      return Promise.reject(err)
    })

    axios.interceptors.request.use(async (config: AxiosRequestConfig) => {
      const t = await AsyncStorage.getItem('auth_token')
      if (config.headers) {
        config.headers.Authorization = `Bearer ${t}`
      }
      return config
    })
  }, [])
}

export default useAuthToken
