import { UserRole } from '@hedgit/lib/enums/user-role'

import { useSelector } from 'store'

const useUserRole = () => {
  const role = useSelector((state) => state.auth.currentUser?.role)

  return {
    role,
    isBroker: role === UserRole.broker,
    isFarmer: role === UserRole.farmer,
    isBrokerFarmer: role === UserRole.broker_farmer
  }
}

export default useUserRole
