import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { FormControl, Input } from 'native-base'
import { useForm, Controller, useFormState } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { theme } from '@hedgit/lib/theme'

import yup from '@hedgit/lib/utils/yup'

import { BrokerPricingSignal } from '@hedgit/lib/interfaces/pricing-signal'

import Body from '@hedgit/lib/components/typography/body'
import H3 from '@hedgit/lib/components/typography/h3'
import ErrorIcon from '@hedgit/lib/components/icons/error'

import {
  CircleWrapper,
  ButtonContainer,
  ButtonsContainer,
  Button,
  CircleBox,
  Line,
  ExecuteBox,
  ReferenceText
} from '../../styled'

export interface ModalProps {
  modalData?: BrokerPricingSignal;
  onPressConfirm: (executionPrice: number) => void;
  onPressCancel: () => void;
}

const { colors } = theme

const Aggregated = ({
  modalData,
  onPressConfirm,
  onPressCancel
}: ModalProps) => {
  const { t } = useTranslation()

  const validationExecutionPrice = yup.object({
    executionPrice: yup
      .number()
      .required(t('ErrorMessages.executionPrice.required'))
      .positive(t('ErrorMessages.executionPrice.positive'))
      .typeError(t('ErrorMessages.executionPrice.typeError'))
  })

  const {
    control,
    handleSubmit: handleSubmitForm,
    formState: { errors },
    reset,
    clearErrors
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: {
      executionPrice: modalData.executionPrice?.toString() || ''
    },
    resolver: yupResolver(validationExecutionPrice)
  })

  const { isDirty } = useFormState({
    control
  })

  const isDisabled = !isDirty

  const formReset = useCallback(() => {
    reset({
      executionPrice: ''
    })
  }, [reset])

  const handleInputFocus = () => {
    clearErrors()
  }

  const handleSubmit = data => {
    onPressConfirm(data.executionPrice)
  }

  return (
    <>
      <CircleWrapper>
        <CircleBox />
        <ExecuteBox>
          <Body>{t('Components.modal.executePricingSignal.currency').toUpperCase()}</Body>
          <FormControl
            isInvalid={'executionPrice' in errors}
            style={{
              height: 118
            }}
          >
            <Controller
              name="executionPrice"
              control={control}
              defaultValue=''
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  variant="unstyled"
                  type="numeric"
                  value={value}
                  keyboardType="numeric"
                  maxLength={6}
                  defaultValue=''
                  onChange={onChange}
                  onFocus={handleInputFocus}
                  onBlur={onBlur}
                  color={colors.dark}
                  _invalid={{
                    color: colors.red
                  }}
                  h={85}
                  w="100%"
                  fontSize={57}
                  fontWeight="bold"
                  fontFamily="lato"
                  textAlign="center"
                />
              )}
            />
            <Line />
            <FormControl.ErrorMessage
              testID="si-phone-error-message"
              style={{ alignItems: 'center' }}
              leftIcon={<ErrorIcon width={14} height={14} color={colors.red} />}
              _text={{ style: { color: colors.red, fontSize: 12, fontFamily: 'Lato' } }}
            >
              {errors.executionPrice?.message}
            </FormControl.ErrorMessage>
          </FormControl>
          <ReferenceText>{t('Components.modal.executePricingSignal.referencePrice').toUpperCase()}</ReferenceText>
          <H3>
            {t('Components.modal.executePricingSignal.currency').toUpperCase()}
            {' '}
            { modalData && modalData.lastMarketDataValue }
          </H3>
        </ExecuteBox>
      </CircleWrapper>
      <ButtonsContainer>
        <ButtonContainer>
          <Button
            testID='es-cancel-button'
            variant='secondary'
            onPress={() => {
              onPressCancel()
              formReset()
            }}
          >
            {t('Components.button.back')}
          </Button>
        </ButtonContainer>
        <ButtonContainer>
          <Button
            disabled={isDisabled}
            testID='es-confirm-button'
            variant='primary'
            onPress={handleSubmitForm(handleSubmit)}
          >
            {t('Components.button.confirm')}
          </Button>
        </ButtonContainer>
      </ButtonsContainer>
    </>
  )
}

export default Aggregated
