import styled from 'styled-components/native'
import styledWeb from 'styled-components'

import Caption from '@hedgit/lib/components/typography/caption'
import H2 from '@hedgit/lib/components/typography/h2'
import Subtitle from '@hedgit/lib/components/typography/subtitle'

export const Container = styled.View`
  background-color: ${props => props.theme.colors.white};
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: 100vh;
  gap: 40px;
  height: 100vh;
  overflow: auto;
  padding-left: 16px;
  padding-right: 16px;
  width: 100%;
`

export const InputContainer = styled.View`
  width: 100%;
  gap: 4px;
`

export const Content = styled.View`
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border-radius: 10px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 20px #00000021;
  opacity: 1;
  width: 100%;
  max-width: 600px;
  margin-bottom: 16px;
`

export const Title = styled(H2)`
  text-align: center;
  margin-top: 4px;
  margin-bottom: 36px;
`

export const SubTitle = styled(Subtitle)`
  text-align: center;
  margin-bottom: 36px;
  font-weight: 400;
`

export const ImageContainer = styled.View`
  align-items: center;
  justify-content: center;
  height: 57px;
`

export const BackgroundContainer = styled.View`
  position: fixed;
  top: 0;
  left: -70vw;
  z-index: 0;
  height: 100vh;
  min-width: 140vw;
`

export const Error = styled.Text`
  color: ${props => props.theme.colors.red};
`

export const AreaCodeAndPhoneContainer = styled.View`
  flex-direction: row;
  justify-content: space-between;
`

export const AreaCodeContainer = styled.View`
  width: 28%;
`

export const PhoneContainer = styled.View`
  width: 70%;
`

export const Br = styledWeb.br`
`

export const CustomErrorWrapper = styled.View`
  flex-direction: row;
  gap: 4px;
  margin-top: 8px;
  align-items: flex-start;
`

export const CustomErrorIconWrapper = styled.View`
  height: 1lh;
  justify-content: center;
  font-size: 12px;
`

export const CustomErrorText = styled.Text`
  color: ${({ theme }) => theme.colors.red};
  font-size: 12px;
  font-family: Lato;
`

export const Requirements = styled(Caption)`
  margin-top: 10px;
  color: ${props => props.theme.colors.gray};
`

export const SignInButtonContainer = styled.View`
  margin: 20px 0px;
  width: 100%;
`

export const ButtonsContainer = styled.View`
  width: 100%;
  gap: 10px;
`

export const ButtonTitle = styled(Subtitle)`
  text-align: center;
  font-weight: 400;
  margin-bottom: 5px;
`
