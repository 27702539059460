import { action } from 'typesafe-actions'

import { User } from '@hedgit/lib/interfaces/user'

import { UsersActionConst } from './types'

export const getUserPending = () =>
  action(UsersActionConst.GET_USERS_PENDING)

export const getUserFulfilled = (users: User[]) =>
  action(UsersActionConst.GET_USERS_FULFILLED, users)

export const getUserFailed = (error: string) =>
  action(UsersActionConst.GET_USERS_FAILED, error)
