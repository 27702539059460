import styled from 'styled-components/native'

import Title from '@hedgit/lib/components/typography/title'

export const CardContent = styled.View`
  box-shadow: 0 3px 6px #0000001a;
  border-radius: 6px;
  max-width: 600px;
  color: #5a6371;
  padding-top: 16px;
  padding-right: 16px;
  padding-left: 17px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

export const TextBox = styled.View`
  align-items: flex-start;
  flex: 2;
`

export const CardTitle = styled(Title)`
  font-size: 25px;
  font-weight: 700;
  font-family: 'Lato';
  color: ${props => props.theme.colors.primary};
  align-self: center;
  text-align: center;
`

export const CardText = styled(Title)`
  font-weight: 600;
  font-size: 19px;
  align-self: center;
  text-align: center;
  width: 80%;
  margin: 15px;
`
