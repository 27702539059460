import styled from 'styled-components/native'

import Title from '@hedgit/lib/components/typography/title'
import Button from '@hedgit/lib/components/typography/button-text'

import Subtitle from '../../typography/subtitle'
import { theme } from '../../../theme'

interface ButtonProps {
  disabled: boolean;
}

export const CardContainer = styled.View`
  border-radius: 10px;
  flex-direction: column;
  margin-block: 5px;
  background-color: ${props => props.theme.colors.white};
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
  width: 100%;
  padding: 10px 40px 15px 40px;
  align-self: center;
`

export const CardTitle = styled(Title)`
  font-size: 17px;
`

export const GenerateButton = styled.TouchableOpacity<ButtonProps>`
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.disabled ? theme.colors.disabledGray : theme.colors.primary};
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1);
  width: 87px;
  height: 32px;
`

export const GenerateButtonText = styled(Button)`
  color: ${props => props.theme.colors.white};
  font-size: 16px;
`

export const DetailsContent = styled.View`
  flex-direction: row;
  align-items: center;
  padding: 5px 0px 5px 0px;
`

export const GenerateButtonContent = styled.View`
  align-items: center;
  justify-content: center;
  padding: 5px 0px 5px 0px;
`

export const ContentText = styled(Subtitle)`
  font-size: 14px;
  margin-right: 20px;
  margin-left: 5px;
  font-weight: 500;
  color: ${props => props.theme.colors.primary};
`

export const Circle = styled.View`
  min-width: 25px;
  max-height: 25px;
  border-radius: 12.5px;
  padding: 2px;
  background-color: ${props => props.theme.colors.primary};
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1);
  align-items: center;
  justify-content: center;
`

export const CircleText = styled(Subtitle)`
  font-size: 14px;
  font-weight: 500;
  color: ${props => props.theme.colors.white};
`
