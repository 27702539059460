import styled from 'styled-components/native'
import { Button as NBButton, Stack as NBStack } from 'native-base'

import Title from '@hedgit/lib/components/typography/title'

export const Content = styled.View`
  justify-content: center;
  flex: 1;
  background-color:  ${props => props.theme.colors.white};
`

export const SubContent = styled.View`
  background-color:  ${props => props.theme.colors.white};
  align-self: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  max-width: 600px;
`

export const Card = styled.View`
  border-radius: 6px;
  elevation: 3;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  margin: 8px;
`

export const CardContent = styled.View`
  margin-right: 10px;
  margin-left: 10px;
  margin-top: 8px;
  margin-bottom: 8px;

`

export const Button = styled(NBButton)`
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  elevation: 3;
  margin: 21px 16px;
  height: 50px;
  background-color: ${props => (props.theme.colors.lightBlue)};
`

export const ScreenContent = styled.View`
  margin: 8px;
  flex-direction: column;
  height: 100%;
`

export const GraphContent = styled.View`
  width: 346px;
  height: 208px;
  align-items: center;
  box-shadow: 0 3px 6px #0000001A;
  background-color: ${props => (props.theme.colors.white)};
  margin-top: 10px;
  margin-bottom: 15px;
  margin-left: 15px;
  margin-right: 15px;
  border-radius: 6px;
`

export const Stack = styled(NBStack)`
  padding: 10px;
`

export const IconBox = styled.View`
  position: absolute;
  margin-top: 25px;
  margin-left: 27px;
  width: 35px;
  height: 35px;
  align-items: center;
  justify-content: center;
  z-index: 1;
`

export const CardText = styled.Text`
  color: #5A6371;
  padding-top: 16px;
  padding-right: 16px;
  padding-left: 17px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

export const ChartTitle = styled(Title)`
  font-weight: bold;
`

export const PerformanceCard = styled.View`
  align-self: center;
`

export const AveragePerformanceAlgorithmChartContainer = styled.View`
  flex-direction: row;
  justify-content: center;
  min-height: 280px;
  align-items: center;
  box-shadow: 0 3px 6px #0000001A;
  background-color: ${props => (props.theme.colors.white)};
  margin-top: 10px;
  margin-bottom: 15px;
  padding: 20px 20px 40px 20px;
  margin-left: 15px;
  margin-right: 15px;
  border-radius: 6px;
`
