import styled from 'styled-components/native'
import { theme } from '@hedgit/lib/theme'
import { ActivityIndicator } from 'react-native'

import H2 from '@hedgit/lib/components/typography/h2'

export const Main = styled.View`
  justify-content: space-between;
  height: calc(var(--vh, 1vh) * 100);
  width: 100%;
`
export const Content = styled.View`
  align-items: center;
  flex: 1;
  `

export const CodesContainer = styled.View`
  padding-block: 5px;
  max-height: 150px;
`
export const Footer = styled.View`
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 113px;
  `
export const Image = styled.Image`
  width: 82px;
  height: 82px;
`

export const Container = styled.View`
  background-color: ${theme.colors.white};
`

export const HeaderWrapper = styled.View`
  width: 100%;
  padding: 20px;
  align-items: center;
  justify-content: center;
`

export const Pressable = styled.Pressable`
  position: absolute;
  left: 16px;
`

export const Title = styled(H2)`
  top: 15px;
`

export const Spinner = styled(ActivityIndicator)`
  color: ${theme.colors.primary};
  size: large;
  margin: 16px;
  align-items: center;
  justify-content: center;
`
