/* eslint-disable react-hooks/exhaustive-deps */

import React, { useCallback, useState } from 'react'
import { useFocusEffect } from '@react-navigation/native'
import {
  FlatList,
  ListRenderItem,
  ListRenderItemInfo
} from 'react-native'
import { NativeStackScreenProps } from '@react-navigation/native-stack'
import { useTranslation } from 'react-i18next'
import useCustomToast from '@hedgit/web/src/hooks/use-custom-toast'

import { executePricingSignals, getPricingSignals } from '@hedgit/lib/store/modules/pricing-signals/thunks'
import { ExecutePricingSignalsInfo, setSelectedPricingProgram } from '@hedgit/lib/store/modules/pricing-signals'

import { formatDate } from '@hedgit/lib/utils/format'

import { PricingSignalStatus } from '@hedgit/lib/enums/pricing-signal-status'
import { UserRole } from '@hedgit/lib/enums/user-role'
import { Entity } from '@hedgit/lib/enums/entity'

import { BrokerPricingSignal, PricingSignal } from '@hedgit/lib/interfaces/pricing-signal'
import { RootStackParamList } from '@hedgit/lib/interfaces/root-stack-params-list'

import PricingSignalCard from '@hedgit/lib/components/cards/pricing-signal'
import { EmptyCard } from '@hedgit/lib/components/cards/empty-card'
import { LoadingScreen } from '@hedgit/lib/components/loading-screen'
import { ExecuteSignalModal } from '@hedgit/lib/components/modals/execute-pricing-signal-modal'
import { Modal } from '@hedgit/lib/components/modals/basic-modal'

import { useDispatch, useSelector } from 'store'

import useSuspendedToast from 'hooks/use-suspended-toast'
import useMediaQueries from 'hooks/use-media-queries'

import { CardClickArea, ContainerFlatList, Content, SectionTitle, SubContent } from './styled'

type Props = NativeStackScreenProps<RootStackParamList, 'NavigationTabs'>

const PricingSignalsExecutedTab = ({ navigation }: Props) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { columns } = useMediaQueries()
  const { showToast } = useCustomToast()

  const userRole = useSelector((state) => state.auth.currentUser?.role)
  const list = useSelector((state) => state.pricingSignals.list || [])
  const brokerList = useSelector((state) => state.pricingSignals.brokerList || [])
  const filteredBrokerList = brokerList.filter((signal) => signal.executionPrice)
  const filteredFarmerList = list.filter((signal) => signal.executionPrice)
  const isFetching = useSelector(state => state.pricingSignals.isFetching)

  const [executionPriceModalVisible, setExecutionPriceModalVisible] = useState<boolean>(false)
  const [modalData, setModalData] = useState<BrokerPricingSignal>()
  const [priceConfirmationModalVisible, setPriceConfirmationModalVisible] = useState<boolean>(false)
  const [selectedPricingProgramInfo, setSelectedPricingProgramInfo] = useState<ExecutePricingSignalsInfo>()

  const selectedPricingProgramId = useSelector(state => state.pricingSignals.selectedPricingProgram.id)
  const selectedPricingProgramDate = useSelector(state => state.pricingSignals.selectedPricingProgram.date)

  const { isSuspended, showSuspendedBrokerToast } = useSuspendedToast()

  useFocusEffect(
    useCallback(() => {
      if (userRole) {
        dispatch(getPricingSignals(userRole))
      }
    }, [dispatch, userRole])
  )

  const renderCard: ListRenderItem<PricingSignal> = useCallback(
    (pricingSignal: ListRenderItemInfo<PricingSignal>) => {
      return (
        <CardClickArea disabled>
          <PricingSignalCard
            role={UserRole.farmer}
            state={PricingSignalStatus.executed}
            programStatus={pricingSignal.item.subscription.pricingProgram.status}
            pricingSignal={pricingSignal.item}
            gen={pricingSignal.item.subscription.pricingProgram.algorithm.gen}
          />
        </CardClickArea>
      )
    },
    [navigation]
  )

  const renderCardByPricingProgram: ListRenderItem<BrokerPricingSignal> = useCallback(
    (pricingSignal: ListRenderItemInfo<BrokerPricingSignal>) => {
      return (
        <CardClickArea
          onPress={() => {
            if (isSuspended) {
              showSuspendedBrokerToast()
            } else {
              const date = new Date(pricingSignal.item.date)
              date.setTime(date.getTime() + date.getTimezoneOffset() * 60 * 1000)

              const selectedPricingProgram = {
                id: pricingSignal.item.id,
                date
              }
              dispatch(setSelectedPricingProgram(selectedPricingProgram))
              setModalData(pricingSignal.item)
              setExecutionPriceModalVisible(true)
            }
          }}
        >
          <PricingSignalCard
            role={UserRole.broker}
            state={PricingSignalStatus.executed}
            programStatus={pricingSignal.item.subscription?.pricingProgram.status}
            pricingSignal={pricingSignal.item}
            gen={pricingSignal.item.subscription.pricingProgram.algorithm.gen}
          />
        </CardClickArea>
      )
    },
    [navigation]
  )

  const isExecutionPriceOutOfRange = (price: number, referenceValue: number) => {
    const isOver = referenceValue + (referenceValue * 5 / 100)

    const isBelow = referenceValue - (referenceValue * 5 / 100)

    return price <= isBelow || price >= isOver
  }

  const handleConfirmExecutedPrice = (executionPrice: number) => {
    try {
      const ppInfo = {
        pricingProgramId: selectedPricingProgramId,
        signalDate: formatDate(new Date(selectedPricingProgramDate)),
        executionPrice: executionPrice
      }
      setSelectedPricingProgramInfo(ppInfo)

      const executionPriceOutOfRange = isExecutionPriceOutOfRange(executionPrice, modalData?.lastMarketDataValue || 0)
      if (executionPriceOutOfRange) {
        setPriceConfirmationModalVisible(true)
      } else {
        setPriceConfirmationModalVisible(false)
        dispatch(executePricingSignals(ppInfo))
        handleExecuteSignalModalClose()
        showToast(t('Components.modal.successExPrice.title'), 'success')
      }
    } catch (e) {
      console.error(e)
      showToast(t('Components.modal.executePricingSignal.noSignalUpadtedMessage'), 'danger')
    }
  }

  const handleExecuteSignalModalClose = () => {
    setModalData(undefined)
    setExecutionPriceModalVisible(false)
  }

  const handlePriceCancelation = () => {
    setPriceConfirmationModalVisible(false)
    setExecutionPriceModalVisible(true)
  }

  const handlePriceConfirmation = () => {
    try {
      if (selectedPricingProgramInfo) {
        setPriceConfirmationModalVisible(false)
        dispatch(executePricingSignals(selectedPricingProgramInfo))
        handleExecuteSignalModalClose()
        showToast(t('Components.modal.successExPrice.title'), 'success')
      }
    } catch (e) {
      console.error(e)
      showToast(t('Components.modal.executePricingSignal.noSignalUpadtedMessage'), 'danger')
    }
  }

  if (isFetching && !filteredBrokerList.length && !filteredFarmerList.length) {
    return (
      <LoadingScreen />
    )
  }

  return (

    <Content>
      <SubContent>
        {
          filteredFarmerList.length === 0 && filteredBrokerList.length === 0
            ? <EmptyCard text={t('Empty.pricingSignals.executed')} entity={Entity.pricingSignal} />
            : (
              <>
                {[UserRole.farmer, UserRole.broker_farmer].includes(
                  userRole as UserRole
                ) && filteredFarmerList.length
                  ? (
                    <ContainerFlatList>
                      {userRole === UserRole.broker_farmer
                        ? (
                          <SectionTitle>
                            {t('PricingPrograms.broker_farmer.farmerSignals')}
                          </SectionTitle>
                          )
                        : null}
                      <FlatList
                        key={`flatListWith-${columns}-Columns`}
                        numColumns={columns}
                        testID="ps-farmer-executed-flatList"
                        data={filteredFarmerList}
                        renderItem={renderCard}
                        keyExtractor={(data, index) => data.id + index}
                        style={{ width: '100%' }}
                        contentContainerStyle={{ alignItems: 'center', paddingBottom: '16px' }}
                        ListEmptyComponent={(
                          <EmptyCard
                            text={t('Empty.pricingSignals.executed')}
                            entity={Entity.pricingSignal}
                          />
                        )}
                      />
                    </ContainerFlatList>
                    )
                  : null}
                {[UserRole.broker, UserRole.broker_farmer].includes(
                  userRole as UserRole
                ) && filteredBrokerList.length
                  ? (
                    <ContainerFlatList>
                      {userRole === UserRole.broker_farmer
                        ? (
                          <SectionTitle>
                            {t('PricingPrograms.broker_farmer.brokerSignals')}
                          </SectionTitle>
                          )
                        : null}
                      <FlatList
                        key={`flatListWith-${columns}-Columns`}
                        numColumns={columns}
                        testID="ps-broker-executed-flatList"
                        data={filteredBrokerList}
                        renderItem={renderCardByPricingProgram}
                        keyExtractor={(data, index) => data.id + index}
                        style={{ width: '100%' }}
                        contentContainerStyle={{ alignItems: 'center', paddingBottom: '16px' }}
                        ListEmptyComponent={(
                          <EmptyCard
                            text={t('Empty.pricingSignals.executed')}
                            entity={Entity.pricingSignal}
                          />
                  )}
                      />
                    </ContainerFlatList>
                    )
                  : null}
                {
                  (executionPriceModalVisible && modalData) && (
                    <ExecuteSignalModal
                      testId='ps-executePrice-modal'
                      modalData={modalData}
                      visible={executionPriceModalVisible}
                      onPressCancel={handleExecuteSignalModalClose}
                      onAggregatedExecution={handleConfirmExecutedPrice}
                    />
                  )
                }
                {
                  priceConfirmationModalVisible && (
                    <Modal
                      title={t('Components.modal.priceConfirmation.title')}
                      variant='error'
                      visible={priceConfirmationModalVisible}
                      buttons={[
                        {
                          label: t('Components.button.cancel').toString(),
                          onPress: handlePriceCancelation,
                          testID: 'ps-priceCancel-button',
                          variant: 'secondary'
                        },
                        {
                          label: t('Components.button.confirm').toString(),
                          onPress: handlePriceConfirmation,
                          testID: 'ps-priceConfirm-button',
                          variant: 'primary'
                        }
                      ]}
                    />
                  )
                }
              </>
              )
        }
      </SubContent>
    </Content>
  )
}

export default PricingSignalsExecutedTab
