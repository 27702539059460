import styled from 'styled-components/native'
import { theme } from '@hedgit/lib/theme'

export const Content = styled.View`
    background-color: ${theme.colors.white};
    justify-content: flex-start;
    height: calc(100vh - 64px);
    padding-top: 50px;
`

export const SubContent = styled.View`
  align-items: center;
  justify-content: center;
`
export const Body = styled.Text`
  font-size: 'sm';
  font-weight: 400;
  font-family: 'Lato';
  color: 'dark';
`
