import React, { useState } from 'react'
import { Modal as RNModal } from 'react-native'
import { useTranslation } from 'react-i18next'

import { BrokerPricingSignal } from '@hedgit/lib/interfaces/pricing-signal'

import H2 from '@hedgit/lib/components/typography/h2'

import {
  CenteredView,
  ModalView
} from './styled'
import { ExecutionTypeSwitch, ExecutionType } from './components/execution-type-switch'
import { Aggregated } from './components/aggregated'
import { Segmented } from './components/segmented'

export interface ModalProps {
  testId: string;
  modalData?: BrokerPricingSignal;
  visible?: boolean;
  onCloseModal?: () => void;
  onAggregatedExecution: (executionPrice: number) => void;
  onPressCancel: () => void;
}

const ExecuteSignalModal = ({
  testId,
  modalData,
  visible,
  onPressCancel,
  onCloseModal,
  onAggregatedExecution
}: ModalProps) => {
  const { t } = useTranslation()
  const [executionType, setExecutionType] = useState<ExecutionType>(ExecutionType.aggregated)

  const hasOnlyOneSubscription = modalData.subscriptions === 1

  return (
    <RNModal
      animationType='fade'
      visible={visible}
      presentationStyle='overFullScreen'
      statusBarTranslucent={false}
      transparent
      onRequestClose={onCloseModal}
    >
      <CenteredView>
        <ModalView testID={testId}>
          <H2>{t('Components.modal.executePricingSignal.title')}</H2>
          {
            !hasOnlyOneSubscription &&
            <ExecutionTypeSwitch selected={executionType} onChange={setExecutionType} />
          }
          {
            executionType === ExecutionType.aggregated
              ? (
                <Aggregated
                  modalData={modalData}
                  onPressCancel={onPressCancel}
                  onPressConfirm={onAggregatedExecution}
                />
                )
              : (
                <Segmented signals={modalData} onPressCancel={onPressCancel} />
                )
          }
        </ModalView>
      </CenteredView>
    </RNModal>
  )
}

export default ExecuteSignalModal
