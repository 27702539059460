import React, { useCallback, useState, useEffect } from 'react'
import {
  FlatList,
  ListRenderItem,
  ListRenderItemInfo
} from 'react-native'
import { useTranslation } from 'react-i18next'
import { APIResponse } from '@hedgit/lib/types/api'
import { useDispatch } from '@hedgit/web/src/store'
import useUserRole from '@hedgit/web/src/hooks/use-user-role'
import useCustomToast from '@hedgit/web/src/hooks/use-custom-toast'

import { patch } from '@hedgit/lib/utils/axios'

import { BrokerPricingSignal, PricingSignal } from '@hedgit/lib/interfaces/pricing-signal'

import H3 from '@hedgit/lib/components/typography/h3'

import SegmentedItem, { ExecutionValue } from './components/item'

import { ButtonContainer, ButtonsContainer, Button, ReferenceText } from '../../styled'
import { getPricingSignals } from '../../../../../store/modules/pricing-signals/thunks'

interface SegmentedProps {
  signals: BrokerPricingSignal;
  onPressCancel: (closeModal?: boolean) => void;
}

interface SegmentedExecution {
  [signal: string]: {
    execute: boolean;
    value: number;
  };
}

interface Errors {
  [signal: string]: boolean;
}

interface SegmentedExecutionAPIResponse {
  signals: PricingSignal[];
}

const Segmented = ({ signals, onPressCancel }: SegmentedProps) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { showToast } = useCustomToast()
  const { role } = useUserRole()
  const [errors, setErrors] = useState<Errors>({})
  const [isFetching, setIsFetching] = useState<boolean>(false)
  const [segmentedExecution, setSegmentedExecution] = useState<SegmentedExecution>({})
  const [successMessage, setSuccessMessage] = useState<string | undefined>()

  useEffect(() => {
    if (successMessage) {
      setErrors({})
    }
  }, [successMessage])

  useEffect(() => {
    if (signals) {
      const mappedSignals = signals.signals.reduce<SegmentedExecution>((accum, s) => {
        accum[s.id] = {
          execute: !!s.executionPrice,
          value: s.executionPrice
        }
        return accum
      }, {})
      setSegmentedExecution(mappedSignals)
    }
  }, [signals])

  const handleSegmentedItemChange = useCallback((execution: ExecutionValue) => {
    if (errors[execution.id]) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [execution.id]: false
      }))
    }

    if (successMessage) {
      setSuccessMessage(undefined)
    }

    setSegmentedExecution((prevState) => ({
      ...prevState,
      [execution.id]: {
        execute: execution.execute,
        value: execution.value
      }
    }))
  }, [errors, successMessage])

  const renderItem: ListRenderItem<PricingSignal> = useCallback(
    ({ item: signal }: ListRenderItemInfo<PricingSignal>) => {
      return (
        <SegmentedItem
          signal={signal}
          onChange={handleSegmentedItemChange}
          isSelected={segmentedExecution[signal.id]?.execute}
          value={segmentedExecution[signal.id]?.value || 0}
          error={errors[signal.id]}
        />
      )
    }, [segmentedExecution, handleSegmentedItemChange, errors]
  )

  const handleSubmit = async () => {
    try {
      setErrors({})

      const newErrors = Object.entries(segmentedExecution).reduce((accum, [id, value]) => {
        if (value.execute && !value.value) {
          accum[id] = true
        }
        return accum
      }, {})

      if (Object.keys(newErrors).length) {
        setErrors(newErrors)
        return
      }

      const body = {
        signals: segmentedExecution
      }
      setIsFetching(true)
      const result = await patch<APIResponse<SegmentedExecutionAPIResponse>>(
        '/pricing-signals/actions/segmented-execution',
        body
      )
      setIsFetching(false)
      const signalsLength = result.data?.signals?.length

      if (signalsLength) {
        let message: string
        if (signalsLength > 1) {
          message = t('Components.modal.executePricingSignal.successMessage.multiple')
        } else {
          message = t('Components.modal.executePricingSignal.successMessage.single')
        }
        showToast(`${signalsLength} ${message}`, 'success')
      } else {
        setSuccessMessage(undefined)
        showToast(t('Components.modal.executePricingSignal.noSignalsUpadtedMessage'), 'danger')
      }
      dispatch(getPricingSignals(role))
      onPressCancel(true)
    } catch (e) {
      setErrors(e?.toString())
    }
  }

  return (
    <>
      <FlatList
        data={signals.signals}
        renderItem={renderItem}
        keyExtractor={(data, index) => data.id + index}
        style={{ width: '100%', maxHeight: '50vh' }}
        contentContainerStyle={{ alignItems: 'center', paddingBottom: '16px' }}
        CellRendererComponent={(props) => props.children}
      />
      <ReferenceText>{t('Components.modal.executePricingSignal.referencePrice').toUpperCase()}</ReferenceText>
      <H3>
        {t('Components.modal.executePricingSignal.currency').toUpperCase()}
        {' '}
        { signals && signals.lastMarketDataValue }
      </H3>
      <ButtonsContainer>
        <ButtonContainer>
          <Button
            testID='es-cancel-button'
            variant='secondary'
            onPress={() => {
              onPressCancel()
            }}
          >
            {t('Components.button.back')}
          </Button>
        </ButtonContainer>
        <ButtonContainer>
          <Button
            disabled={isFetching}
            testID='es-confirm-button'
            variant='primary'
            onPress={handleSubmit}
            loading={isFetching}
          >
            {t('Components.button.confirm')}
          </Button>
        </ButtonContainer>
      </ButtonsContainer>
    </>
  )
}

export default Segmented
