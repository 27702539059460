import { ThunkAction } from 'redux-thunk'

import { get } from '@hedgit/lib/utils/axios'

import {
  getSubscriptionOptionsPending,
  getSubscriptionOptionsFulfilled,
  getSubscriptionOptionsFailed
} from './actions'
import { SubscriptionOptionsActionTypes } from './types'

import { APIResponse } from '../../../types/api'
import { SubscriptionOptionsData } from '../../../interfaces/subscription-options'

export const getSubscriptionOptions = (): ThunkAction<void, unknown, unknown, SubscriptionOptionsActionTypes> => {
  return async (dispatch) => {
    dispatch(getSubscriptionOptionsPending())
    try {
      const response = await get<APIResponse<SubscriptionOptionsData>>('/farmers/subscription-options')
      dispatch(getSubscriptionOptionsFulfilled(response.data))
    } catch (error) {
      dispatch(getSubscriptionOptionsFailed(error as string))
    }
  }
}
