interface Params {
  marketPrice: number;
  steps: number;
  signals: number[];
}

export const getMarkToMarket = ({ marketPrice, steps, signals }: Params) => {
  if (!marketPrice || !steps) return 0

  const mappedSignals = signals.map(s => s || marketPrice)

  if (!mappedSignals?.length) return marketPrice

  const signalWeight = 1 / steps
  const signalsAvg = mappedSignals.reduce((acc, curr) => acc + (curr * signalWeight), 0)
  const marketPriceWeight = 1 - signalWeight * signals.length

  return marketPrice * marketPriceWeight + signalsAvg
}
