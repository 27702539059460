import React from 'react'
import { GestureResponderEvent } from 'react-native'
import { getDate } from '@hedgit/lib/translations/getTranslation'
import { t } from 'i18next'

import { planMap } from './constants'
import {
  CardContainer,
  CardContent,
  ContentContainer,
  IconContainer,
  PlanNameTitle,
  UpgradeButton,
  UpgradeButtonText,
  DetailsContent,
  Text,
  ContentText,
  Circle,
  CircleText,
  RemainingCodesContent,
  TextContainer
} from './styled'

import XpPlanIcon from '../../icons/xp-plan-icon'
import { SubscriptionPlan } from '../../../interfaces/subscription-plan'

interface XpPlanCardProps {
  subscriptionPlan: SubscriptionPlan;
  availableCodes: number | 'infinity';
  onPress?: (event: GestureResponderEvent) => void;
}

export const XpPlanCard = ({ subscriptionPlan, availableCodes, onPress }: XpPlanCardProps) => {
  const color = planMap[subscriptionPlan.planType.name]?.color || '#333C4C'
  const upgrade = subscriptionPlan.planType.hasUpgrade
  const planText =
    subscriptionPlan.planType.gen.length === 1
      ? t('Components.card.xpPlanText.oneGen')
      : t('Components.card.xpPlanText.bothGen')
  const planCodes =
    subscriptionPlan.planType.codes === 'infinity'
      ? t('Components.card.xpPlanText.unlimited')
      : subscriptionPlan.planType.codes
  const planAvailableCodes = availableCodes === 'infinity' ? '∞' : availableCodes
  return (
    <CardContainer testID={`xp-${subscriptionPlan.id}-card`}>
      <ContentContainer color={color}>
        <IconContainer>
          <XpPlanIcon />
        </IconContainer>
        <CardContent>
          <DetailsContent>
            <PlanNameTitle>{subscriptionPlan.planType.name}</PlanNameTitle>
          </DetailsContent>
          <DetailsContent>
            <RemainingCodesContent>
              <Circle>
                <CircleText color={color}>{planAvailableCodes}</CircleText>
              </Circle>
              <ContentText>{t('Components.card.xpPlanCodesRemaining')}</ContentText>
            </RemainingCodesContent>
            {upgrade && (
              <UpgradeButton onPress={onPress} testID="xp-plan-card-button">
                <UpgradeButtonText>{t('Components.card.xpPlanButton')}</UpgradeButtonText>
              </UpgradeButton>
            )}
          </DetailsContent>
        </CardContent>
      </ContentContainer>
      <TextContainer>
        <Text>
          {planCodes}
          {' '}
          {planText}
        </Text>
        <Text>
          {t('Components.card.xpStartDate')}
          {' '}
          {getDate(subscriptionPlan.startedDate)}
        </Text>
        <Text>
          {t('Components.card.xpEndDate')}
          {' '}
          {getDate(subscriptionPlan.expirationDate)}
        </Text>
      </TextContainer>
    </CardContainer>
  )
}
