import React from 'react'
import { useTranslation } from 'react-i18next'
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs'
import { theme } from '@hedgit/lib/theme'

import useSafeArea from '@hedgit/lib/hooks/use-safe-area'

import { SubscriptionStatus } from '@hedgit/lib/enums/subscription-status'
import { UserRole } from '@hedgit/lib/enums/user-role'

import PricingProgramIcon from '@hedgit/lib/components/icons/pricing-program'
import PricingSignalIcon from '@hedgit/lib/components/icons/pricing-signal'
import AlgorithmIcon from '@hedgit/lib/components/icons/algorithm'

import { useSelector } from 'store'

import { PricingSignalsTabs } from 'routes/pricing-signals/tabs'
import { AlgorithmsTabs } from 'routes/algorithms/tabs'
import { PricingProgramsTabs } from 'routes/pricing-programs/tabs'

import useSuspendedToast from 'hooks/use-suspended-toast'

import selectPendingPricingSignalsFarmer from '../../store/selectors/selectPendingPricingSignalsFarmer'
import selectPendingPricingSignalsBroker from '../../store/selectors/selectPendingPricingSignalsBroker'
import selectActivePricingProgramsBroker from '../../store/selectors/selectActivePricingProgramsBroker'
import selectExecutedPricingSignalsBroker from '../../store/selectors/selectExecutedPricingSignalsBroker'

const Tab = createBottomTabNavigator()

const { colors } = theme

const getBadgeOptions = (notifications: number) => ({
  tabBarBadge: (notifications <= 9 ? notifications : '9+') || undefined,
  tabBarBadgeStyle: {
    backgroundColor: theme.colors.red,
    minWidth: notifications <= 9 ? '18.75px' : '26px'
  }
})

const NavigationTabs = () => {
  const { t } = useTranslation()
  const { bottom } = useSafeArea()

  const brokerList = useSelector(selectActivePricingProgramsBroker())
  const userRole = useSelector(state => state.auth.currentUser?.role) as UserRole

  const brokerPendingBadge = useSelector(selectPendingPricingSignalsBroker()).length
  const farmerPendingBadge = useSelector(selectPendingPricingSignalsFarmer()).length
  const brokerExecuted = useSelector(selectExecutedPricingSignalsBroker())

  const { isSuspended } = useSuspendedToast()

  const subscriptionsBadge = brokerList.reduce((accum, pricingProgram) => {
    const { subscribers } = pricingProgram

    const pendingSubscriptions = subscribers.reduce((accum, subscriber) => {
      if ([SubscriptionStatus.pendingCancellation, SubscriptionStatus.pending].includes(subscriber.status)) {
        accum++
      }
      return accum
    }, 0)

    return accum + pendingSubscriptions
  }, 0)

  const signalsBadge = () => {
    const pendingOnExectuedTab = brokerExecuted.reduce((accum, pricingProgram) => {
      const { signals } = pricingProgram

      const pendingSignals = signals.reduce((accum, signal) => {
        if (!signal.executionPrice) {
          accum++
        }
        return accum
      }, 0)
      return accum + pendingSignals
    }, 0)

    if ([UserRole.broker, UserRole.broker_farmer].includes(userRole as UserRole) && isSuspended) {
      return 0
    }
    if ([UserRole.broker, UserRole.broker_farmer].includes(userRole)) {
      return brokerPendingBadge + pendingOnExectuedTab
    }
    if (userRole === UserRole.farmer) {
      return farmerPendingBadge
    }
    return 0
  }

  return (
    <Tab.Navigator
      screenOptions={{
        headerTitleAlign: 'center',
        headerShown: false,
        tabBarActiveTintColor: colors.white,
        tabBarInactiveTintColor: colors.inactiveGray,
        tabBarStyle: {
          height: 67 + bottom,
          paddingBottom: bottom,
          backgroundColor: colors.primary
        },
        tabBarLabelPosition: 'below-icon',
        tabBarLabelStyle: {
          fontFamily: 'Lato',
          fontSize: 10,
          fontWeight: '500'
        },
        tabBarItemStyle: {
          paddingVertical: 10
        }
      }}
    >
      <Tab.Screen
        name='PricingPrograms'
        component={PricingProgramsTabs}
        options={{
          tabBarTestID: 'nv-pricingProgram-tab',
          tabBarIcon: (PricingProgramIcon),
          title: t('NavigationTabs.pricingProgramsTab').toUpperCase(),
          ...getBadgeOptions(subscriptionsBadge)
        }}
      />
      <Tab.Screen
        name='Algorithms'
        component={AlgorithmsTabs}
        options={{
          tabBarTestID: 'nv-algorithm-tab',
          tabBarIcon: (AlgorithmIcon),
          title: t('NavigationTabs.algorithms').toUpperCase()
        }}
      />
      <Tab.Screen
        name='PricingSignals'
        component={PricingSignalsTabs}
        options={{
          tabBarTestID: 'nv-signals-tab',
          tabBarIcon: (PricingSignalIcon),
          title: t('NavigationTabs.signals').toUpperCase(),
          ...getBadgeOptions(signalsBadge())
        }}
      />
    </Tab.Navigator>
  )
}

export default NavigationTabs
