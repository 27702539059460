import React from 'react'
import { useTranslation } from 'react-i18next'
import { TouchableOpacity, Linking } from 'react-native'

import BodyEmail from '@hedgit/lib/components/typography/body-email'

import { Content, SubContent, Body } from './styled'

import { EmptyCard } from '../cards/empty-card'
import { Entity } from '../../enums/entity'

const SuspendedScreen = () => {
  const { t } = useTranslation()

  return (
    <Content>
      <SubContent>
        <EmptyCard
          entity={Entity.pricingSignal}
        >
          <Body>
            {t('Suspended.Message')}
            <TouchableOpacity>
              <BodyEmail style={{ color: '#33A3D9' }} onPress={() => Linking.openURL('mailto:soporte@hedgit.ai')}>
                soporte@hedgit.ai
              </BodyEmail>
            </TouchableOpacity>
          </Body>
        </EmptyCard>
      </SubContent>
    </Content>
  )
}

export default SuspendedScreen
