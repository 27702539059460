import React from 'react'
import { Modal as RNModal, GestureResponderEvent, TouchableOpacity, Linking } from 'react-native'
import { useTranslation } from 'react-i18next'

import Title from '@hedgit/lib/components/typography/title'

import {
  CenteredView,
  ModalView,
  ButtonContainer,
  IconContainer,
  ButtonsContainer,
  Button,
  BodyContainer,
  Body,
  CurrentPlan
} from './styled'

import CheckIcon from '../../icons/check-icon'
import BodyEmail from '../../typography/body-email'

export interface ModalProps {
  visible?: boolean;
  onPress?: (event: GestureResponderEvent) => void;
  onCloseModal?: () => void;
  currentPlan?: string;
}

const UpgradeModal = ({ onPress, visible, currentPlan, onCloseModal }: ModalProps) => {
  const { t } = useTranslation()

  return (
    <RNModal
      animationType="fade"
      visible={visible}
      presentationStyle="overFullScreen"
      statusBarTranslucent={false}
      transparent
      onRequestClose={onCloseModal}
    >
      <CenteredView testID="upgrade-modal">
        <ModalView>
          <IconContainer>
            <CheckIcon />
          </IconContainer>
          <Title>{t('Components.modal.upgradeModal.title')}</Title>
          <CurrentPlan>{currentPlan}</CurrentPlan>
          <BodyContainer>
            <Body>
              {t('Components.modal.upgradeModal.body')}
              <TouchableOpacity>
                <BodyEmail style={{ color: '#33A3D9' }} onPress={() => Linking.openURL('mailto:soporte@hedgit.ai')}>
                  soporte@hedgit.ai
                </BodyEmail>
              </TouchableOpacity>
            </Body>
          </BodyContainer>
          <ButtonsContainer>
            <ButtonContainer>
              <Button testID="upgrade-modal-button" onPress={onPress} variant="primary">
                {t('Components.button.ok')}
              </Button>
            </ButtonContainer>
          </ButtonsContainer>
        </ModalView>
      </CenteredView>
    </RNModal>
  )
}

export default UpgradeModal
