import React, { useState } from 'react'
import { Text, TouchableOpacity } from 'react-native'
import { useTranslation } from 'react-i18next'
import { APIResponse } from '@hedgit/lib/types/api'
import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { useDispatch, useSelector } from '@hedgit/web/src/store'

import { patch } from '@hedgit/lib/utils/axios'

import { RootStackParamList } from '@hedgit/lib/interfaces/root-stack-params-list'
import { Subscriber, Subscription } from '@hedgit/lib/interfaces/subscription'

import { UserModal } from '@hedgit/lib/components/modals/user-modal'

import {
  Card,
  CardContent,
  DetailsContent,
  SeeBox,
  SeeText,
  DetailsText,
  SubscriberText,
  SubscriberName,
  UserIcon,
  TonsText,
  NewText,
  CancelText,
  UserIconContainer,
  ExpiredText
} from './styled'

import { SubscriptionStatus } from '../../../enums/subscription-status'
import { getSubscriptions } from '../../../store/modules/subscriptions/thunks'
import { UserRole } from '../../../enums/user-role'
import { getDate } from '../../../translations/getTranslation'
import { Modal } from '../../modals/basic-modal'
import { Status } from '../../../enums/status'

interface SubscriberProps {
 subscriber: Subscriber;
 pricingProgramStartDate: Date | undefined;
}

interface cancelResponse {
  subscription: Subscription;
}

type NavigationProps = NativeStackNavigationProp<RootStackParamList, 'PricingProgramSubscribers'>

export const SubscriberCard = ({ subscriber, pricingProgramStartDate }: SubscriberProps) => {
  const navigation = useNavigation<NavigationProps>()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [userModalVisible, setUserModalVisible] = useState(false)
  const [infoMessage, setInfoMessage] = useState(null)

  const subscriptionTime = getDate(subscriber.subscriptionTime)
  const toDayDate = new Date()
  const ppDate = new Date(pricingProgramStartDate)
  const isExpired = ppDate < toDayDate

  const nameInitial = subscriber.firstName.charAt(0)
  const lastNameInitial = subscriber.lastName.charAt(0)
  const currentUser = useSelector(state => state.auth.currentUser)
  const subscriptionId = subscriber.subscriptionId
  const [isLoadingCancel, setIsLoadingCancel] = useState(false)
  const [isLoadingConfirm, setIsLoadingConfirm] = useState(false)

  const brokerSubscriptions = useSelector((state) => state.subscriptions.brokerList)

  const brokerSubscription = brokerSubscriptions.find((brokerSubscription) =>
    brokerSubscription.subscribers.find((subscriber) => subscriber.subscriptionId === subscriptionId))

  const buttonConfirmActions = async () => {
    if (currentUser?.status !== Status.active) return
    if (subscriber.status === SubscriptionStatus.pendingCancellation) {
      if (subscriptionId) {
        try {
          await patch<APIResponse<cancelResponse>>(
            `/subscriptions/${subscriptionId}/actions/cancel`
          )
          setUserModalVisible(false)
          dispatch(getSubscriptions(UserRole.broker))
        } catch (error) {
          console.log(error)
        }
      }
    } else if (subscriber.status === SubscriptionStatus.pending) {
      if (subscriptionId) {
        try {
          setIsLoadingConfirm(true)
          const response = await patch<APIResponse<cancelResponse>>(
            `/subscriptions/${subscriptionId}/actions/accepted`
          )
          setIsLoadingConfirm(false)
          setUserModalVisible(false)

          if (response.data.subscription.status === SubscriptionStatus.cancelled) {
            setInfoMessage(t('SubscriberCard.infoModal.subscriptionCancelled'))
          } else if (response.data.subscription.status === SubscriptionStatus.rejected) {
            setInfoMessage(t('SubscriberCard.infoModal.subscriptionRejected'))
          } else {
            setInfoMessage(t('SubscriberCard.infoModal.subscriptionAccepted'))
          }
        } catch (error) {
          console.log(error)
        }
      }
    }
  }

  const buttonRejectActions = async () => {
    if (subscriber.status === SubscriptionStatus.pendingCancellation) {
      if (subscriptionId) {
        try {
          await patch<APIResponse<cancelResponse>>(
            `/subscriptions/${subscriptionId}/actions/reject-cancellation`
          )
          dispatch(getSubscriptions(UserRole.broker))
          setUserModalVisible(false)
          navigation.navigate('NavigationTabs')
        } catch (error) {
          console.log(error)
        }
      }
    } else if (subscriber.status === SubscriptionStatus.pending) {
      if (subscriptionId) {
        try {
          setIsLoadingCancel(true)
          await patch<APIResponse<cancelResponse>>(
            `/subscriptions/${subscriptionId}/actions/reject-subscription`
          )
          setIsLoadingCancel(false)
          dispatch(getSubscriptions(UserRole.broker))
          setUserModalVisible(false)
          navigation.navigate('NavigationTabs')
        } catch (error) {
          console.log(error)
        }
      }
    }
  }

  const onConfirmSubscription = async () => {
    await dispatch(getSubscriptions(UserRole.broker))
    setInfoMessage(null)
    navigation.navigate('PricingProgramDetails', {
      id: brokerSubscription?.pricingProgramId,
      crop: brokerSubscription?.pricingProgram.product.crop.name.es,
      month: brokerSubscription?.pricingProgram.product.month,
      year: brokerSubscription?.pricingProgram.product.year,
      state: brokerSubscription?.pricingProgram.status,
      role: UserRole.broker
    })
  }

  return (
    <TouchableOpacity
      onPress={() => setUserModalVisible(true)}
    >
      <Card testID={`pps-subscribers-${subscriber.id}-card`}>
        <CardContent testID={`pps-subscribers-${subscriber.id}-cardContent`}>
          <DetailsContent>
            <UserIconContainer>
              <UserIcon>
                {nameInitial}
                {lastNameInitial}
              </UserIcon>
            </UserIconContainer>
            <DetailsText>
              <SubscriberName>
                <Text>{subscriber.firstName}</Text>
                {' '}
                <Text>{subscriber.lastName}</Text>
                {
                  subscriber.status === SubscriptionStatus.pending
                    ? isExpired
                      ? (
                        <ExpiredText>
                          {t('Components.card.expired')}
                        </ExpiredText>
                        )
                      : (
                        <NewText>
                          {t('Components.card.new')}
                        </NewText>
                        )
                    : null
                }
                {
                  subscriber.status === SubscriptionStatus.pendingCancellation
                    ? (
                      <CancelText>
                        {t('Components.card.cancel')}
                      </CancelText>
                      )
                    : null
                }
              </SubscriberName>
              <SubscriberText>
                <TonsText>
                  {subscriber.tons}
                  {' '}
                  {t('Components.card.tons')}
                </TonsText>
                <Text>
                  {subscriptionTime}
                </Text>
              </SubscriberText>
            </DetailsText>
          </DetailsContent>
          <SeeBox>
            <SeeText>
              {t('Subscribers.see')}
            </SeeText>
          </SeeBox>
        </CardContent>
        <UserModal
          userName={subscriber.firstName + ' ' + subscriber.lastName}
          body={t('Subscribers.date') + subscriptionTime}
          phone={subscriber.phone}
          email={subscriber.email}
          visible={userModalVisible}
          onPress={() => setUserModalVisible(false)}
          firstLetter={nameInitial}
          secondLetter={lastNameInitial}
          status={subscriber.status}
          isExpired={isExpired}
          buttons={[
            {
              label: t('Components.button.reject'),
              onPress: () => buttonRejectActions(),
              testID: 'reject-modal-button',
              variant: 'secondary',
              isLoading: isLoadingCancel,
              disabled: isLoadingCancel
            },
            {
              label: t('Components.button.confirm'),
              onPress: () => buttonConfirmActions(),
              testID: 'confirm-modal-button',
              variant: 'primary',
              isLoading: isLoadingConfirm,
              disabled: isLoadingConfirm || isLoadingCancel
            }
          ]}
        />
        <Modal
          title={infoMessage}
          variant="confirm"
          visible={!!infoMessage}
          buttons={[
            {
              label: t('Components.button.ok'),
              onPress: onConfirmSubscription,
              testID: 'no-delete-broker-modal-button',
              variant: 'secondary'
            }
          ]}
        />
      </Card>
    </TouchableOpacity>
  )
}
