import React, { useState } from 'react'
import { ScrollView } from 'react-native'
import { useTranslation } from 'react-i18next'
import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp, NativeStackScreenProps } from '@react-navigation/native-stack'

import { RootStackParamList } from '@hedgit/lib/interfaces/root-stack-params-list'

import { SubscribeCodeCard } from '@hedgit/lib/components/cards/subscribe-code-card/subscribe-code-card'
import {
  SubscribePremiumPartner
} from '@hedgit/lib/components/cards/subscribe-premium-partner/subscribe-premium-partner'
import { NoXpPremiumModal } from '@hedgit/lib/components/modals/no-xp-premium-modal'

import { useSelector } from 'store'

import {
  CardBox,
  Content,
  SubContent,
  SubTitle,
  CardText,
  Footer,
  Image
} from './styled'

type NavigationType = NativeStackNavigationProp<RootStackParamList, 'SubscriptionCode'>
type Props = NativeStackScreenProps<RootStackParamList, 'AlgorithmSubscriptionOptions'>

const AlgorithmSubscriptionOptions = ({ route }: Props) => {
  const { id, name } = route.params
  const { t } = useTranslation()
  const navigation = useNavigation<NavigationType>()
  const hasPremiumXP = useSelector((store) => store.subscriptionOptions.hasPremiumXp)
  const [showModal, setShowModal] = useState(false)

  const handleOnPress = () => {
    hasPremiumXP
      ? navigation.navigate('SubscriptionXpPremium', { pricingProgramId: id, name })
      : setShowModal(true)
  }
  return (
    <Content>
      <SubContent>
        <ScrollView>
          <CardBox>
            <CardText>
              <SubTitle>
                {t('AlgorithmFarmerOptions.subscriptionPremiumPartner.title')}
              </SubTitle>
            </CardText>
            <SubscribePremiumPartner onPress={handleOnPress} />
          </CardBox>
          <CardBox>
            <CardText>
              <SubTitle>
                {t('AlgorithmFarmerOptions.subscriptionXp')}
              </SubTitle>
            </CardText>
            <SubscribeCodeCard onPress={() => navigation.navigate('SubscriptionCode', {
              id: id,
              name: name
            })}
            />
          </CardBox>
        </ScrollView>
        <NoXpPremiumModal
          visible={showModal}
          onPress={() => setShowModal(false)}
          onCloseModal={() => setShowModal(false)}
        />
        <Footer>
          <Image source={require('@hedgit/lib/components/sidebar/assets/icons/hedgit-icon-sm.png')} />
        </Footer>
      </SubContent>
    </Content>
  )
}

export default AlgorithmSubscriptionOptions
