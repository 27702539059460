import styled from 'styled-components/native'

import Caption from '@hedgit/lib/components/typography/caption'
import { Background as BackgroundSvg } from '@hedgit/lib/components/background'

export const Container = styled.View`
  justify-content: space-between;
  align-items: center;
  align-self: center;
  padding: 16px;
  padding-top: 0;
  border-radius: 10px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 20px #00000021;
  opacity: 1;
  margin-top: 40px;
  max-width: 600px;
`

export const Header = styled.View`
  z-index: 10;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 64px;
  margin-bottom: 24px;
`

export const BackButtonContainer = styled.View`
  position: absolute;
  left: -16px;
`

export const UserIcon = styled.View`
  position: absolute;
  background-color: #33A3D9;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  justify-content: center;
  align-items: center;
  align-self: center;
  margin-top: -50px;
  box-shadow: 0 16px 40px #00000029;
  padding-bottom: 6px;
`

export const UserText = styled.Text`
  font-size: 46px;
  color: #FFFFFF;
`

export const StyledBackground = styled(BackgroundSvg)`
  position: absolute;
  height: 110vh;
  left: -100vw;
  top: -20vh;
`

export const InputsContainer = styled.View`
  width: 100%;
  gap: 10px;
  margin-bottom: 24px;
  padding-top: 80px;
  flex-direction: column;
  justify-content: space-between;
`

export const Requirements = styled(Caption)`
  color: ${props => props.theme.colors.gray};
  text-align: center;
`

export const ButtonContainer = styled.View`
  width: 100%;
  gap: 20px;
`

export const Error = styled.Text`
    color: ${props => props.theme.colors.red};
`

export const FieldContainer = styled.View`
  margin-bottom: 26px;
`

export const AreaCodeAndPhoneContainer = styled.View`
  flex-direction: row;
  justify-content: space-between;
`

export const AreaCodeContainer = styled.View`
  width: 28%;
`

export const PhoneContainer = styled.View`
  width: 70%;
`

export const CustomErrorWrapper = styled.View`
  flex-direction: row;
  gap: 4px;
  margin-top: 8px;
  align-items: flex-start;
  max-width: 100px;
`

export const CustomErrorIconWrapper = styled.View`
  height: 1lh;
  justify-content: center;
  font-size: 12px;
`

export const CustomErrorText = styled.Text`
  color: ${({ theme }) => theme.colors.red};
  font-size: 12px;
  font-family: Lato;
`
