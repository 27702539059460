/* eslint-disable max-len */
import React from 'react'
import { Svg, Path } from 'react-native-svg'

import { theme } from '../../theme'

interface IconProps {
  isSubscriptionScreen?: boolean;
}

const XpPlanIcon = ({ isSubscriptionScreen = false }: IconProps) => {
  const color = isSubscriptionScreen ? theme.colors.orange : theme.colors.white
  const opacity = isSubscriptionScreen ? 0.8 : 0.2
  return (
    <Svg width="62" height="62" viewBox="0 0 65 60">
      <Path d="M33.4736 0C50.3038 0 63.9473 13.4476 63.9473 30.0361C63.9473 46.6246 50.3038 60.0723 33.4736 60.0723C16.6435 60.0723 3 46.6246 3 30.0361C3 13.4476 16.6435 0 33.4736 0Z" fill={color} opacity={opacity} />
      <Path d="M53.4921 31.2422L36.3623 14.2512C35.6771 13.5716 34.7255 13.1562 33.6786 13.1562H20.3555C18.2618 13.1562 16.5488 14.8553 16.5488 16.932V30.1472C16.5488 31.1855 16.9676 32.1295 17.6718 32.828L34.8016 49.8189C35.4868 50.4986 36.4384 50.9139 37.4853 50.9139C38.5321 50.9139 39.4837 50.4986 40.1689 49.8001L53.4921 36.5849C54.1964 35.9052 54.6151 34.9613 54.6151 33.923C54.6151 32.8846 54.1773 31.9218 53.4921 31.2422ZM23.2104 22.5957C21.6307 22.5957 20.3555 21.3308 20.3555 19.7638C20.3555 18.1969 21.6307 16.932 23.2104 16.932C24.7902 16.932 26.0654 18.1969 26.0654 19.7638C26.0654 21.3308 24.7902 22.5957 23.2104 22.5957Z" fill="white" />
    </Svg>
  )
}

export default XpPlanIcon
