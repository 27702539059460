import React, { useState, BaseSyntheticEvent } from 'react'
import { FormControl } from 'native-base'
import { theme } from '@hedgit/lib/theme'
import { Controller, FieldValues, useForm, useFormState } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import { useSelector } from 'react-redux'
import { NativeStackScreenProps } from '@react-navigation/native-stack'
import useCustomToast from '@hedgit/web/src/hooks/use-custom-toast'

import { verifySubscriptionCode } from '@hedgit/lib/store/modules/subscription-codes'

import yup from '@hedgit/lib/utils/yup'

import { RootStackParamList } from '@hedgit/lib/interfaces/root-stack-params-list'

import ErrorIcon from '@hedgit/lib/components/icons/error'
import SubCodeIcon from '@hedgit/lib/components/icons/sub-code-icon'

import { RootState, useThunkDispatch } from 'store'

import {
  Main,
  InputContainer,
  StyledInput,
  InputWrapper,
  Button,
  SubContainer,
  RequirementsContainer,
  RequirementsBold,
  Requirements,
  Text,
  IconBox,
  TextErrorCodeInvalid,
  ErrorMessage,
  UnderlineContainer,
  Underline
} from './styled'

const { colors } = theme

type Props = NativeStackScreenProps<RootStackParamList, 'SubscriptionCode'>

const CodeSubscription = ({ route, navigation }: Props) => {
  const { t } = useTranslation()
  const dispatch = useThunkDispatch()
  const { showToast } = useCustomToast()

  const pricingProgram = route.params

  const subscriptionCodeSchema = yup.object({
    subscriptionCode: yup.string()
      .required(t('ErrorMessages.subscriptionCode.required'))
      .matches(/^[a-zA-Z0-9]/, t('ErrorMessages.subscriptionCode.matches'))
      .length(6, t('ErrorMessages.subscriptionCode.min'))
  })

  const {
    control,
    handleSubmit: handleSubmitForm,
    formState: { errors },
    clearErrors,
    watch
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    defaultValues: {
      subscriptionCode: ''
    },
    resolver: yupResolver(subscriptionCodeSchema)
  })

  const subscriptionCode = watch('subscriptionCode')
  const isFetching = useSelector((state: RootState) => state.subscriptionCodes.isFetching)
  const [isValid, setIsValid] = useState<boolean>()

  const handleOnChange = <T extends BaseSyntheticEvent, >(e: T, onChange: (...event: Array<T>) => void) => {
    const value = e.target.value.toUpperCase()
    if (value.length <= 6) onChange(value.replace(/[^a-z0-9]+/i, ''))
  }

  const handleSubmit = async (data: FieldValues) => {
    const { isValid, broker, errorMsg } = await dispatch(verifySubscriptionCode({
      code: data.subscriptionCode.toLowerCase(),
      pricingProgram: pricingProgram.id
    }))
    setIsValid(isValid)
    if (errorMsg?.message === 'Request failed with status code 429') {
      return showToast(t('AlgorithmSubscription.subscriptionCode.toast.error'), 'danger')
    }
    if (!isValid) return

    navigation.navigate('SubscriptionCodeValid', {
      pricingProgramId: pricingProgram.id,
      name: pricingProgram.name,
      code: subscriptionCode,
      broker: `${broker?.firstName} ${broker?.lastName}`
    })
  }

  const { isDirty } = useFormState({
    control
  })

  const isDisabled = !isDirty || subscriptionCode.length < 6

  return (
    <Main>
      <IconBox>
        <SubCodeIcon />
      </IconBox>
      <Text>{t('AlgorithmFarmerOptions.subscriptionCode.title')}</Text>
      <SubContainer>
        <InputWrapper>
          <InputContainer>
            <FormControl isInvalid={'subscriptionCode' in errors} style={{ alignItems: 'center' }}>
              <Controller
                name="subscriptionCode"
                control={control}
                defaultValue=""
                render={({ field: { value, onChange, onBlur } }) => (
                  <StyledInput
                    testID="av-code-input"
                    value={value}
                    onChange={e => handleOnChange(e, onChange)}
                    onFocus={() => clearErrors('subscriptionCode')}
                    onBlur={onBlur}
                    keyboardType="default"
                    maxLength={6}
                  />
                )}
              />
              <UnderlineContainer>
                {[0, 1, 2, 3, 4, 5].map((v, i) => (
                  <Underline
                    key={v}
                    style={{ backgroundColor: subscriptionCode.length === i ? colors.primary : colors.lightGray }}
                  />
                ))}
              </UnderlineContainer>
              <ErrorMessage
                testID="av-error-message"
                leftIcon={<ErrorIcon width={14} height={14} color={colors.red} />}
              >
                {errors.subscriptionCode?.message}
              </ErrorMessage>
            </FormControl>
          </InputContainer>
          <RequirementsContainer>
            <Requirements testID="sc-requirements-text">
              {t('CodeSubscription.requirements.firstPart')}
              <RequirementsBold>
                {t('CodeSubscription.requirements.code')}
              </RequirementsBold>
              {t('CodeSubscription.requirements.secondPart')}
            </Requirements>
            {isValid === false && (
            <TextErrorCodeInvalid testID="av-invalidCode-error-message">
              {t('CodeSubscription.error.invalidCode')}
            </TextErrorCodeInvalid>
            )}
          </RequirementsContainer>
        </InputWrapper>
        <Button
          testID="als-subscribe-button"
          onPress={handleSubmitForm(handleSubmit)}
          disabled={isDisabled}
          loading={isFetching}
        >
          {t('Components.button.next').toUpperCase()}
        </Button>
      </SubContainer>
    </Main>
  )
}

export default CodeSubscription
