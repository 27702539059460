import React from 'react'
import { createPortal } from 'react-dom'

import { PortalProps } from '../interfaces'

export const Portal = ({ children }: PortalProps) => {
  const el = document.createElement('div')

  React.useEffect(() => {
    document.body.appendChild(el)
    return () => {
      document.body.removeChild(el)
    }
  }, [el])

  return createPortal(children, el)
}
