import { Reducer } from 'redux'

import { UsersActionTypes, UsersState, UsersActionConst } from './types'

import { AuthActionConsts, SignOutActionType } from '../auth'

export const initialState: UsersState = {
  list: [],
  isFetching: false,
  error: ''
}

export const userReducer: Reducer<UsersState, UsersActionTypes | SignOutActionType> = (
  state = initialState,
  action
): UsersState => {
  switch (action.type) {
    case UsersActionConst.GET_USERS_PENDING:
      return {
        ...state,
        isFetching: true
      }
    case UsersActionConst.GET_USERS_FULFILLED:
      return {
        ...state,
        isFetching: false,
        list: action.payload
      }
    case UsersActionConst.GET_USERS_FAILED:
      return {
        ...state,
        error: action.payload,
        isFetching: false
      }
    case AuthActionConsts.SIGN_OUT:
      return {
        ...initialState
      }
    default:
      return state
  }
}
