import React from 'react'
import { Svg, Path } from 'react-native-svg'

const SharedButton = () => {
  return (
    <Svg width={14} height={14} viewBox="0 0 16 16" fill="none">
      <Path
          // eslint-disable-next-line max-len
        d="M11.3333 10.4819C10.9111 10.4819 10.5333 10.6627 10.2444 10.9458L6.28333 8.44578C6.31111 8.30723 6.33333 8.16867 6.33333 8.0241C6.33333 7.87952 6.31111 7.74096 6.28333 7.60241L10.2 5.12651C10.5 5.42771 10.8944 5.61446 11.3333 5.61446C12.2556 5.61446 13 4.80723 13 3.80723C13 2.80723 12.2556 2 11.3333 2C10.4111 2 9.66667 2.80723 9.66667 3.80723C9.66667 3.95181 9.68889 4.09036 9.71667 4.22892L5.8 6.70482C5.5 6.40361 5.10556 6.21687 4.66667 6.21687C3.74444 6.21687 3 7.0241 3 8.0241C3 9.0241 3.74444 9.83133 4.66667 9.83133C5.10556 9.83133 5.5 9.64458 5.8 9.34337L9.75556 11.8494C9.72778 11.9759 9.71111 12.1084 9.71111 12.241C9.71111 13.2108 10.4389 14 11.3333 14C12.2278 14 12.9556 13.2108 12.9556 12.241C12.9556 11.2711 12.2278 10.4819 11.3333 10.4819Z"
        fill="#313C4D"
      />
    </Svg>
  )
}

export default SharedButton
