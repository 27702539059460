import React, { useCallback } from 'react'
import {
  FlatList,
  ListRenderItem,
  ListRenderItemInfo
} from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import selectCancelledPricingProgramsFarmer from '@hedgit/web/src/store/selectors/selectCancelledPricingProgramsFarmer'
import { getTranslation } from '@hedgit/lib/translations/getTranslation'
import { useTranslation } from 'react-i18next'
import { useToast } from 'react-native-toast-notifications'

import { PricingProgramStatus } from '@hedgit/lib/enums/pricing-program-status'
import { UserRole } from '@hedgit/lib/enums/user-role'
import { Entity } from '@hedgit/lib/enums/entity'
import { Status } from '@hedgit/lib/enums/status'

import { RootStackParamList } from '@hedgit/lib/interfaces/root-stack-params-list'
import { FarmerSubscription } from '@hedgit/lib/interfaces/subscription'

import PricingProgramCard from '@hedgit/lib/components/cards/pricing-program'
import { EmptyCard } from '@hedgit/lib/components/cards/empty-card'
import { LoadingScreen } from '@hedgit/lib/components/loading-screen'

import { useSelector } from 'store'

import useMediaQueries from 'hooks/use-media-queries'

import { CardClickArea, Content, SubContent } from '../styled'
import { RouteType } from '../tabs'

type PricingProgramsFarmerListProps = {
  route: RouteType;
}

type NavigationType = NativeStackNavigationProp<RootStackParamList, 'NavigationTabs'>

const PricingProgramsFarmerList = ({ route }: PricingProgramsFarmerListProps) => {
  const { t } = useTranslation()
  const navigation = useNavigation<NavigationType>()
  const { columns } = useMediaQueries()

  const toast = useToast()

  const list = useSelector(selectCancelledPricingProgramsFarmer())
  const isFetching = useSelector(state => state.subscriptions.isFetching)

  const renderCard: ListRenderItem<FarmerSubscription> = useCallback(
    (subscription: ListRenderItemInfo<FarmerSubscription>) => {
      return (
        <CardClickArea
          onPress={() => {
            if (subscription.item.broker.status === Status.suspended) {
              toast.show(t('Suspended.farmer'), {
                type: 'success',
                successColor: 'gray',
                placement: 'bottom',
                duration: 6000,
                animationType: 'slide-in'
              })
            } else {
              navigation.navigate('PricingProgramDetails', {
                id: subscription.item.id,
                crop: getTranslation(subscription.item.pricingProgram.product.crop.name) || '',
                month: subscription.item.pricingProgram.product.month,
                year: subscription.item.pricingProgram.product.year,
                state: route.name.toLowerCase() as PricingProgramStatus,
                role: UserRole.farmer
              })
            }
          }}
        >
          <PricingProgramCard
            role={UserRole.farmer}
            state={route.name.toLowerCase() as PricingProgramStatus}
            subscription={subscription.item}
            subscribers={[]}
          />
        </CardClickArea>
      )
    },
    [navigation, route.name, t, toast]
  )

  if (isFetching && !list.length) {
    return (
      <LoadingScreen />
    )
  }

  return (
    <Content>
      <SubContent>
        <FlatList
          key={`flatListWith-${columns}-Columns`}
          numColumns={columns}
          testID='pp-farmer-cancelled-flatList'
          data={list}
          renderItem={renderCard}
          keyExtractor={data => data.id}
          style={{ width: '100%' }}
          contentContainerStyle={{ alignItems: 'center', paddingBottom: '16px' }}
          ListEmptyComponent={<EmptyCard text={t('Empty.pricingPrograms.cancelled')} entity={Entity.pricingProgram} />}
        />
      </SubContent>
    </Content>
  )
}

export default PricingProgramsFarmerList
