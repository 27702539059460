import React from 'react'
import { View, Image } from 'react-native'
import { useSelector, useThunkDispatch } from '@hedgit/web/src/store'
import { useTranslation } from 'react-i18next'
import { useForm, Controller } from 'react-hook-form'
import { NativeStackScreenProps } from '@react-navigation/native-stack'
import { yupResolver } from '@hookform/resolvers/yup'
import useCustomToast from '@hedgit/web/src/hooks/use-custom-toast'
import {
  FormControl
} from 'native-base'
import { theme } from '@hedgit/lib/theme'

import { updateFarmer } from '@hedgit/lib/store/modules/auth'

import { ConfirmPhoneSchema } from '@hedgit/lib/utils/validations/user'
import { phoneRegex } from '@hedgit/lib/utils/regex'

import { RootStackParamList } from '@hedgit/lib/interfaces/root-stack-params-list'

import { Background as BackgroundSvg } from '@hedgit/lib/components/background'
import { SharedInput } from '@hedgit/lib/components/shared/input'
import { formatNumber } from '@hedgit/lib/components/auth/sign-up/farmer/farmer-sign-up'
import ErrorIcon from '@hedgit/lib/components/icons/error'
import PencilIcon from '@hedgit/lib/components/icons/pencil'
import { SharedButton } from '@hedgit/lib/components/shared/button'
import { FieldContainer } from '@hedgit/lib/components/settings'

import {
  Container,
  BackgroundContainer,
  ImageContainer,
  AreaCodeAndPhoneContainer,
  AreaCodeContainer,
  PhoneContainer,
  Br,
  CustomErrorWrapper,
  CustomErrorIconWrapper,
  CustomErrorText,
  Requirements,
  Content,
  Title,
  SubTitle,
  InputContainer,
  SignInButtonContainer,
  ButtonsContainer,
  ButtonTitle,
  Error
} from './styled'

const { colors } = theme

type Props = NativeStackScreenProps<RootStackParamList, 'ConfirmPhone'>

const ConfirmPhone = ({ navigation }: Props) => {
  const dispatch = useThunkDispatch()
  const { t } = useTranslation()
  const currentUser = useSelector(store => store.auth.currentUser)
  const error = useSelector(state => state.auth.error)
  const { showToast } = useCustomToast()

  const {
    control,
    handleSubmit: handleSubmitForm,
    formState: { errors, isDirty, isValid },
    clearErrors,
    watch,
    setError
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: {
      phone: '',
      areaCode: ''
    },
    resolver: yupResolver(ConfirmPhoneSchema)
  })

  const handlePhoneInputFocus = () => {
    clearErrors('phone')
  }

  const areaCode = watch('areaCode')
  const phone = watch('phone')

  const handleSubmit = async (data: { areaCode: string; phone: string }) => {
    try {
      clearErrors()
      const phoneNumber = `+549${data.areaCode}${data.phone}`
      if (phoneNumber !== currentUser?.phone) {
        setError('phone', {
          type: 'value',
          message: t('ConfirmPhone.phoneError')
        })
        showToast(t('ConfirmPhone.phoneError'), 'danger')
      } else {
        const { success } = await dispatch(updateFarmer({ ...currentUser, areaCode: data.areaCode }))
        if (success) {
          showToast(t('ConfirmPhone.success'), 'success')
          navigation.navigate('NavigationTabs', { screen: 'PricingPrograms' })
        } else {
          showToast(t('ConfirmPhone.error'), 'danger')
        }
      }
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <Container>
      <View nativeID="recaptcha-verifier" />
      <BackgroundContainer>
        <BackgroundSvg />
      </BackgroundContainer>
      <ImageContainer>
        <Image
          style={{ width: 230.2, height: 56.81 }}
          source={require('@hedgit/lib/assets/images/hedgit-icon.png')}
        />
      </ImageContainer>
      <Content>
        <InputContainer>
          <Title>
            {t('ConfirmPhone.title')}
          </Title>
          <SubTitle>
            {t('ConfirmPhone.subTitleOne')}
            {currentUser?.phone}
            {t('ConfirmPhone.subTitleTwo')}
          </SubTitle>
          <AreaCodeAndPhoneContainer>
            <AreaCodeContainer>
              <FieldContainer>
                <FormControl isInvalid={'areaCode' in errors}>
                  <Controller
                    name="areaCode"
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <SharedInput
                        testID="su-confirm-area-code-input"
                        maxLength={
                      phone.length === 8
                        ? 2
                        : phone.length === 7
                          ? 3
                          : phone.length === 6 ? 4 : 4
                    }
                        withLabel={false}
                        value={formatNumber(value)}
                        onChange={onChange}
                        onFocus={() => clearErrors('areaCode')}
                        onBlur={onBlur}
                        keyboardType="numeric"
                        placeholder={t('SignUpFarmer.form.areaCode.placeholder')}
                        endAdornment={<PencilIcon width={24} height={24} color='#adb1b8' />}
                      />
                    )}
                  />
                  {'areaCode' in errors && (
                  <CustomErrorWrapper testID="su-confirm-phone-error-message">
                    <CustomErrorIconWrapper>
                      <ErrorIcon width={14} height={14} color={colors.red} />
                    </CustomErrorIconWrapper>
                    <CustomErrorText>
                      {errors.areaCode?.message}
                    </CustomErrorText>
                  </CustomErrorWrapper>
                  )}
                </FormControl>
              </FieldContainer>
            </AreaCodeContainer>
            <PhoneContainer>
              <FieldContainer>
                <FormControl isInvalid={'phone' in errors}>
                  <Controller
                    name="phone"
                    control={control}
                    defaultValue=""
                    rules={{ pattern: { value: phoneRegex, message: t('SignUpFarmer.form.phoneRegexError') } }}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <SharedInput
                        leftComponent={15}
                        testID="su-confirm-phone-input"
                        maxLength={
                      areaCode.length === 2
                        ? 8
                        : areaCode.length === 3
                          ? 7
                          : areaCode.length === 4 ? 6 : 8
                    }
                        withLabel={false}
                        value={formatNumber(value)}
                        onChange={onChange}
                        onFocus={handlePhoneInputFocus}
                        onBlur={onBlur}
                        keyboardType="numeric"
                        placeholder={t('SignUpFarmer.form.phone.placeholder')}
                        endAdornment={<PencilIcon width={24} height={24} color='#adb1b8' />}
                      />
                    )}
                  />
                  <FormControl.ErrorMessage
                    testID="su-confirm-phone-error-message"
                    leftIcon={<ErrorIcon width={14} height={14} color={colors.red} />}
                    _text={{ style: { color: colors.red, fontSize: 12, fontFamily: 'Lato' } }}
                  >
                    {errors.phone?.message}
                  </FormControl.ErrorMessage>
                </FormControl>
              </FieldContainer>
            </PhoneContainer>
          </AreaCodeAndPhoneContainer>
          <Requirements>
            {t('SignUpFarmer.requirementsAreaCode.title')}
            <Br />
            {t('SignUpFarmer.requirementsAreaCode.noStart')}
          </Requirements>
          <Requirements>
            {t('SignUpFarmer.requirementsPhone.title')}
            <Br />
            {t('SignUpFarmer.requirementsPhone.have')}
            {
            areaCode.length === 2
              ? 8
              : areaCode.length === 3
                ? 7
                : areaCode.length === 4 ? 6 : 8
          }
            {' '}
            {t('SignUpFarmer.requirementsPhone.digits')}
            <Br />
            {t('SignUpFarmer.requirementsPhone.noStart')}
          </Requirements>
          <Error
            testID="su-confirm-phone-errors-messages"
          >
            {error}
          </Error>
        </InputContainer>
        <ButtonsContainer>
          <SignInButtonContainer>
            <ButtonTitle>
              {t('ConfirmPhone.buttonTitle')}
            </ButtonTitle>
            <SharedButton
              testID="si-confirm-phone-button"
              variant="primary"
              onPress={handleSubmitForm(handleSubmit)}
              disabled={!isDirty || !isValid}
            >
              {t('SignIn.signInButton')}
            </SharedButton>
          </SignInButtonContainer>
        </ButtonsContainer>
      </Content>
    </Container>
  )
}

export default ConfirmPhone
