import React from 'react'
import { Linking } from 'react-native'
import { useTranslation } from 'react-i18next'

import { Container, FaqButton, IconContainer, IconLine, IconLineContainer, Label } from './styled'

import EmailIcon from '../../icons/email'
import PhoneIcon from '../../icons/phone'
import H3 from '../../typography/h3'
import MessageIcon from '../../icons/message'
import FaqIcon from '../../icons/faq'
import { UserRole } from '../../../enums/user-role'
import DownloadIcon from '../../icons/downloadIcon'

export enum ContactInformationType {
  email = 'email',
  message = 'message',
  phone = 'phone',
  faq = 'http',
}

export type ContactInformationProps = {
  variant: ContactInformationType;
  value: string;
  role?: UserRole;
}

interface IconProps {
  variant: ContactInformationProps['variant'];
}

const Icon = ({ variant }: IconProps) => {
  if (variant === ContactInformationType.phone) return <PhoneIcon />
  if (variant === ContactInformationType.email) return <EmailIcon />
  if (variant === ContactInformationType.message) return <MessageIcon />
  if (variant === ContactInformationType.faq) return <FaqIcon />
  return null
}

const ContactInformation: React.FC<ContactInformationProps> = ({
  variant,
  value,
  role
}: ContactInformationProps) => {
  const { t } = useTranslation()

  const mailLabel = role !== UserRole.farmer ? t('ContactInformation.email') : t('ContactInformation.emailFarmer')

  const variants = {
    [ContactInformationType.email]: {
      linkPrefix: 'mailto',
      label: mailLabel
    },
    [ContactInformationType.phone]: {
      linkPrefix: 'tel',
      label: t('ContactInformation.phone')
    },
    [ContactInformationType.message]: {
      linkPrefix: 'sms',
      label: t('ContactInformation.message')
    },
    [ContactInformationType.faq]: {
      linkPrefix: 'http',
      label: t('ContactInformation.faq')
    }
  }

  const isVariantFaq = variants[variant].linkPrefix === 'http'

  const handlePress = () => {
    const { linkPrefix } = variants[variant]
    Linking.openURL(`${linkPrefix}:${value}`)
    console.log(variant)
  }

  const handleFaqPress = () => Linking.openURL('/ManualdeAPP.pdf')

  return (
    <Container>
      <IconLineContainer>
        <IconContainer>
          <Icon variant={variant} />
        </IconContainer>
        <IconLine />
      </IconLineContainer>
      <Label>{variants[variant].label}</Label>
      <FaqButton
        testID={`ci-${isVariantFaq ? 'faq' : value}-button`}
        onPress={isVariantFaq ? handleFaqPress : handlePress}
      >
        <H3>{value}</H3>
        {isVariantFaq && <DownloadIcon />}
      </FaqButton>
    </Container>
  )
}

export default ContactInformation
