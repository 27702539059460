import React from 'react'

import { Container, Button } from './styled'

import ButtonText from '../../../../typography/button-text'

export enum ExecutionType {
  aggregated = 'aggregated',
  segmented = 'segmented'
}

interface ExecutionTypeSwitchProps {
  selected: ExecutionType;
  onChange: (value: ExecutionType) => void;
}

const ExecutionTypeSwitch = ({ onChange, selected }: ExecutionTypeSwitchProps) => {
  return (
    <Container>
      <Button
        isSelected={selected === ExecutionType.aggregated}
        onPress={() => onChange(ExecutionType.aggregated)}
      >
        <ButtonText style={{ color: 'white' }}>Agregada</ButtonText>
      </Button>
      <Button
        isSelected={selected === ExecutionType.segmented}
        onPress={() => onChange(ExecutionType.segmented)}
      >
        <ButtonText style={{ color: 'white' }}>Segmentada</ButtonText>
      </Button>
    </Container>
  )
}

export default ExecutionTypeSwitch
