import React from 'react'
import { GestureResponderEvent } from 'react-native'
import { t } from 'i18next'

import {
  IconBox,
  CardContent,
  TextBox,
  CardTitle,
  CardSubTitle,
  ArrowIconBox
} from './styled'

import SubCodeIcon from '../../icons/sub-code-icon'
import ArrowTwoIcon from '../../icons/arrow-two'

interface SubscribeCodeCardProps {
  onPress?: (event: GestureResponderEvent) => void;
}

export const SubscribeCodeCard = ({ onPress }: SubscribeCodeCardProps) => {
  return (
    <CardContent onPress={onPress} testID='subscribe-code-card-id'>
      <IconBox>
        <SubCodeIcon id='SubscribeCodeCard' />
      </IconBox>
      <TextBox>
        <CardTitle>
          {t('AlgorithmFarmerOptions.subscriptionCode.title')}
        </CardTitle>
        <CardSubTitle>
          {t('AlgorithmFarmerOptions.subscriptionCode.subTitle')}
        </CardSubTitle>
      </TextBox>
      <ArrowIconBox>
        <ArrowTwoIcon />
      </ArrowIconBox>
    </CardContent>
  )
}
