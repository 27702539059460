import React, { MouseEvent } from 'react'

import { BackDropContainer } from '../styled'

interface BackdropProps {
  onClose: (e: MouseEvent) => void;
  children: React.ReactNode;
}

export function Backdrop ({ children, onClose }: BackdropProps) {
  const handleOnClose = (e: MouseEvent) => {
    if (e.target === e.currentTarget) {
      onClose(e)
    }
  }

  return (
    <BackDropContainer onClick={handleOnClose} data-testid="disclosure-component">
      {children}
    </BackDropContainer>
  )
}
