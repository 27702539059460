import React, { useMemo } from 'react'
import { GestureResponderEvent } from 'react-native'
import { t } from 'i18next'
import { getDate } from '@hedgit/lib/translations/getTranslation'
import { RWebShare } from '@hedgit/web/src/components/share-social'
import useCustomToast from '@hedgit/web/src/hooks/use-custom-toast'

import { SubscriptionCodeStatus } from '@hedgit/lib/enums/subscription-code-status'

import {
  CardContainer,
  Code,
  Content,
  DateText,
  ExpirationDateCodeContainer,
  ShareButtonContainer,
  Status
} from './styled'
import { statusMap } from './constants'

import SharedButton from '../../icons/share-button'

interface CodesCardProps {
  value: string;
  generationDate: Date;
  expirationDate: Date;
  status: SubscriptionCodeStatus;
  onPress?: (event: GestureResponderEvent) => void;
}

export const CodeCard = ({ value, generationDate, expirationDate, status, onPress }: CodesCardProps) => {
  const color = statusMap[status]?.color
  const { showToast } = useCustomToast()

  const formattedGenerationDate = useMemo(() => {
    const date = new Date(generationDate)
    date.setTime(date.getTime() + date.getTimezoneOffset() * 60 * 1000)
    return date
  }, [generationDate])

  const formattedExpirationDate = useMemo(() => {
    const date = new Date(expirationDate)
    date.setTime(date.getTime() + date.getTimezoneOffset() * 60 * 1000)
    return date
  }, [expirationDate])

  return (
    <Content testID="code-card-container">
      <CardContainer>
        <Code color={color}>{value}</Code>
        <DateText>
          {t('Components.card.dateGenerated')}
          {getDate(formattedGenerationDate)}
        </DateText>
        <Status color={color}>
          {status === SubscriptionCodeStatus.available
            ? t('Components.card.available')
            : status === SubscriptionCodeStatus.pendingConfirmation
              ? t('Components.card.pending')
              : t('Components.card.used')}
        </Status>
        <ExpirationDateCodeContainer>
          <DateText visibility={status === SubscriptionCodeStatus.available ? 'visible' : 'hidden'}>
            {t('Components.card.validDate')}
            {getDate(formattedExpirationDate)}
          </DateText>
          {status === SubscriptionCodeStatus.available && (
          <ShareButtonContainer onPress={onPress} testID="share-button">
            <RWebShare
              data={{
                text: t('Components.card.share.text'),
                url: value,
                title: t('Components.card.share.title')
              }}
              onClick={site => {
                if (site === 'copy') showToast('Código copiado al portapapeles', 'success')
              }}
              sites={['whatsapp', 'mail', 'copy']}
            >
              <SharedButton />
            </RWebShare>
          </ShareButtonContainer>
          )}
        </ExpirationDateCodeContainer>
      </CardContainer>
    </Content>
  )
}
