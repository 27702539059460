import React, { useEffect } from 'react'
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import selectActivePricingProgramsBroker from '@hedgit/web/src/store/selectors/selectActivePricingProgramsBroker'
import selectExecutedPricingProgramsBroker from '@hedgit/web/src/store/selectors/selectExecutedPricingProgramsBroker'
import selectCancelledPricingProgramsBroker from '@hedgit/web/src/store/selectors/selectCancelledPricingProgramsBroker'
import selectActivePricingProgramsFarmer from '@hedgit/web/src/store/selectors/selectActivePricingProgramsFarmer'
import selectExecutedPricingProgramsFarmer from '@hedgit/web/src/store/selectors/selectExecutedPricingProgramsFarmer'
import selectCancelledPricingProgramsFarmer from '@hedgit/web/src/store/selectors/selectCancelledPricingProgramsFarmer'
import { theme } from '@hedgit/lib/theme'

import { getPricingPrograms } from '@hedgit/lib/store/modules/pricing-programs/thunks'
import { getNotifications } from '@hedgit/lib/store/modules/notifications/thunks'
import { getSubscriptions } from '@hedgit/lib/store/modules/subscriptions/thunks'
import { getPricingSignals } from '@hedgit/lib/store/modules/pricing-signals/thunks'

import useResetTab from '@hedgit/lib/hooks/use-reset-tab'

import { PricingProgramStatus } from '@hedgit/lib/enums/pricing-program-status'

import { RootState, useDispatch } from 'store'

import useUserRole from 'hooks/use-user-role'

import { PricingProgramsActiveTab } from './active'
import { PricingProgramsExecutedTab } from './executed'
import { PricingProgramsCancelledTab } from './cancelled'

export type RouteType = {
  name: PricingProgramStatus;
}

const Tab = createMaterialTopTabNavigator()

const { colors } = theme

const PricingProgramsTabs = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const role = useSelector((store: RootState) => store.auth.currentUser?.role)
  const { isFarmer, isBrokerFarmer, isBroker } = useUserRole()

  useResetTab('PricingProgramsActiveTab')

  useEffect(() => {
    if (role) {
      dispatch(getNotifications())
      dispatch(getPricingPrograms())
      dispatch(getSubscriptions(role))
      dispatch(getPricingSignals(role))
    }
  }, [dispatch, role])

  const brokerActiveBadge = useSelector(selectActivePricingProgramsBroker()).length
  const brokerExecutedBadge = useSelector(selectExecutedPricingProgramsBroker()).length
  const brokerCancelledBadge = useSelector(selectCancelledPricingProgramsBroker()).length

  const farmerActiveBadge = useSelector(selectActivePricingProgramsFarmer()).length
  const farmerExecutedBadge = useSelector(selectExecutedPricingProgramsFarmer()).length
  const farmerCancelledBadge = useSelector(selectCancelledPricingProgramsFarmer()).length

  const activeBadge = () => {
    if (isBroker) {
      return brokerActiveBadge
    } else if (isFarmer) {
      return farmerActiveBadge
    } else if (isBrokerFarmer) {
      return brokerActiveBadge + farmerActiveBadge
    }
    return 0
  }

  const executedBadge = () => {
    if (isBroker) {
      return brokerExecutedBadge
    } else if (isFarmer) {
      return farmerExecutedBadge
    } else if (isBrokerFarmer) {
      return brokerExecutedBadge + farmerExecutedBadge
    }
    return 0
  }

  const cancelledBadge = () => {
    if (isBroker) {
      return brokerCancelledBadge
    } else if (isFarmer) {
      return farmerCancelledBadge
    } else if (isBrokerFarmer) {
      return brokerCancelledBadge + farmerCancelledBadge
    }
    return 0
  }

  return (
    <Tab.Navigator
      screenOptions={{
        tabBarActiveTintColor: colors.primary,
        tabBarInactiveTintColor: colors.dark,
        tabBarIndicatorStyle: {
          backgroundColor: colors.primary
        },
        tabBarItemStyle: { padding: 0 }
      }}
    >
      <Tab.Screen
        name="PricingProgramsActiveTab"
        component={PricingProgramsActiveTab}
        options={{
          tabBarTestID: 'pp-active-tab',
          title: `${activeBadge()} ${t('PricingPrograms.tabs.active')}`,
          tabBarLabelStyle: { margin: '2px' }
        }}
      />
      <Tab.Screen
        name="PricingProgramsExecutedTab"
        component={PricingProgramsExecutedTab}
        options={{
          tabBarTestID: 'pp-executed-tab',
          title: `${executedBadge()} ${t('PricingPrograms.tabs.executed')}`,
          tabBarLabelStyle: { margin: '2px' }
        }}
      />
      <Tab.Screen
        name="PricingProgramsCancelledTab"
        component={PricingProgramsCancelledTab}
        options={{
          tabBarTestID: 'pp-cancelled-tab',
          title: `${cancelledBadge()} ${t('PricingPrograms.tabs.cancelled')}`,
          tabBarLabelStyle: { margin: '2px' }
        }}
      />
    </Tab.Navigator>
  )
}

export default PricingProgramsTabs
