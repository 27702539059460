import React from 'react'
import { GestureResponderEvent } from 'react-native'
import { t } from 'i18next'

import {
  IconBox,
  CardContent,
  TextBox,
  ArrowIconBox,
  CardText,
  SecondaryText
} from './styled'

import XpPlanIcon from '../../icons/xp-plan-icon'
import ArrowTwoIcon from '../../icons/arrow-two'

interface SubscribePremiumPartnerProps {
  onPress?: (event: GestureResponderEvent) => void;
}

export const SubscribePremiumPartner = ({ onPress }: SubscribePremiumPartnerProps) => {
  return (
    <CardContent onPress={onPress} testID='subscribe-premium-partner-id'>
      <IconBox>
        <XpPlanIcon isSubscriptionScreen />
      </IconBox>
      <TextBox>
        <CardText>
          {t('AlgorithmFarmerOptions.subscriptionPremiumPartner.text')}
          <SecondaryText>{t('Free')}</SecondaryText>
        </CardText>
      </TextBox>
      <ArrowIconBox>
        <ArrowTwoIcon />
      </ArrowIconBox>
    </CardContent>
  )
}
