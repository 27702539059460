import React from 'react'
import { useSelector } from '@hedgit/web/src/store'
import { useTranslation } from 'react-i18next'

import { Notification } from '@hedgit/lib/interfaces/notifications'

import {
  Container,
  SubContainer,
  IconContainer,
  GreenCircle,
  RedCircle,
  YellowCircle,
  Title,
  Time,
  Message,
  Content
} from './styled'

import NotificationPricingProgramIcon from '../../icons/notification-pricing-program'
import NotificationPricingSignalIcon from '../../icons/notification-pricing-signal'
import { NotificationType } from '../../../enums/notification-type'
import { GetMonthName } from '../../../utils/format'
import { getTranslation } from '../../../translations/getTranslation'
import { UserRole } from '../../../enums/user-role'
interface NotificationCardProps {
  notification: Notification;
}

export const NotificationCard = ({
  notification
}: NotificationCardProps) => {
  const user = useSelector(
    state => state.auth.currentUser
  )

  const { t } = useTranslation()

  const pricingSignalSub = notification.content.pricingSignal?.subscription
  const subscription = notification.content.subscription

  return (
    <Container>
      <SubContainer>
        <IconContainer>
          {
            (notification.notificationType === NotificationType.signalCreated ||
              notification.notificationType === NotificationType.signalExecuted
              ? (
                <NotificationPricingSignalIcon color='#ADB1B8' />
                )
              : (
                <NotificationPricingProgramIcon color='#ADB1B8' />
                ))
          }
        </IconContainer>
        {!notification.isRead
          ? (notification.notificationType === NotificationType.signalExecuted ||
            notification.notificationType === NotificationType.subscriptionAccepted ||
            notification.notificationType === NotificationType.cancelSubscriptionAccepted)
              ? <GreenCircle />
              : (notification.notificationType === NotificationType.signalCreated ||
                notification.notificationType === NotificationType.subscriptionPending ||
                notification.notificationType === NotificationType.cancelSubscriptionPending)
                  ? <YellowCircle />
                  : <RedCircle />
          : <> </>}
      </SubContainer>
      <Content>
        <Title>
          {getTranslation(notification.content.product.crop)}
          {' '}
          {GetMonthName(notification.content.product.month)}
          {' '}
          {notification.content.product.year}
          {user?.role === UserRole.broker_farmer &&
          (pricingSignalSub?.farmer === user?.id || subscription?.farmer === user?.id
            ? ` - (${t('UserRole.farmer')})`
            : ` - (${t('UserRole.broker')})`)}
        </Title>
        <Message>
          {notification.message}
          {user?.role === UserRole.farmer && ` ${notification.content.brokerLastName}`}
        </Message>
      </Content>
      <SubContainer
        style={{
          alignSelf: 'flex-start',
          paddingTop: '8px',
          paddingRight: '10px'
        }}
      >
        <Time>{notification.time}</Time>
      </SubContainer>
    </Container>
  )
}
