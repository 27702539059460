import styled from 'styled-components/native'

import { SharedButton } from '../../shared/button'

export const CenteredView = styled.View`
  flex: 1;
  justify-content: center;
  align-items: center;
  background-color: #00000052;
`
export const ModalView = styled.View`
  display: flex;
  background-color: white;
  border-radius: 10px;
  width: 85%;
  padding: 0 16px 16px 16px;
  max-width: 500px;
  justify-content: center;
  align-items: center;
`
export const ButtonsContainer = styled.View`
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  gap: 16px;
`
export const ButtonContainer = styled.View`
  flex-direction: row;
  flex: 1;
  margin-top: 24px;
  justify-content: space-evenly;
`
export const Button = styled(SharedButton)`
  width: 40%;
`
export const BodyContainer = styled.View`
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  text-align: center;
`
export const Body = styled.Text`
  font-size: 'sm';
  font-weight: 400;
  font-family: 'Lato';
  color: 'dark';
`
export const TitleContent = styled.View`
  padding: 15px;
`
