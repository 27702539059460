import styled from 'styled-components/native'

import H3 from '@hedgit/lib/components/typography/h3'
import Body from '@hedgit/lib/components/typography/body'

import { SidebarBack } from '../sidebar/components/sidebar-back'

export const Content = styled.View<{smallHeader?: boolean}>`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: ${({ smallHeader }) => smallHeader ? '20px 14px' : '41px 28px'};
  background-color: transparent;
  height: ${({ smallHeader }) => smallHeader ? '60px' : '129px'};
  overflow: hidden;
`
export const GradientContainer = styled.View`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  z-index: -2;
  background-image: linear-gradient(90deg, #33A3D9 0%, #333C4C 100%);
`
export const HeaderBack = styled(SidebarBack)`
  height: 166%;
  width: 100%;
  position: absolute;
  top: 30%;
  left: 10%;
  z-index: -1;
`

export const BackButtonContainer = styled.View`
`

export const PlanInfo = styled.View`
  justify-content: center;
  align-items: center;
`

export const Title = styled(H3)<{smallHeader?: boolean}>`
  color: #ffffff;
  font-size: ${({ smallHeader }) => smallHeader ? '16px' : '25px'};
  font-weight: 900;
  line-break: normal;
  align-self: center;
`

export const SubTitle = styled(Body)<{smallHeader?: boolean}>`
  text-transform: uppercase;
  color: #ffffff;
  font-size: ${({ smallHeader }) => smallHeader ? '10px' : '14px'};
  font-weight: 400;
  align-self: center;
`
