import React from 'react'
import { Image } from 'react-native'

const SubsPlan = () => {
  return (
    <Image
      style={{ width: '42px', height: '42px' }}
      source={require('@hedgit/lib/components/assets/subs-plan.png')}
    />
  )
}

export default SubsPlan
