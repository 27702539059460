import { ActionType } from 'typesafe-actions'

import { FarmerSubscription, BrokerSubscription, Subscription } from '@hedgit/lib/interfaces/subscription'

import * as actions from './actions'

export enum SubscriptionsActionConsts {
  GET_SUBSCRIPTIONS_PENDING='GET_SUBSCRIPTIONS_PENDING',
  GET_SUBSCRIPTIONS_FULFILLED='GET_SUBSCRIPTIONS_FULFILLED',
  GET_BROKER_SUBSCRIPTIONS_FULFILLED='GET_BROKER_SUBSCRIPTIONS_FULFILLED',
  GET_SUBSCRIPTIONS_FAILED='GET_SUBSCRIPTIONS_FAILED',
  CREATE_SUBSCRIPTION_PENDING='CREATE_SUBSCRIPTION_PENDING',
  CREATE_SUBSCRIPTION_FULFILLED='CREATE_SUBSCRIPTION_FULFILLED',
  CREATE_SUBSCRIPTION_FAILED='CREATE_SUBSCRIPTION_FAILED',
  ACCEPTED_SUBSCRIPTION_PENDING='ACCEPTED_SUBSCRIPTION_PENDING',
  ACCEPTED_SUBSCRIPTION_FULFILLED='ACCEPTED_SUBSCRIPTION_FULFILLED',
  ACCEPTED_SUBSCRIPTION_FAILED='ACCEPTED_SUBSCRIPTION_FAILED',
  REJECT_SUBSCRIPTION_PENDING='REJECT_SUBSCRIPTION_PENDING',
  REJECT_SUBSCRIPTION_FULFILLED='REJECT_SUBSCRIPTION_FULFILLED',
  REJECT_SUBSCRIPTION_FAILED='REJECT_SUBSCRIPTION_FAILED',
  REJECT_CANCELLATION_PENDING='REJECT_CANCELLATION_PENDING',
  REJECT_CANCELLATION_FULFILLED='REJECT_CANCELLATION_FULFILLED',
  REJECT_CANCELLATION_FAILED='REJECT_CANCELLATION_FAILED',
  CANCEL_SUBSCRIPTION_PENDING='CANCEL_SUBSCRIPTION_PENDING',
  CANCEL_SUBSCRIPTION_FULFILLED='CANCEL_SUBSCRIPTION_FULFILLED',
  CANCEL_SUBSCRIPTION_PENDING_FULFILLED='CANCEL_SUBSCRIPTION_PENDING_FULFILLED',
  CANCEL_SUBSCRIPTION_FAILED='CANCEL_SUBSCRIPTION_FAILED',
  GET_BROKER_FARMER_SUBSCRIPTIONS_FULFILLED='GET_BROKER_FARMER_SUBSCRIPTIONS_FULFILLED',
  CONFIRM_CANCEL_SUBSCRIPTION_FULFILLED='CONFIRM_CANCEL_SUBSCRIPTION_FULFILLED',
  CONFIRM_CANCEL_SUBSCRIPTION_FAILED='CONFIRM_CANCEL_SUBSCRIPTION_FAILED',
  CONFIRM_CANCEL_SUBSCRIPTION_PENDING='CONFIRM_CANCEL_SUBSCRIPTION_PENDING',
  SIGN_OUT='SIGN_OUT',
}

export type SubscriptionsActionTypes = ActionType<typeof actions>

export interface SubscriptionsState {
  readonly list: Subscription[];
  readonly farmerList: FarmerSubscription[];
  readonly brokerList: BrokerSubscription[];
  readonly isFetching: boolean;
  readonly error: string;
  readonly isSubscriptionFetching: boolean;
}
