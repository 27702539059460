import React, { useEffect, useState } from 'react'
import { View } from 'react-native'
import { t } from 'i18next'
import useCustomToast from '@hedgit/web/src/hooks/use-custom-toast'

import {
  CardContainer,
  CardTitle,
  GenerateButton,
  GenerateButtonText,
  DetailsContent,
  ContentText,
  GenerateButtonContent,
  Circle,
  CircleText
} from './styled'

import { useThunkDispatch } from '../../../../web/src/store'
import { createSubscriptionCode } from '../../../store/modules/subscription-codes/thunks'

interface GenerateCodeCardProps {
  remainingCodes: number | 'infinity';
  disabledButton: boolean;
}

export const GenerateCodeCard = ({ remainingCodes, disabledButton }: GenerateCodeCardProps) => {
  const [codes, setCodes] = useState(remainingCodes)
  const [disabled, setDisabled] = useState(false)

  const dispatch = useThunkDispatch()
  const { showToast } = useCustomToast()

  useEffect(() => {
    setCodes(remainingCodes)
  }, [remainingCodes])

  useEffect(() => {
    if (codes !== 'infinity' && codes < 1) setDisabled(true)
  }, [codes])

  const createCode = async () => {
    if (codes === 'infinity' || codes > 0) {
      const { success, errorMsg } = await dispatch(createSubscriptionCode())
      if (!success) return showToast(errorMsg.toString(), 'danger')
      codes !== 'infinity' && setCodes(codes - 1)
    }
  }

  return (
    <CardContainer testID='generate-code-card-id'>
      <View>
        <CardTitle>{t('Components.card.generateCodeTitle')}</CardTitle>
      </View>
      <DetailsContent>
        <Circle>
          <CircleText>{codes === 'infinity' ? '∞' : codes}</CircleText>
        </Circle>
        <ContentText>{t('Components.card.generateCodeRemaining')}</ContentText>
      </DetailsContent>
      <GenerateButtonContent>
        <GenerateButton onPress={createCode} testID='generate-code-card-button' disabled={disabled || disabledButton}>
          <GenerateButtonText>{t('Components.card.generateCodeButton')}</GenerateButtonText>
        </GenerateButton>
      </GenerateButtonContent>
    </CardContainer>
  )
}
