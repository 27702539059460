import { Reducer } from 'redux'

import { SubscriptionOptionsActionTypes, SubscriptionOptionsState, SubscriptionOptionsActionConsts } from './types'

export const initialState: SubscriptionOptionsState = {
  hasPremiumXp: false,
  premiumXpName: undefined,
  isFetching: false,
  error: ''
}

export const subscriptionOptionsReducer: Reducer<SubscriptionOptionsState,
SubscriptionOptionsActionTypes> = (
  state = initialState,
  action
): SubscriptionOptionsState => {
  switch (action.type) {
    case SubscriptionOptionsActionConsts.GET_SUBSCRIPTION_OPTIONS_PENDING:
      return {
        ...state,
        isFetching: true
      }
    case SubscriptionOptionsActionConsts.GET_SUBSCRIPTION_OPTIONS_FULFILLED:
      return {
        ...state,
        isFetching: false,
        hasPremiumXp: action.payload.hasPremiumXp,
        premiumXpName: action.payload.premiumXpName
      }
    case SubscriptionOptionsActionConsts.GET_SUBSCRIPTION_OPTIONS_FAILED:
      return {
        ...state,
        isFetching: false,
        error: action.payload
      }
    default:
      return state
  }
}
