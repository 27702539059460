import React, { useState } from 'react'
import { UserCredential } from 'firebase/auth'
import { useDispatch, useSelector } from 'react-redux'

import { setAuthToken, saveFirebaseUID, setIsSignIn } from '@hedgit/lib/store/modules/auth'
import { createFarmer } from '@hedgit/lib/store/modules/auth/thunks'

import useSignUp from '@hedgit/lib/hooks/firebase/useSignUp'

import { AuthUser } from '@hedgit/lib/interfaces/auth-user'

import { AccessVerificationCode } from '@hedgit/lib/components/auth/access-verification-code'

import { RootState } from 'store'

import auth from 'utils/firebase'

import { Container } from './styled'

const SignUpVerification = () => {
  const dispatch = useDispatch()

  const [errorCodeInvalid, setErrorCodeInvalid] = useState<string>('')
  const [isFetching, setIsFetching] = useState<boolean>(false)

  const farmer = useSelector((store: RootState) => store.auth.user)
  const phone = useSelector((store: RootState) => store.auth.phone)
  const user = useSelector((store: RootState) => store.auth.user)

  const onCodeVerified = async (data: UserCredential) => {
    const firebaseUID = data.user.uid
    dispatch(saveFirebaseUID(firebaseUID))
    const token = await data.user.getIdToken()
    const refreshToken = data.user.refreshToken
    try {
      await dispatch(createFarmer({ ...farmer, firebaseUID, refreshToken } as AuthUser))
      dispatch(setAuthToken(token))
      dispatch(setIsSignIn(true))
    } catch (e) {
      console.error(e)
    }
  }

  const onCodeInvalid = (error: string) => {
    setIsFetching(false)
    setErrorCodeInvalid(error)
  }

  const {
    signUp,
    signUpVerification
  } = useSignUp({
    auth,
    onCodeVerified,
    onCodeInvalid
  })

  return (
    <Container>
      <AccessVerificationCode
        onSubmit={(code) => {
          setIsFetching(true)
          signUpVerification(code)
        }}
        onResendCode={() => {
          signUp(user as AuthUser)
        }}
        phone={phone}
        errorCodeInvalid={errorCodeInvalid}
        isFetching={isFetching}
      />
    </Container>
  )
}

export default SignUpVerification
