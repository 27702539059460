import { useState, useMemo } from 'react'
import { isBefore, isAfter, isEqual, subYears, isSameDay, subMonths, subBusinessDays } from 'date-fns'

import { HistoricalDataFilter } from '@hedgit/lib/enums/filters'

import { useSelector } from 'store'

export const useFilteredMarketData = (id: string) => {
  const farmerList = useSelector((state) => state.subscriptions.farmerList)
  const brokerList = useSelector((state) => state.subscriptions.brokerList)
  const [filter, setFilter] = useState<HistoricalDataFilter>(HistoricalDataFilter['1M'])

  const selectedSubscription = farmerList.find(
    subscription => subscription.id === id
  ) || brokerList.find(
    subscription => subscription.pricingProgramId === id
  )

  const marketData = useSelector(
    state =>
      state.marketData.listByPricingProgram[
        selectedSubscription?.pricingProgram.id || ''
      ] || []
  )

  const today = new Date()
  const ppStartDate = useMemo(() => {
    const date = new Date(selectedSubscription?.pricingProgram.startDate || '')
    date.setTime(date.getTime() - date.getTimezoneOffset() * 60 * 1000)
    return date
  }, [selectedSubscription])

  const ppEndDate = new Date(selectedSubscription?.pricingProgram.endDate || '')
  ppEndDate.setTime(ppEndDate.getTime() - ppEndDate.getTimezoneOffset() * 60 * 1000)

  const endDate = isBefore(today, ppEndDate) ? today : ppEndDate

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const startDate = () => {
    if (!selectedSubscription) return new Date()

    let date: Date

    if (filter === HistoricalDataFilter['1M']) {
      date = subMonths(endDate, 1)
    } else if (filter === HistoricalDataFilter['3M']) {
      date = subMonths(endDate, 3)
    } else if (filter === HistoricalDataFilter['6M']) {
      date = subMonths(endDate, 6)
    } else if (filter === HistoricalDataFilter['12M']) {
      date = subYears(endDate, 1)
    } else {
      date = ppStartDate
    }
    return date
  }

  const filteredMarketData = useMemo(() => {
    if (!selectedSubscription) return []

    return marketData.filter(mD => {
      const valueDate = new Date(mD.date)
      const start = startDate()

      const isEqualOrAfter = isEqual(valueDate, start) || isAfter(valueDate, start)
      const isEqualOrBefore = isEqual(valueDate, endDate) || isBefore(valueDate, endDate)

      return isEqualOrAfter && isEqualOrBefore
    })
  }, [marketData, selectedSubscription, startDate, endDate])

  const ppStartMarketData = useMemo(() => {
    if (selectedSubscription?.pricingProgram.startDate) {
      const index = filteredMarketData.findIndex(
        (mD) => {
          return isSameDay(new Date(mD.date), ppStartDate)
        }
      )

      if (index !== -1) {
        return {
          ...filteredMarketData[index],
          index
        }
      } else {
        let diff = 1
        let index = -1
        while (diff < 10) {
          const ppStart = subBusinessDays(ppStartDate, diff)

          const i = filteredMarketData.findIndex(
            (mD) => {
              return isSameDay(new Date(mD.date), ppStart)
            }
          )

          if (i !== -1) {
            index = i
            break
          }
          diff++
        }
        if (index === -1) {
          return {
            ...filteredMarketData[0],
            index: 0
          }
        }
        return {
          ...filteredMarketData[index],
          index
        }
      }
    }
  }
  , [filteredMarketData, selectedSubscription, ppStartDate])

  const ppMarketDataValues = selectedSubscription
    ? marketData.filter(mD => {
      const valueDate = new Date(mD.date)

      const isEqualOrAfter = isEqual(valueDate, ppStartDate) || isAfter(valueDate, ppStartDate)
      const isEqualOrBefore = isEqual(valueDate, ppEndDate) || isBefore(valueDate, ppEndDate)

      return isEqualOrAfter && isEqualOrBefore
    }).map(mD => mD.value)
    : []

  const ppMinPrice = Math.min(...ppMarketDataValues)
  const ppMaxPrice = Math.max(...ppMarketDataValues)
  const ppAvg = ppMarketDataValues.reduce((accum, value) => accum + value, 0) / ppMarketDataValues.length

  return {
    setFilter,
    filter,
    filteredMarketData,
    startDate,
    endDate,
    ppStartMarketData,
    ppMinPrice,
    ppMaxPrice,
    ppAvg
  }
}
