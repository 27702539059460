import { action } from 'typesafe-actions'

import { SubscriptionCode } from '@hedgit/lib/interfaces/subscription-code'

import { SubscriptionCodesActionConsts } from './types'

export const getSubscriptionCodePending = () =>
  action(SubscriptionCodesActionConsts.GET_SUBSCRIPTION_CODE_PENDING)

export const getSubscriptionCodeFulfilled = (subscriptionCodes: SubscriptionCode[]) =>
  action(SubscriptionCodesActionConsts.GET_SUBSCRIPTION_CODE_FULFILLED, subscriptionCodes)

export const getSubscriptionCodeFailed = (error: string) =>
  action(SubscriptionCodesActionConsts.GET_SUBSCRIPTION_CODE_FAILED, error)

export const createSubscriptionCodePending = () =>
  action(SubscriptionCodesActionConsts.CREATE_SUBSCRIPTION_CODE_PENDING)

export const createSubscriptionCodeFulfilled = (subscriptionCode: SubscriptionCode) =>
  action(SubscriptionCodesActionConsts.CREATE_SUBSCRIPTION_CODE_FULFILLED, subscriptionCode)

export const createSubscriptionCodeFailed = (error: string) =>
  action(SubscriptionCodesActionConsts.CREATE_SUBSCRIPTION_CODE_FAILED, error)

export const verifySubscriptionCodePending = () =>
  action(SubscriptionCodesActionConsts.VERIFY_SUBSCRIPTION_CODE_PENDING)

export const verifySubscriptionCodeFulfilled = (isValid: boolean) =>
  action(SubscriptionCodesActionConsts.VERIFY_SUBSCRIPTION_CODE_FULFILLED, isValid)

export const verifySubscriptionCodeFailed = (error: string) =>
  action(SubscriptionCodesActionConsts.VERIFY_SUBSCRIPTION_CODE_FAILED, error)
