import React from 'react'
import { Circle, Svg, Line } from 'react-native-svg'
import type { CircleProps } from 'react-native-svg'

import { MarketDataSummary } from '@hedgit/lib/store/modules/market-data'

import { theme } from '../../../../theme'

interface DecoratorProps {
  x: (arg: number) => number;
  y: (arg: number) => number;
  ppStartMarketData: MarketDataSummary & { index: number };
  ppStartDate: Date;
}

interface CircleSvgProps extends CircleProps {
  style: Record<string, string>;
}

const CircleSvg = (props: CircleSvgProps) => {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Circle {...props} />
}

export const StartLine = (props: Partial<DecoratorProps>) => {
  const { x, y, ppStartMarketData, ppStartDate } = props as DecoratorProps

  const shadow = { filter: 'drop-shadow( 0px 3px 6px rgba(0, 0, 0, .16))' }

  const { colors } = theme

  if (!ppStartMarketData || !ppStartDate) return null

  return (
    <Svg>
      <CircleSvg
        cx={x(ppStartMarketData.index)}
        cy={y(ppStartMarketData.value)}
        r={4.5}
        fill={colors.lightBlue}
        style={shadow}
      />
      <Line
        x1={x(ppStartMarketData.index)}
        y1={y(ppStartMarketData.value)}
        x2={x(ppStartMarketData.index)}
        y2={270}
        stroke={colors.lightBlue}
        strokeDasharray="1.5"
        strokeWidth="2"
      />
    </Svg>
  )
}
