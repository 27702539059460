import { ActionType } from 'typesafe-actions'

import { User } from '@hedgit/lib/interfaces/user'

import * as actions from './actions'

export type UsersActionTypes = ActionType<typeof actions>

export enum UsersActionConst {
  GET_USERS_PENDING='GET_USERS_PENDING',
  GET_USERS_FULFILLED='GET_USERS_FULFILLED',
  GET_USERS_FAILED='GET_USERS_FAILED',
}

export interface UsersState {
  readonly list: User[];
  readonly isFetching: boolean;
  readonly error: string;
}
