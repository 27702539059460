import React from 'react'
import { t } from 'i18next'

import { CardContent, CardTitle, CardText, TextBox } from './styled'

export const CardSubscriptionPlanValid = () => {
  return (
    <CardContent testID="subscribe-code-card-valid-id">
      <TextBox>
        <CardTitle>{t('Components.card.subscriptionCodeValid.title')}</CardTitle>
        <CardText>{t('Components.card.subscriptionCodeValid.text')}</CardText>
      </TextBox>
    </CardContent>
  )
}
