import styled from 'styled-components/native'

interface ItemContainerProps {
  error: boolean | undefined;
}

export const ItemContainer = styled.View<ItemContainerProps>`
  border: 1px solid ${({ theme, error }) => error ? theme.colors.red : theme.colors.lightGray};
  border-radius: 12px;
  padding: 8px 8px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 12px;
`

export const RightContainer = styled.View`
  flex-direction: row;
  align-items: center;
  flex: 1;
  justify-content: flex-end;
`

interface InputProps {
  active?: boolean;
  disabled?: boolean;
}

export const Input = styled.TextInput<InputProps>`
  border: 1px solid ${({ theme, active }) => active ? theme.colors.primary : theme.colors.whiteLight};
  width: 80px;
  font-size: 22px;
  color: ${({ theme, active }) => active ? theme.colors.primary : theme.colors.whiteLight};
  padding: 2px 6px;
  border-radius: 8px;
  margin-left: 6px;
  font-weight: 600;
`
