import styled from 'styled-components/native'

import Title from '@hedgit/lib/components/typography/title'

export const Content = styled.View`
  justify-content: center;
  height: calc(100vh - 64px);
  background-color:  ${props => props.theme.colors.white};
`

export const SubContent = styled.View`
  background-color:  ${props => props.theme.colors.white};
  align-self: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  max-width: 600px;
`

export const CardText = styled.Text`
  color: #5A6371;
  padding-top: 16px;
  padding-right: 16px;
  padding-left: 17px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

export const SubTitle = styled(Title)`
  font-weight: 900;
`

export const CardBox = styled.View`
  align-self: center;
`

export const Footer = styled.View`
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 113px;
  `
export const Image = styled.Image`
  width: 82px;
  height: 82px;
`
