import React from 'react'
import { theme } from '@hedgit/lib/theme'
import type { RouteProp } from '@react-navigation/native'

import { PricingProgramStatus } from '@hedgit/lib/enums/pricing-program-status'

import { RootStackNavigationType, RootStackParamList } from '@hedgit/lib/interfaces/root-stack-params-list'

import GoBackIcon from '@hedgit/lib/components/icons/go-back'

import { Pressable } from 'routes/support/styled'

interface Props {
  route: RouteProp<RootStackParamList, 'PricingProgramDetails'>;
  navigation: RootStackNavigationType;
}

const PricingProgramDetailsGoBackButton = ({ route, navigation }: Props) => {
  return (
    <Pressable
      testID='nav-bar-go-back'
      onPress={() => {
        let screen = 'PricingProgramsActiveTab'
        if (route.params.state === PricingProgramStatus.cancelled) {
          screen = 'PricingProgramsCancelledTab'
        }
        if (route.params.state === PricingProgramStatus.executed) {
          screen = 'PricingProgramsExecutedTab'
        }
        navigation.navigate('NavigationTabs', {
          screen: 'PricingPrograms',
          params: {
            screen
          }
        })
      }}
      style={{ padding: 16 }}
    >
      <GoBackIcon color={theme.colors.dark} />
    </Pressable>
  )
}

export default PricingProgramDetailsGoBackButton
