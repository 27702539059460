import { ThunkAction } from 'redux-thunk'

import { get } from '@hedgit/lib/utils/axios'

import {
  getSubscriptionPlanPending,
  getSubscriptionPlanFulfilled,
  getSubscriptionPlanFailed
} from './actions'
import { SubscriptionPlansActionTypes } from './types'

import { APIResponse } from '../../../types/api'
import { SubscriptionPlansDetails } from '../../../interfaces/subscription-plan'

interface Response {
  activeSubscriptionPlan: SubscriptionPlansDetails;
}

export const getSubscriptionPlan = (): ThunkAction<void, unknown, unknown, SubscriptionPlansActionTypes> => {
  return async (dispatch) => {
    dispatch(getSubscriptionPlanPending())
    try {
      const response = await get<APIResponse<Response>>('/brokers/active-subscription-plan')
      dispatch(getSubscriptionPlanFulfilled(response.data.activeSubscriptionPlan))
    } catch (error) {
      dispatch(getSubscriptionPlanFailed(error as string))
    }
  }
}
