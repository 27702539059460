import styled from 'styled-components/native'

import Title from '@hedgit/lib/components/typography/title'

export const CardContent = styled.TouchableOpacity`
  flex-direction: row;
  width: 344px;
  height: 130px;
  align-items: center;
  box-shadow: 0 3px 6px #0000001A;
  background-color: ${props => (props.theme.colors.white)};
  margin-top: 10px;
  margin-bottom: 15px;
  margin-left: 15px;
  margin-right: 15px;
  border-radius: 6px;
`

export const IconBox = styled.View`
  width: 65px;
  height: 65px;
  align-items: center;
  justify-content: center;
  flex: 1;
`

export const ArrowIconBox = styled.View`
  align-items: center;
  justify-content: center;
`

export const TextBox = styled.View`
  align-items: flex-start;
  flex: 2;
`

export const CardText = styled(Title)`
  font-weight: bold;
  font-size: 16px;
  color: ${props => (props.theme.colors.gray)};
`
export const SecondaryText = styled(Title)`
  text-transform: uppercase;
  font-weight: bold;
  font-size: 16px;
  color: ${props => (props.theme.colors.primary)};
`
