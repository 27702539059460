import { ThunkAction } from '@hedgit/lib/types/redux-thunk'

import {
  getSubscriptionCodePending,
  getSubscriptionCodeFulfilled,
  getSubscriptionCodeFailed,
  createSubscriptionCodePending,
  createSubscriptionCodeFulfilled,
  createSubscriptionCodeFailed,
  verifySubscriptionCodePending,
  verifySubscriptionCodeFulfilled,
  verifySubscriptionCodeFailed
} from './actions'
import { SubscriptionCodesActionTypes } from './types'

import { get, post } from '../../../utils/axios'
import { APIResponse } from '../../../types/api'
import SubscriptionCode from '../../../interfaces/subscription-code'
import { Broker } from '../../../interfaces/broker'

export interface CancelSubscriptionRequest {
  cancelledBy: string;
  cancelledReason: string;
}

interface CreateResponse {
  subscriptionCode: SubscriptionCode;
}

interface GetByIdResponse {
  subscriptionCodes: SubscriptionCode[];
}

interface VerifyCodeRequest {
  pricingProgram: string;
  code: string;
}

interface VerifyCodeResponse {
  isValid: boolean;
  broker?: Broker;
}

interface ThunkResponse {
  isValid?: boolean;
  broker?: Broker;
  success: boolean;
  errorMsg?: Error;
}

export const createSubscriptionCode = (
): ThunkAction<Promise<{success: boolean; errorMsg?: string}>, unknown, unknown, SubscriptionCodesActionTypes> => {
  return async dispatch => {
    dispatch(createSubscriptionCodePending())
    try {
      const response = await post<APIResponse<CreateResponse>>(
        '/subscription-code'
      )

      const { value, ...rest } = response.data.subscriptionCode
      const formattedSubscriptionCode = {
        ...rest,
        value: value.toUpperCase()
      }

      dispatch(createSubscriptionCodeFulfilled(formattedSubscriptionCode))
      return { success: true }
    } catch (error) {
      dispatch(createSubscriptionCodeFailed(error))
      return { success: false, errorMsg: error }
    }
  }
}

export const getSubscriptionCodes = (id: string):
ThunkAction<Promise<{success: boolean; errorMsg?: string}>, unknown, unknown, SubscriptionCodesActionTypes> => {
  return async dispatch => {
    dispatch(getSubscriptionCodePending())
    try {
      const response = await get<APIResponse<GetByIdResponse>>(
        `subscription-plan/${id}/subscription-code`
      )

      const subscriptionCodes = response.data.subscriptionCodes.map((code) => {
        const { value, ...rest } = code
        return {
          ...rest,
          value: value.toUpperCase()
        }
      })
      dispatch(getSubscriptionCodeFulfilled(subscriptionCodes))
      return { success: true }
    } catch (error) {
      dispatch(getSubscriptionCodeFailed(error))
      return { success: false, errorMsg: error }
    }
  }
}

export const verifySubscriptionCode = (verifyRequest: VerifyCodeRequest):
ThunkAction<Promise<ThunkResponse>, unknown, unknown, SubscriptionCodesActionTypes> => {
  return async dispatch => {
    dispatch(verifySubscriptionCodePending())
    try {
      const { code, pricingProgram } = verifyRequest
      const response =
      await post<APIResponse<VerifyCodeResponse>>('subscription-code/verify', {
        code: code.toLowerCase(),
        pricingProgram
      })
      dispatch(verifySubscriptionCodeFulfilled(response.data.isValid))
      return { success: true, broker: response.data.broker, isValid: response.data.isValid }
    } catch (error) {
      dispatch(verifySubscriptionCodeFailed(error))
      return { success: false, errorMsg: error }
    }
  }
}
