import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { View } from 'native-base'
import { FlatList, useWindowDimensions } from 'react-native'
import { StackScreenProps } from '@react-navigation/stack'
import { theme } from '@hedgit/lib/theme'

import { getSubscriptionPlan } from '@hedgit/lib/store/modules/subscription-plans/thunks'
import { getSubscriptionCodes } from '@hedgit/lib/store/modules/subscription-codes'

import { Entity } from '@hedgit/lib/enums/entity'
import { PlanName } from '@hedgit/lib/enums/plan-name'
import { UserRole } from '@hedgit/lib/enums/user-role'
import { SubscriptionCodeStatus } from '@hedgit/lib/enums/subscription-code-status'

import { RootStackParamList } from '@hedgit/lib/interfaces/root-stack-params-list'

import { Header } from '@hedgit/lib/components/header'
import { EmptyCard } from '@hedgit/lib/components/cards/empty-card'
import { XpPlanCard } from '@hedgit/lib/components/cards/xp-plan-card'
import { UpgradeModal } from '@hedgit/lib/components/modals/upgrade-modal'
import { GenerateCodeCard } from '@hedgit/lib/components/cards/generate-code-card'
import { CodeCard } from '@hedgit/lib/components/cards/code-card'
import GoBackIcon from '@hedgit/lib/components/icons/go-back'
import SuspendedScreen from '@hedgit/lib/components/suspended-screen/suspended-screen'

import { useSelector, useThunkDispatch } from 'store'

import useSuspendedToast from 'hooks/use-suspended-toast'

import { Main, Footer, Content, Image, HeaderWrapper, Pressable, Title, Container, Spinner } from './styled'

type ScreenProps = StackScreenProps<RootStackParamList, 'Plan'>

const PlanScreen = ({ navigation }: ScreenProps) => {
  const subscriptionPlan = useSelector(state => state.subscriptionPlans.activeSubscriptionPlan)
  const isLoadingSubscriptionPlan = useSelector(state => state.subscriptionPlans.isFetching)
  const subscriptionCodes = useSelector(state => state.subscriptionCodes.list)
  const isLoading = useSelector(state => state.subscriptionCodes.isFetching)
  const [codesFetched, setCodesFetched] = useState(false)
  const [modalUpgrade, setModalUpgrade] = useState(false)
  const [sumCodes, setSumCodes] = useState(false)
  const [totalCodes, setTotalCodes] = useState<number>(0)
  const { height } = useWindowDimensions()
  const { isSuspended } = useSuspendedToast()

  const dispatch = useThunkDispatch()
  const { t } = useTranslation()

  useEffect(() => {
    const vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty('--vh', `${vh}px`)

    const listener = () => {
      const vh = window.innerHeight * 0.01
      const oldProperty = document.documentElement.style.getPropertyValue('--vh')
      if (vh < +oldProperty.slice(0, -2)) return
      document.documentElement.style.setProperty('--vh', `${vh}px`)
    }
    window.addEventListener('resize', listener)
    return () => window.removeEventListener('resize', listener)
  }, [])

  useEffect(() => {
    dispatch(getSubscriptionPlan())
  }, [dispatch])

  useEffect(() => {
    if (subscriptionPlan) {
      const fetchData = async () => {
        const { success } = await dispatch(getSubscriptionCodes(subscriptionPlan?.id))
        setCodesFetched(success)
      }
      fetchData()
    }
  }, [dispatch, subscriptionPlan])

  useEffect(() => {
    if (!sumCodes && codesFetched && (
      subscriptionPlan?.details.role === UserRole.broker_farmer ||
    subscriptionPlan?.details.role === UserRole.broker)) {
      const availableCodes = subscriptionCodes.filter((code) => code.status === SubscriptionCodeStatus.available).length
      setTotalCodes(subscriptionPlan.details.remainingCodes !== 'infinity'
        ? subscriptionPlan?.details.remainingCodes + availableCodes
        : 0)
      setSumCodes(true)
    }
  }, [sumCodes, subscriptionCodes, subscriptionPlan, isLoading, codesFetched])

  const footerVisible = height > 667

  if (isSuspended) {
    return (
      <Container>
        <HeaderWrapper>
          <Pressable testID='support-goBack-button' onPress={() => navigation.goBack()}>
            <GoBackIcon color={theme.colors.dark} />
          </Pressable>
          <Title>{t('Header.title')}</Title>
        </HeaderWrapper>
        <SuspendedScreen />
      </Container>
    )
  }

  return (
    <Main>
      <Header planType={subscriptionPlan?.planType.name as PlanName} />
      {
          isLoadingSubscriptionPlan
            ? (
              <Spinner />
              )
            : (
              <Content>
                {(subscriptionPlan && subscriptionPlan?.details.role === UserRole.broker_farmer) ||
              subscriptionPlan?.details.role === UserRole.broker
                  ? (
                    <View flex={1}>
                      <XpPlanCard
                        subscriptionPlan={subscriptionPlan}
                        availableCodes={
                      subscriptionPlan.details.remainingCodes === 'infinity'
                        ? 'infinity'
                        : totalCodes
                      }
                        onPress={() => setModalUpgrade(true)}
                      />
                      <GenerateCodeCard
                        remainingCodes={subscriptionPlan.details.remainingCodes}
                        disabledButton={isLoading}
                      />
                      <FlatList
                        data={subscriptionCodes}
                        contentContainerStyle={{ marginVertical: '5px', flex: 1 }}
                        renderItem={({ item }) => (
                          <CodeCard
                            value={item.value}
                            generationDate={item.generationDate}
                            expirationDate={item.expirationDate}
                            status={item.status}
                          />
                        )}
                      />
                    </View>
                    )
                  : (
                    <EmptyCard text={t('Empty.planType')} entity={Entity.planType} />
                    )}
              </Content>
              )
      }
      <UpgradeModal
        currentPlan={subscriptionPlan?.planType.name}
        visible={modalUpgrade}
        onPress={() => setModalUpgrade(false)}
      />
      {footerVisible && (
        <Footer>
          <Image source={require('@hedgit/lib/components/sidebar/assets/icons/hedgit-icon-sm.png')} />
        </Footer>
      )}
    </Main>
  )
}

export default PlanScreen
