import React, { useEffect, useState, useCallback } from 'react'
import { FormControl, WarningOutlineIcon } from 'native-base'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import { NativeStackScreenProps } from '@react-navigation/native-stack'
import { Controller, useForm } from 'react-hook-form'
import * as yup from 'yup'
import useCustomToast from '@hedgit/web/src/hooks/use-custom-toast'

import { createSubscriptionBrokerFarmer, getSubscriptions } from '@hedgit/lib/store/modules/subscriptions/thunks'

import { UserRole } from '@hedgit/lib/enums/user-role'

import { RootStackParamList } from '@hedgit/lib/interfaces/root-stack-params-list'

import Title from '@hedgit/lib/components/typography/title'
import { SharedInput } from '@hedgit/lib/components/shared/input'
import { Modal } from '@hedgit/lib/components/modals/basic-modal'

import { useSelector, useThunkDispatch } from 'store'

import { Container, InputContainer, RequirementText, Button } from './styled'

type Props = NativeStackScreenProps<RootStackParamList, 'AlgorithmSubscription'>
interface FormData {
  broker: string;
  tons: number;
}

const AlgorithmSubscription = ({ route, navigation }: Props) => {
  const { ppId, program } = route.params
  const { t } = useTranslation()

  const dispatch = useThunkDispatch()

  const currentUser = useSelector(state => state.auth.currentUser)
  const pricingProgram = useSelector(state => state.pricingPrograms.list)
  const isLoading = useSelector(state => state.subscriptions.isSubscriptionFetching)
  const selectedPricingProgram = pricingProgram.find(pp => pp.id === ppId)
  const steps = selectedPricingProgram?.algorithm.steps || 0
  const multiple = steps * 20
  const [modalVisible, setModalVisible] = useState(false)

  const { showToast } = useCustomToast()

  const formatPhoneNumber = (number: string | undefined) => {
    const formattedNumber = number?.replace(/[^\d]/g, '')
    return formattedNumber
  }

  const farmerBrokerValidation = yup.string()

  const validationSchema = yup.object({
    broker: farmerBrokerValidation,
    tons: yup
      .number()
      .required(t('ErrorMessages.tons.required'))
      .positive(t('ErrorMessages.tons.positive'))
      .integer(t('ErrorMessages.tons.integer'))
      .typeError(t('ErrorMessages.tons.typeError'))
      .test({
        name: 'tons-multiple-validation',
        test: async (value, testContext) => {
          if (value && (value % multiple === 0)) return true
          return testContext.createError({ message: t('ErrorMessages.tons.test') + `${multiple}` })
        }
      })
  })

  const {
    control,
    handleSubmit: handleSubmitForm,
    formState: { errors },
    reset,
    clearErrors
  } = useForm<FormData>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: {
      tons: undefined
    },
    resolver: yupResolver(validationSchema)
  })

  const formReset = useCallback(() => {
    reset({
      tons: undefined
    })
  }, [reset])

  useEffect(() => {
    dispatch(getSubscriptions())
    formReset()
  }, [dispatch, formReset])

  const handleSubmit = async (data: FormData) => {
    const { tons } = data
    const { success } = await dispatch(
      createSubscriptionBrokerFarmer({
        pricingProgram: ppId,
        tons: tons
      })
    )
    if (success) {
      setModalVisible(true)
      formReset()
      return
    }
    showToast(t('AlgorithmSubscription.subscriptionCode.error'), 'danger')
  }

  const handleModalClose = () => {
    navigation.navigate('NavigationTabs', { screen: 'PricingPrograms' })
  }

  return (
    <Container>
      <InputContainer>
        <FormControl isInvalid={'tons' in errors}>
          <Title testID="als-volume-label">{t('AlgorithmSubscription.form.volume.label')}</Title>
          <RequirementText>
            {t('AlgorithmSubscription.form.volume.requirement')}
            {multiple}
          </RequirementText>
          <Controller
            name="tons"
            control={control}
            render={({ field: { onChange, value, onBlur } }) => (
              <SharedInput
                testID="als-volume-input"
                placeholder={t('AlgorithmSubscription.form.volume.placeholder')}
                maxLength={20}
                value={formatPhoneNumber(value?.toString())}
                onChange={onChange}
                onFocus={() => clearErrors('tons')}
                onBlur={onBlur}
                withLabel={false}
                keyboardType="numeric"
              />
            )}
          />
          <FormControl.ErrorMessage testID="als-volumeError-message" leftIcon={<WarningOutlineIcon size="xs" />}>
            {errors.tons?.message}
          </FormControl.ErrorMessage>
        </FormControl>
      </InputContainer>
      <Button
        testID="als-subscribe-button"
        onPress={handleSubmitForm(handleSubmit)}
        disabled={isLoading}
        loading={isLoading}
      >
        {t('Components.button.subscribe')}
      </Button>
      <Modal
        title={
          currentUser?.role === UserRole.broker_farmer
            ? t('Components.modal.algorithmAutoSubscription.title') + program.toUpperCase()
            : t('Components.modal.algorithmSubscription.title') + program.toUpperCase()
        }
        variant="success"
        visible={modalVisible}
        buttons={[
          {
            label: t('Components.modal.button.ok'),
            onPress: handleModalClose,
            testID: 'ok-modal-button',
            variant: 'primary'
          }
        ]}
      />
    </Container>
  )
}
export default AlgorithmSubscription
