import styled from 'styled-components/native'
import styledWeb from 'styled-components'

import Caption from '../../../typography/caption'

export const Container = styled.View`
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border-radius: 10px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 20px #00000021;
  opacity: 1;
  width: 100%;
  max-width: 600px;
  align-self: center;
  flex: 1;
  height: 100%;
  overflow: scroll;
`

export const InputsContainer = styled.View`
  width: 100%;
  gap: 10px;
`

export const TermsAndConditionsContainer = styled.View`
`

export const TermsAndConditionsRow = styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

export const TermsAndConditionsText = styled(Caption)`
  color: ${props => props.theme.colors.gray};
`

export const TermsAndConditionsLink = styled(Caption)`
  color: ${props => props.theme.colors.primary};
  font-weight: bold;
`

export const Requirements = styled(Caption)`
  color: ${props => props.theme.colors.gray};
`

export const ButtonContainer = styled.View`
  width: 100%;
  gap: 20px;
`

export const Error = styled.Text`
  color: ${props => props.theme.colors.red};
`

export const AreaCodeAndPhoneContainer = styled.View`
  flex-direction: row;
  justify-content: space-between;
`

export const AreaCodeContainer = styled.View`
  width: 28%;
`

export const PhoneContainer = styled.View`
  width: 70%;
`

export const Br = styledWeb.br`
`

export const CustomErrorWrapper = styled.View`
  flex-direction: row;
  gap: 4px;
  margin-top: 8px;
  align-items: flex-start;
`

export const CustomErrorIconWrapper = styled.View`
  height: 1lh;
  justify-content: center;
  font-size: 12px;
`

export const CustomErrorText = styled.Text`
  color: ${({ theme }) => theme.colors.red};
  font-size: 12px;
  font-family: Lato;
`

export const TermsAndConditionsErrorWrapper = styled.View`
flex-direction: row;
gap: 4px;
align-items: flex-start;
`

export const TextContainer = styled.View`
  justify-content: flex-start;
  align-items: center;
  margin-left: 8px;
  height: 70px
`
