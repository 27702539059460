/* eslint-disable max-len */
import React from 'react'

import { IconItem } from '../../interfaces'

export interface IconListObject {
  [key: string]: IconItem;
}

const externalOpen = (URL: string) => window.open(URL, '_blank', 'noopener')

export const IconList: IconListObject = {
  whatsapp: {
    path: (
      <path d="M17.5 14.4l-2-1c-.3 0-.5-.1-.7.2l-1 1.1c-.1.2-.3.3-.6.1s-1.3-.5-2.4-1.5a9 9 0 01-1.7-2c-.1-.3 0-.5.2-.6l.4-.6c.2-.1.2-.3.3-.5v-.5L9 7c-.2-.6-.4-.5-.6-.5h-.6c-.2 0-.5 0-.8.4-.2.3-1 1-1 2.5s1 2.8 1.2 3c.2.2 2.1 3.2 5.1 4.5l1.7.6a4 4 0 001.9.2c.5-.1 1.7-.8 2-1.5.2-.6.2-1.2.1-1.4l-.5-.3M12 21.8a9.9 9.9 0 01-5-1.4l-.4-.2-3.7 1 1-3.7-.2-.3a9.9 9.9 0 01-1.5-5.3 9.9 9.9 0 0116.8-7 9.8 9.8 0 013 7 9.9 9.9 0 01-10 9.9m8.4-18.3A11.8 11.8 0 0012.1 0 12 12 0 001.8 17.8L0 24l6.4-1.6a11.9 11.9 0 005.6 1.4 12 12 0 0012-11.9 11.8 11.8 0 00-3.5-8.4z" />
    ),
    color: '#25D366',
    e: (l, t) => externalOpen(`https://api.whatsapp.com/send?text=${t} ${l}`)
  },
  mail: {
    path: (
      <path d="M20 4H4a2 2 0 00-2 2v12c0 1.1.9 2 2 2h16a2 2 0 002-2V6a2 2 0 00-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z" />
    ),
    color: '#E53E3E',
    e: (l, t) => externalOpen(`mailto:?body=${l}&subject=${t}`)
  },
  copy: {
    path: (
      <path d="M16 1H4a2 2 0 00-2 2v14h2V3h12V1zm3 4H8a2 2 0 00-2 2v14c0 1.1.9 2 2 2h11a2 2 0 002-2V7a2 2 0 00-2-2zm0 16H8V7h11v14z" />
    ),
    color: '#718096',
    e: (l) => navigator.clipboard.writeText(decodeURIComponent(l))
  }
}
