import React, { useEffect } from 'react'
import { Modal as RNModal, GestureResponderEvent, TouchableOpacity, Linking } from 'react-native'
import { useTranslation } from 'react-i18next'
import { useSelector, useThunkDispatch } from '@hedgit/web/src/store'

import { getSubscriptionCodes } from '@hedgit/lib/store/modules/subscription-codes'
import { getSubscriptionPlan } from '@hedgit/lib/store/modules/subscription-plans/thunks'

import { SubscriptionCodeStatus } from '@hedgit/lib/enums/subscription-code-status'

import Title from '@hedgit/lib/components/typography/title'

import {
  CenteredView,
  ModalView,
  ButtonContainer,
  ButtonsContainer,
  Button,
  BodyContainer,
  Body,
  TitleContent
} from './styled'

import BodyEmail from '../../typography/body-email'
import { UserRole } from '../../../enums/user-role'

export interface ModalProps {
  visible?: boolean;
  onPress?: (event: GestureResponderEvent) => void;
  onCloseModal?: () => void;
  onConfirm?: (event: GestureResponderEvent) => void;
}
const WarnSubscriptionModal = ({ onPress, visible, onCloseModal, onConfirm }: ModalProps) => {
  const { t } = useTranslation()
  const subscriptionPlan = useSelector(state => state.subscriptionPlans.activeSubscriptionPlan)
  const subscriptionCodes = useSelector(state => state.subscriptionCodes.list)
  const dispatch = useThunkDispatch()

  useEffect(() => {
    dispatch(getSubscriptionPlan())
  }, [dispatch])

  useEffect(() => {
    dispatch(getSubscriptionCodes(subscriptionPlan?.id))
  }, [dispatch, subscriptionPlan])

  const availableCodes = subscriptionCodes.filter(code => code.status === SubscriptionCodeStatus.available).length

  let availableSubscriptions: number | 'infinity' = availableCodes
  if (subscriptionPlan?.details.role === UserRole.broker_farmer) {
    if (subscriptionPlan?.details.remainingCodes === 'infinity') {
      availableSubscriptions = subscriptionPlan?.details.remainingCodes
    } else {
      availableSubscriptions += subscriptionPlan?.details.remainingCodes
    }
  }

  return (
    <RNModal
      animationType="fade"
      visible={visible}
      presentationStyle="overFullScreen"
      statusBarTranslucent={false}
      transparent
      onRequestClose={onCloseModal}
    >
      <CenteredView testID="warn-modal">
        <ModalView>
          <TitleContent>
            <Title>
              {availableSubscriptions
                ? t('Components.modal.warnModalConfirm.title')
                : t('Components.modal.warnModal.title')}
            </Title>
          </TitleContent>
          {!availableSubscriptions && (
            <BodyContainer>
              <Body>
                {t('Components.modal.upgradeModal.body')}
                <TouchableOpacity>
                  <BodyEmail style={{ color: '#33A3D9' }} onPress={() => Linking.openURL('mailto:soporte@hedgit.ai')}>
                    soporte@hedgit.ai
                  </BodyEmail>
                </TouchableOpacity>
              </Body>
            </BodyContainer>
          )}
          <ButtonsContainer>
            <ButtonContainer>
              <Button testID="warn-modal-button-cancel" onPress={onPress} variant="secondary">
                {availableSubscriptions ? t('Components.button.goBack') : t('Components.button.close')}
              </Button>
              {!availableSubscriptions
                ? null
                : (
                  <Button testID="warn-modal-button-confirm" onPress={onConfirm} variant="primary">
                    {t('Components.button.confirm')}
                  </Button>
                  )}
            </ButtonContainer>
          </ButtonsContainer>
        </ModalView>
      </CenteredView>
    </RNModal>
  )
}
export default WarnSubscriptionModal
