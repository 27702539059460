import styled from 'styled-components/native'

import { SharedButton } from '@hedgit/lib/components/shared/button'
import Body from '@hedgit/lib/components/typography/body'

export const CenteredView = styled.View`
  flex: 1;
  justify-content: center;
  align-items: center;
  background-color: #00000052;
`

export const ModalView = styled.View`
  background-color: white;
  border-radius: 10px;
  align-items: center;
  width: 94%;
  padding-top: 24px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 20px;
  margin-top: -100px;
  max-width: 500px;
`

export const CircleWrapper = styled.View`
  justify-content: center;
  align-items: center;
`

export const CircleBox = styled.View`
  position: relative;
  margin-top: 20px;
  height: 240px;
  width: 240px;
  justify-content: center;
  background-color: ${props => props.theme.colors.whiteLight};
  opacity: 0.1;
  z-index: -100;
  border-radius: 50%;
`

export const ExecuteBox = styled.View`
  align-items: center;
  justify-content: center;
  position: absolute;
`

export const Line = styled.View`
  border: 1px solid #D6D8DB;
  background-color: #D6D8DB;
  z-index: 2;
  width: 100%;
`

export const ReferenceText = styled(Body)`
  color: ${props => props.theme.colors.gray};
  margin-bottom: 4px;
`

export const ButtonsContainer = styled.View`
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
  gap: 16px;
`

export const ButtonContainer = styled.View`
  flex-direction: row;
  flex: 1;
  margin-top: 16px;
`

export const Button = styled(SharedButton)`
  flex: 1;
`
