import React from 'react'
import { t } from 'i18next'

import { CloseButtonProps } from '../interfaces'
import { ButtonClose } from '../styled'

export function CloseButton ({ onClose, closeText }: CloseButtonProps) {
  return (
    <ButtonClose aria-label="Close" type="button" onClick={onClose}>
      {closeText || t('Components.card.share.close')}
    </ButtonClose>
  )
}
