import styled from 'styled-components/native'

import { SharedButton } from '../../shared/button'
import Title from '../../typography/title'

export const CenteredView = styled.View`
  flex: 1;
  justify-content: center;
  align-items: center;
  background-color: #00000052;
`

export const ModalView = styled.View`
  display: flex;
  background-color: white;
  border-radius: 10px;
  width: 85%;
  padding: 0 16px 16px 16px;
  max-width: 500px;
  justify-content: center;
  align-items: center;
`

export const ButtonsContainer = styled.View`
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  gap: 16px;
`

export const ButtonContainer = styled.View`
  flex-direction: row;
  flex: 1;
  margin-top: 24px;
`

export const Button = styled(SharedButton)`
  flex: 1;
`

export const IconContainer = styled.View`
  display: flex;
  align-items: center;
  top: -24px;
`

export const BodyContainer = styled.View`
  align-content: center;
  justify-content: center;
  margin-top: 20px;
  text-align: center;
`

export const Body = styled.Text`
  font-size: 20px;
  font-weight: 600;
  font-family: Lato;
`

export const TitleModal = styled(Title)`
  text-align: center;
`
