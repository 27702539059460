import styled from 'styled-components/native'

import Title from '@hedgit/lib/components/typography/title'
import Button from '@hedgit/lib/components/typography/button-text'

import Caption from '../../typography/caption'
import Subtitle from '../../typography/subtitle'

interface ColorProps {
  color: string;
}

export const IconContainer = styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: 5px;
`

export const ContentContainer = styled.View<ColorProps>`
  border-radius: 5px;
  flex-direction: row;
  justify-content: center;
  background-color: ${props => props.color};
  margin-top: 6px;
`
export const CardContainer = styled.View`
  border-radius: 5px;
  flex-direction: column;
  margin-top: 6px;
`

export const CardContent = styled.View`
  flex: 5;
  margin: 5px
`

export const PlanNameTitle = styled(Title)`
  font-size: 25px;
  color: ${props => props.theme.colors.white};
`

export const UpgradeButton = styled.TouchableOpacity`
  border-radius: 10px;
  border: 1px;
  border-color: white;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.theme.colors.primary};
  width: 64px;
  height: 24px;
`

export const UpgradeButtonText = styled(Button)`
  color: ${props => props.theme.colors.white};
  font-size: 11px;
`

export const RemainingCodesContent = styled.View`
  flex-direction: row;
  align-items: center;
`

export const DetailsContent = styled.View`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-right: 2px;
  padding-left: 5px;
`

export const ContentText = styled(Subtitle)`
  font-size: 14px;
  margin-right: 20px;
  margin-left: 5px;
  font-weight: 500;
  color: ${props => props.theme.colors.white};
`

export const Circle = styled.View`
  min-width: 25px;
  max-height: 25px;
  border-radius: 12.5px;
  padding: 2px;
  background-color: ${props => props.theme.colors.white};
  align-items: center;
  justify-content: center;
`

export const CircleText = styled(Subtitle)<ColorProps>`
  font-size: 14px;
  font-weight: 500;
  color: ${props => props.color};
`

export const TextContainer = styled.View`
  justify-content: flex-start;
`

export const Text = styled(Caption)`
  width: 360px;
  font-size: 14px;
  font-weight: regular;
  margin: 5px;
`
