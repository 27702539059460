import { createSelector } from 'reselect'

import { SubscriptionStatus } from '@hedgit/lib/enums/subscription-status'

import { RootState } from 'store'

const selectFarmerList = (state: RootState) => state.pricingSignals.list

const selectPendingPricingSignalsFarmer = () => createSelector(
  selectFarmerList,
  (farmerList) =>
    farmerList.filter(signal => !signal.executionPrice && signal.subscription.status === SubscriptionStatus.active))

export default selectPendingPricingSignalsFarmer
