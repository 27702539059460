export enum SubscriptionStatus {
  active = 'active',
  deleted = 'deleted',
  cancelled = 'cancelled',
  pending = 'pending',
  rejected = 'rejected',
  pendingCancellation = 'pendingCancellation',
  rejectCancellation = 'rejectedCancellation',
  suspended = 'suspended',
}
