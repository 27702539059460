import { ActionType } from 'typesafe-actions'

import { SubscriptionCode } from '@hedgit/lib/interfaces/subscription-code'

import * as actions from './actions'

export enum SubscriptionCodesActionConsts {
  GET_SUBSCRIPTION_CODE_PENDING='GET_SUBSCRIPTION_CODE_PENDING',
  GET_SUBSCRIPTION_CODE_FULFILLED='GET_SUBSCRIPTION_CODE_FULFILLED',
  GET_SUBSCRIPTION_CODE_FAILED='GET_SUBSCRIPTION_CODE_FAILED',
  CREATE_SUBSCRIPTION_CODE_PENDING='CREATE_SUBSCRIPTION_CODE_PENDING',
  CREATE_SUBSCRIPTION_CODE_FULFILLED='CREATE_SUBSCRIPTION_CODE_FULFILLED',
  CREATE_SUBSCRIPTION_CODE_FAILED='CREATE_SUBSCRIPTION_CODE_FAILED',
  VERIFY_SUBSCRIPTION_CODE_PENDING='VERIFY_SUBSCRIPTION_CODE_PENDING',
  VERIFY_SUBSCRIPTION_CODE_FULFILLED='VERIFY_SUBSCRIPTION_CODE_FULFILLED',
  VERIFY_SUBSCRIPTION_CODE_FAILED='VERIFY_SUBSCRIPTION_CODE_FAILED',
}

export type SubscriptionCodesActionTypes = ActionType<typeof actions>

export interface SubscriptionCodesState {
  readonly list: SubscriptionCode[];
  readonly isFetching: boolean;
  readonly isValid: boolean;
  readonly error: string;
}
