import { combineReducers, compose, createStore, applyMiddleware, AnyAction } from 'redux'
import { useSelector as useSelectorRedux, useDispatch as useDispatchRedux, TypedUseSelectorHook } from 'react-redux'
import thunk, { ThunkDispatch } from 'redux-thunk'
import { ThunkAction } from '@hedgit/lib/types/redux-thunk'

import {
  authReducer,
  AuthActionTypes,
  AuthState
} from '@hedgit/lib/store/modules/auth'
import {
  subscriptionReducer,
  SubscriptionsActionTypes,
  SubscriptionsState
} from '@hedgit/lib/store/modules/subscriptions'
import {
  pricingProgramsReducer,
  PricingProgramsActionTypes,
  PricingProgramsState
} from '@hedgit/lib/store/modules/pricing-programs'
import {
  pricingSignalsReducer,
  PricingSignalsActionTypes,
  PricingSignalsState
} from '@hedgit/lib/store/modules/pricing-signals'
import {
  productsReducer,
  ProductsActionTypes,
  ProductsState
} from '@hedgit/lib/store/modules/products'
import {
  algorithmsReducer,
  AlgorithmsActionTypes,
  AlgorithmsState
} from '@hedgit/lib/store/modules/algorithms'
import {
  marketDataReducer,
  MarketDataActionTypes,
  MarketDataState
} from '@hedgit/lib/store/modules/market-data'
import {
  destinationsReducer,
  DestinationsActionTypes,
  DestinationsState
} from '@hedgit/lib/store/modules/destinations'
import {
  cropsReducer,
  CropsActionTypes,
  CropsState
} from '@hedgit/lib/store/modules/crops'
import {
  notificationsReducer,
  NotificationsActionTypes,
  NotificationsState
} from '@hedgit/lib/store/modules/notifications'
import {
  translationsReducer,
  TranslationsActionTypes,
  TranslationsState
} from '@hedgit/lib/store/modules/translations'
import {
  tutorialsReducer,
  TutorialsActionTypes,
  TutorialsState
} from '@hedgit/lib/store/modules/tutorials'
import {
  brokersReducer,
  BrokersActionTypes,
  BrokersState
} from '@hedgit/lib/store/modules/brokers'
import {
  uiReducer,
  UIActionTypes,
  UIState
} from '@hedgit/lib/store/modules/ui'
import {
  userReducer,
  UsersActionTypes,
  UsersState
} from '@hedgit/lib/store/modules/users'
import {
  subscriptionCodeReducer,
  SubscriptionCodesActionTypes,
  SubscriptionCodesState
} from '@hedgit/lib/store/modules/subscription-codes'
import {
  subscriptionPlansReducer,
  SubscriptionPlansActionTypes,
  SubscriptionPlansState
} from '@hedgit/lib/store/modules/subscription-plans'
import { SubscriptionOptionsState, subscriptionOptionsReducer } from '@hedgit/lib/store/modules/subscription-options'

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

export interface RootState {
  auth: AuthState;
  subscriptions: SubscriptionsState;
  pricingPrograms: PricingProgramsState;
  pricingSignals: PricingSignalsState;
  products: ProductsState;
  algorithms: AlgorithmsState;
  marketData: MarketDataState;
  destinations: DestinationsState;
  crops: CropsState;
  notifications: NotificationsState;
  translations: TranslationsState;
  tutorials: TutorialsState;
  brokers: BrokersState;
  users: UsersState;
  ui: UIState;
  subscriptionCodes: SubscriptionCodesState;
  subscriptionPlans: SubscriptionPlansState;
  subscriptionOptions: SubscriptionOptionsState;
}

export type RootAction =
  AuthActionTypes |
  UsersActionTypes |
  SubscriptionsActionTypes |
  PricingProgramsActionTypes |
  PricingSignalsActionTypes |
  ProductsActionTypes |
  AlgorithmsActionTypes |
  MarketDataActionTypes |
  DestinationsActionTypes |
  CropsActionTypes |
  NotificationsActionTypes |
  TranslationsActionTypes |
  TutorialsActionTypes |
  BrokersActionTypes |
  ThunkAction<void, RootState, unknown, AnyAction> |
  UIActionTypes |
  SubscriptionCodesActionTypes |
  SubscriptionPlansActionTypes;

const rootReducer = combineReducers<RootState>({
  auth: authReducer,
  subscriptions: subscriptionReducer,
  pricingPrograms: pricingProgramsReducer,
  pricingSignals: pricingSignalsReducer,
  products: productsReducer,
  algorithms: algorithmsReducer,
  marketData: marketDataReducer,
  destinations: destinationsReducer,
  crops: cropsReducer,
  notifications: notificationsReducer,
  translations: translationsReducer,
  tutorials: tutorialsReducer,
  brokers: brokersReducer,
  users: userReducer,
  ui: uiReducer,
  subscriptionCodes: subscriptionCodeReducer,
  subscriptionPlans: subscriptionPlansReducer,
  subscriptionOptions: subscriptionOptionsReducer
})

const enhancer = composeEnhancers(applyMiddleware(thunk))

export const generateStore = () => {
  const store = createStore(rootReducer, enhancer)
  return store
}

export const store = generateStore()

export const useSelector: TypedUseSelectorHook<RootState> = useSelectorRedux

type Dispatch = <TReturnType>(action: RootAction) => TReturnType
export const useDispatch = () => useDispatchRedux<Dispatch>()
export const useThunkDispatch = () => useDispatchRedux<ThunkDispatch<RootState, undefined, AnyAction>>()
