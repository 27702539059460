import styled from 'styled-components/native'

export const Container = styled.View`
  align-items: center;
  border-color: ${props => props.theme.colors.whiteLight};
  background-color: ${props => props.theme.colors.white};
  padding: 20px;
  gap: 20px;
  border-width: 1px;
  height: calc(100vh - 64px);
  overflow: scroll;
`
