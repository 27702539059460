import React, { useEffect } from 'react'
import { Modal as RNModal } from 'react-native'
import { FormControl, View } from 'native-base'
import { useTranslation } from 'react-i18next'
import { Controller, useForm, useFormState } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import Title from '@hedgit/lib/components/typography/title'

import {
  CenteredView,
  ModalView,
  IconContainer,
  ButtonsContainer,
  Button,
  InputLabel,
  ErrorBox,
  InputLabelBold
} from './styled'

import XIcon from '../../icons/x-icon'
import OkIcon from '../../icons/ok-icon'
import { SharedInput } from '../../shared/input'
import ErrorIcon from '../../icons/error'
import { theme } from '../../../theme'
import yup from '../../../utils/yup'

export interface ModalProps {
  title?: string;
  variant: 'confirm' | 'success' | 'error';
  visible?: boolean;
  onCloseModal?: () => void;
  inputText: string;
  requirementText: string;
  onCancelPress:() => void;
  onConfirmPress: () => void;
}

const { colors } = theme

const InputModal = ({
  title,
  variant,
  visible,
  onCloseModal,
  inputText,
  requirementText,
  onCancelPress,
  onConfirmPress
}: ModalProps) => {
  const { t } = useTranslation()

  const validationAlgorithmName = yup.object({
    algorithm: yup
      .string()
      .required(t('ErrorMessages.algorithmName.required'))
      .test({
        name: 'algorithm-name-validation',
        test: async (value, testContext) => {
          console.log(value, inputText?.trim())
          if (value === inputText?.trim()) return true
          return testContext.createError({ message: t('ErrorMessages.algorithmName.test') + `${inputText?.trim()}` })
        }
      })
  })

  const {
    control,
    reset,
    handleSubmit: handleSubmitForm,
    formState: { errors },
    clearErrors
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: {
      algorithm: ''
    },
    resolver: yupResolver(validationAlgorithmName)
  })

  const { isDirty } = useFormState({
    control
  })

  const isDisabled = !isDirty

  const handleSubmit = (data: Record <string, unknown>) => {
    try {
      if (data.algorithm === inputText?.trim()) {
        onConfirmPress()
      }
    } catch (e) {
      console.error(e)
    }
  }

  useEffect(() => {
    if (!visible) {
      reset()
    }
  }, [reset, visible])

  const handleInputFocus = () => {
    clearErrors()
  }

  const handleCancel = () => {
    reset()
    clearErrors()
    onCancelPress()
  }

  return (
    <RNModal
      animationType='fade'
      visible={visible}
      presentationStyle='overFullScreen'
      statusBarTranslucent={false}
      transparent
      onRequestClose={onCloseModal}
    >
      <CenteredView>
        <ModalView variant={variant}>
          {
              variant !== 'confirm'
                ? (
                  <IconContainer>
                    {variant === 'success' ? <OkIcon /> : null }
                    {variant === 'error' ? <XIcon /> : null }
                  </IconContainer>
                  )
                : null
            }
          <Title>{title}</Title>
          <View style={{ width: '100%' }}>
            <InputLabel testID="basicModal-label-input">
              {requirementText}
              {/* {t('Components.modal.pricingProgramDetails.farmer.cancelProgram.textToCancel')} */}
              <InputLabelBold>
                {inputText}
              </InputLabelBold>
            </InputLabel>
            <FormControl
              isInvalid={'algorithm' in errors}
            >
              <Controller
                name="algorithm"
                control={control}
                defaultValue=""
                render={({ field: { onChange, onBlur, value } }) => (
                  <SharedInput
                    testID="pp-cancelModal-input"
                    value={value}
                    required
                    withLabel={false}
                    keyboardType="default"
                    onChange={onChange}
                    onFocus={handleInputFocus}
                    onBlur={onBlur}
                  />
                )}
              />
              <ErrorBox>
                <FormControl.ErrorMessage
                  testID="basicModal-error-message"
                  leftIcon={<ErrorIcon width={14} height={14} color={colors.red} />}
                  _text={{
                    style: {
                      color: colors.red,
                      fontSize: 12,
                      fontFamily: 'Lato'
                    }
                  }}
                >
                  {errors.algorithm?.message}
                </FormControl.ErrorMessage>
              </ErrorBox>
            </FormControl>
          </View>
          <ButtonsContainer>
            <Button
              testID='pp-cancelModal-no-button'
              variant="secondary"
              onPress={handleCancel}
            >
              {t('Components.button.no')}
            </Button>
            <Button
              testID='pp-cancelModal-yes-button'
              variant="primary"
              disabled={isDisabled}
              onPress={handleSubmitForm(handleSubmit)}
            >
              {t('Components.button.yes')}
            </Button>
          </ButtonsContainer>
        </ModalView>
      </CenteredView>
    </RNModal>
  )
}

export default InputModal
