import React, { useCallback, useEffect, useState } from 'react'
import { View } from 'react-native'
import { createStackNavigator } from '@react-navigation/stack'
import { NavigationContainer, getFocusedRouteNameFromRoute } from '@react-navigation/native'
import type { HeaderTitleProps } from '@react-navigation/elements'
import { useTranslation } from 'react-i18next'
import type { RouteProp } from '@react-navigation/native'

import { toggleSidebar } from '@hedgit/lib/store/modules/ui'

import { GetMonthName } from '@hedgit/lib/utils/format'

import { UserRole } from '@hedgit/lib/enums/user-role'

import { RootStackParamList } from '@hedgit/lib/interfaces/root-stack-params-list'

import { SideBar } from '@hedgit/lib/components/sidebar'
import H2 from '@hedgit/lib/components/typography/h2'
import { HelpModal } from '@hedgit/lib/components/modals/help-modal'
import { LoadingScreen } from '@hedgit/lib/components/loading-screen'

import { RootState, useDispatch, useSelector } from 'store'

import { Notifications } from 'routes/notifications'
import { AlgorithmDetails } from 'routes/algorithms/details'
import { AlgorithmSubscription } from 'routes/algorithms/subscription'
import { PricingProgramDetails } from 'routes/pricing-programs/details'
import { Subscribers } from 'routes/pricing-programs/subscribers'
import Support from 'routes/support'
import Plan from 'routes/plans'
import SubscriptionCode from 'routes/subscription-code'
import { TutorialsList } from 'routes/tutorials/list'
import { TutorialsDetails } from 'routes/tutorials/details'
import { NavigationTabs } from 'routes/navigation-tabs'
import { AlgorithmSubscriptionOptions } from 'routes/algorithms/subscription-options'
import SubscriptionCodeValid from 'routes/algorithms/subscription-options/subscription-code/subscription-code-valid'
import SubscriptionXpPremium from
  'routes/algorithms/subscription-options/subscription-xp-premium/subscription-xp-premium'
import { ConfirmPhone } from 'routes/auth/confirm-phone'

import { PricingProgramDetailsGoBackButton } from '../../components/pricing-program-details-go-back-button'
import { HeaderLeftButton } from '../../components/header-left-button'
import { HeaderRightButton } from '../../components/header-right-button'
import { Settings } from '../settings'

type NavigationRouteProp = RouteProp<RootStackParamList, 'NavigationTabs'>
type NavigationPricingProgramDetailsRouteProp = RouteProp<RootStackParamList, 'PricingProgramDetails'>
type NavigationAlgorithmDetailsRouteProp = RouteProp<RootStackParamList, 'AlgorithmDetails'>
type NavigationAlgorithmSubscriptionRouteProp = RouteProp<RootStackParamList, 'AlgorithmSubscription'>
type NavigationAlgorithmSubscriptionOptionsRouteProp = RouteProp<RootStackParamList, 'AlgorithmSubscriptionOptions'>
type NavigationSubscriptionCodeValidRouteProp = RouteProp<RootStackParamList, 'SubscriptionCodeValid'>
type NavigationCodeSubscriptionRouteProp = RouteProp<RootStackParamList, 'SubscriptionCode'>;
type NavigationSubscriptionXpPremiumRouteProp = RouteProp<RootStackParamList, 'SubscriptionXpPremium'>

const Stack = createStackNavigator<RootStackParamList>()

const HeaderTitleStackScreen = (props: HeaderTitleProps) => <H2>{props.children}</H2>

const HeaderTitleTabScreen = (route: string) => <H2>{route}</H2>

const HeaderTitlePricingProgramDetailsScreen = (route: NavigationPricingProgramDetailsRouteProp) => {
  const crop = route.params.crop
  const month = GetMonthName(route.params.month)
  const year = route.params.year
  return <H2>{`${crop} ${month} ${year}`}</H2>
}

const HeaderTitleAlgorithmDetailsScreen = (route: NavigationAlgorithmDetailsRouteProp) => {
  const algorithm = route.params.name
  return <H2>{`${algorithm}`}</H2>
}

const HeaderTitleAlgorithmSubscriptionOptionsScreen = (route: NavigationAlgorithmSubscriptionOptionsRouteProp) => {
  const algorithm = route.params.name
  return <H2>{`${algorithm}`}</H2>
}

const HeaderTitleCodeSubscriptionScreen = (route: NavigationCodeSubscriptionRouteProp) => {
  const algorithm = route.params.name
  return (
    <H2>{`${algorithm}`}</H2>
  )
}

const HeaderTitleAlgorithmSubscriptionScreen = (route: NavigationAlgorithmSubscriptionRouteProp) => {
  const algorithm = route.params.algorithmName
  return <H2>{`${algorithm}`}</H2>
}
const HeaderTitleSubscriptionCodeValidScreen = (route: NavigationSubscriptionCodeValidRouteProp) => {
  const algorithm = route.params.name
  return <H2>{`${algorithm}`}</H2>
}

const HeaderTitleSubscriptionXpPremiumScreen = (route: NavigationSubscriptionXpPremiumRouteProp) => {
  const algorithm = route.params.name
  return <H2>{`${algorithm}`}</H2>
}

const GetHeaderTitle = (route: NavigationRouteProp) => {
  const { t } = useTranslation()

  const routeName = getFocusedRouteNameFromRoute(route) ?? 'Pricing Program'

  switch (routeName) {
    case 'PricingPrograms':
      return t('NavigationTabs.pricingPrograms')
    case 'Algorithms':
      return t('NavigationTabs.algorithms')
    case 'PricingSignals':
      return t('NavigationTabs.signals')
    default:
      return t('NavigationTabs.pricingPrograms')
  }
}

const AppNavigator = () => {
  const { t } = useTranslation()
  const sidebarOpen = useSelector(state => state.ui.sidebarOpen)
  const currentUser = useSelector((store: RootState) => store.auth.currentUser)
  const [modalProgramDetailVisible, setModalProgramDetailVisible] = useState(false)
  const [confirmPhoneScreen, setConfirmPhoneScreen] = useState<boolean | undefined>(undefined)

  const areaCode = currentUser?.areaCode
  const role = currentUser?.role

  const dispatch = useDispatch()

  const closeSidebar = useCallback(() => {
    dispatch(toggleSidebar())
  }, [dispatch])

  useEffect(() => {
    if (role) {
      setConfirmPhoneScreen(!areaCode && role === UserRole.farmer)
    }
  }, [areaCode, role])

  if (confirmPhoneScreen === undefined) {
    return (
      <LoadingScreen />
    )
  }

  return (
    <View style={{ height: '100%' }}>
      <NavigationContainer>
        <SideBar open={sidebarOpen} onLinkPressed={closeSidebar} />
        <Stack.Navigator
          initialRouteName={confirmPhoneScreen ? 'ConfirmPhone' : 'NavigationTabs'}
          screenOptions={{
            headerTitleAlign: 'center',
            headerTitle: HeaderTitleStackScreen,
            headerShadowVisible: false,
            headerLeft: HeaderLeftButton,
            headerRight: HeaderRightButton
          }}
        >
          <Stack.Screen
            name="NavigationTabs"
            component={NavigationTabs}
            options={({ route }) => ({
              headerTitle: () => HeaderTitleTabScreen(GetHeaderTitle(route as NavigationRouteProp))
            })}
          />
          <Stack.Screen
            name="Notifications"
            component={Notifications}
            options={() => ({
              headerTitle: () => HeaderTitleTabScreen(t('NavigationStack.notifications'))
            })}
          />
          <Stack.Screen
            name="AlgorithmDetails"
            component={AlgorithmDetails}
            options={({ route }) => ({
              headerTitle: () => HeaderTitleAlgorithmDetailsScreen(route as NavigationAlgorithmDetailsRouteProp)
            })}
          />
          <Stack.Screen
            name="AlgorithmSubscription"
            component={AlgorithmSubscription}
            options={({ route }) => ({
              headerTitle: () =>
                HeaderTitleAlgorithmSubscriptionScreen(route as NavigationAlgorithmSubscriptionRouteProp)
            })}
          />
          <Stack.Screen
            name="AlgorithmSubscriptionOptions"
            component={AlgorithmSubscriptionOptions}
            options={({ route }) => ({
              headerTitle: () =>
                HeaderTitleAlgorithmSubscriptionOptionsScreen(route as NavigationAlgorithmSubscriptionOptionsRouteProp)
            })}
          />
          <Stack.Screen
            name="Plan"
            component={Plan}
            options={{
              headerShown: false
            }}
          />
          <Stack.Screen
            name="PricingProgramDetails"
            component={PricingProgramDetails}
            options={({ route, navigation }) => ({
              headerTitle: () =>
                HeaderTitlePricingProgramDetailsScreen(route as NavigationPricingProgramDetailsRouteProp),
              headerLeft: () => PricingProgramDetailsGoBackButton({ route, navigation })
            })}
          />
          <Stack.Screen
            name="PricingProgramSubscribers"
            component={Subscribers}
            options={() => ({
              headerTitle: () => HeaderTitleTabScreen(t('Subscribers.title'))
            })}
          />
          <Stack.Screen
            name="Settings"
            component={Settings}
            options={{
              headerShown: false
            }}
          />
          <Stack.Screen
            name="SubscriptionCode"
            component={SubscriptionCode}
            options={({ route }) => ({
              headerTitle: () => HeaderTitleCodeSubscriptionScreen(route as NavigationCodeSubscriptionRouteProp)
            })}
          />
          <Stack.Screen
            name="Support"
            component={Support}
            options={{
              headerShown: false
            }}
          />
          <Stack.Screen
            name="TutorialsList"
            component={TutorialsList}
            options={{
              headerTitle: () => HeaderTitleTabScreen(t('Tutorials.headerTitle'))
            }}
          />
          <Stack.Screen name="TutorialsDetails" component={TutorialsDetails} options={{ headerShown: false }} />
          <Stack.Screen
            name="SubscriptionCodeValid"
            component={SubscriptionCodeValid}
            options={({ route }) => ({
              headerTitle: () =>
                HeaderTitleSubscriptionCodeValidScreen(route as NavigationSubscriptionCodeValidRouteProp)
            })}
          />
          <Stack.Screen
            name="SubscriptionXpPremium"
            component={SubscriptionXpPremium}
            options={({ route }) => ({
              headerTitle: () =>
                HeaderTitleSubscriptionXpPremiumScreen(route as NavigationSubscriptionXpPremiumRouteProp)
            })}
          />
          <Stack.Screen
            name="ConfirmPhone"
            component={ConfirmPhone}
            options={{
              headerShown: false
            }}
          />
        </Stack.Navigator>
        <HelpModal
          title={t('Components.modal.help.subscribe.title')}
          body={t('Components.modal.help.subscribe.body')}
          visible={modalProgramDetailVisible}
          buttons={[
            {
              label: t('Components.modal.button.ok'),
              onPress: () => setModalProgramDetailVisible(false),
              testID: 'help-modal-button',
              variant: 'primary'
            }
          ]}
        />
      </NavigationContainer>
    </View>
  )
}

export default AppNavigator
