import React from 'react'
import { Modal as RNModal, GestureResponderEvent } from 'react-native'
import { useTranslation } from 'react-i18next'

import {
  CenteredView,
  ModalView,
  ButtonContainer,
  IconContainer,
  ButtonsContainer,
  Button,
  BodyContainer,
  Body,
  TitleModal
} from './styled'

import CheckIcon from '../../icons/check-icon'

export interface ModalProps {
  visible?: boolean;
  brokerName?: string;
  algorithmName?: string;
  onPress?: (event: GestureResponderEvent) => void;
  onCloseModal?: () => void;
  isXpPremium?: boolean;
}

const SubscriptionSuccessModal = ({
  onPress, visible, onCloseModal, brokerName, algorithmName, isXpPremium
}: ModalProps) => {
  const { t } = useTranslation()

  return (
    <RNModal
      animationType="fade"
      visible={visible}
      presentationStyle="overFullScreen"
      statusBarTranslucent={false}
      transparent
      onRequestClose={onCloseModal}
    >
      <CenteredView testID="subscription-success-modal">
        <ModalView>
          <IconContainer>
            <CheckIcon />
          </IconContainer>
          <TitleModal>
            {t('Components.modal.subscriptionSuccess.firstPart')}
            {algorithmName}
            {isXpPremium
              ? (
                <>
                  {t('Components.modal.subscriptionSuccess.secondPart')}
                </>
                )
              : (
                <>
                  {t('Components.modal.subscriptionSuccess.secondPartPartner')}

                </>
                )}
          </TitleModal>
          <BodyContainer>
            <Body>
              {brokerName}
            </Body>
          </BodyContainer>
          <ButtonsContainer>
            <ButtonContainer>
              <Button testID="subscription-success-modal-button" onPress={onPress} variant="primary">
                {t('Components.button.ok')}
              </Button>
            </ButtonContainer>
          </ButtonsContainer>
        </ModalView>
      </CenteredView>
    </RNModal>
  )
}

export default SubscriptionSuccessModal
