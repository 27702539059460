/* eslint-disable max-len */
import React from 'react'
import { Svg, Path, G, Defs } from 'react-native-svg'

const CheckIcon = () => {
  return (
    <Svg width={54} height={54} viewBox="0 0 54 54">
      <G filter="url(#filter0_d_475_683)">
        <Path
          // eslint-disable-next-line max-len
          d="M3 24C3 10.7452 13.7452 0 27 0V0C40.2548 0 51 10.7452 51 24V24C51 37.2548 40.2548 48 27 48V48C13.7452 48 3 37.2548 3 24V24Z"
          fill="#D6EDF7"
        />
      </G>
      <Path
        d="M22.199 35.3027L10.999 24.1027L13.149 21.9527L22.199 31.0027L41.399 11.8027L43.549 13.9527L22.199 35.3027Z"
        fill="#33A3D9"
      />
      <Defs>
        <filter id="filter0_d_475_683" x="0" y="0" width="54" height="54" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy="3" />
          <feGaussianBlur stdDeviation="1.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.101961 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_475_683" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_475_683" result="shape" />
        </filter>
      </Defs>
    </Svg>
  )
}

export default CheckIcon
