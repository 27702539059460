import { action } from 'typesafe-actions'

import { SubscriptionPlan } from '@hedgit/lib/interfaces/subscription-plan'

import { SubscriptionPlanActionConsts } from './types'

export const getSubscriptionPlanPending = () =>
  action(SubscriptionPlanActionConsts.GET_SUBSCRIPTION_PLAN_PENDING)

export const getSubscriptionPlanFulfilled = (subscriptionPlan: SubscriptionPlan) =>
  action(SubscriptionPlanActionConsts.GET_SUBSCRIPTION_PLAN_FULFILLED, subscriptionPlan)

export const getSubscriptionPlanFailed = (error: string) =>
  action(SubscriptionPlanActionConsts.GET_SUBSCRIPTION_PLAN_FAILED, error)
