import React, { useState, useEffect, useMemo } from 'react'
import { View, TouchableOpacity } from 'react-native'
import { format, isAfter, isSameDay } from 'date-fns'
import { useTranslation } from 'react-i18next'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { useNavigation } from '@react-navigation/native'
import { theme } from '@hedgit/lib/theme'
import { APIResponse } from '@hedgit/lib/types/api'
import {
  getTranslation,
  TextTranslationProp
} from '@hedgit/lib/translations/getTranslation'

import { CancelSubscriptionRequest } from '@hedgit/lib/store/modules/subscriptions/thunks'
import { getPricingProgramsById } from '@hedgit/lib/store/modules/pricing-programs/thunks'
import { getMarketDataByPricingProgram } from '@hedgit/lib/store/modules/market-data'
import { getPricingSignalsBySubscription } from '@hedgit/lib/store/modules/pricing-signals/thunks'
import {
  cancelSubscriptionFailed,
  cancelSubscriptionFulfilled,
  cancelSubscriptionPending
} from '@hedgit/lib/store/modules/subscriptions'

import { patch } from '@hedgit/lib/utils/axios'
import { formatDate, GetMonthName } from '@hedgit/lib/utils/format'
import { getMarkToMarket } from '@hedgit/lib/utils/formulas/mark-to-market'

import { SubscriptionStatus } from '@hedgit/lib/enums/subscription-status'
import { PricingProgramStatus } from '@hedgit/lib/enums/pricing-program-status'
import { HistoricalDataFilter } from '@hedgit/lib/enums/filters'
import { UserRole } from '@hedgit/lib/enums/user-role'

import { RootStackParamList } from '@hedgit/lib/interfaces/root-stack-params-list'
import { Subscription } from '@hedgit/lib/interfaces/subscription'

import { HelpModal } from '@hedgit/lib/components/modals/help-modal'
import { PerformanceCard } from '@hedgit/lib/components/charts/pricing-programs/performance'
import { HistoricalPricesPricingProgramChart } from '@hedgit/lib/components/charts/pricing-programs/historical-prices'
import { PricingSignalPricingProgramChart } from '@hedgit/lib/components/charts/pricing-programs/pricing-signal'
import Question from '@hedgit/lib/components/icons/question'
import { SharedButton } from '@hedgit/lib/components/shared/button'
import { Modal } from '@hedgit/lib/components/modals/basic-modal'
import { InputModal } from '@hedgit/lib/components/modals/input-modal'

import { useFilteredMarketData } from './useFilteredMarketData'
import {
  ChartTitle,
  CardText,
  Content,
  SubContent,
  ButtonContainer
} from './styled'

import { useSelector, useDispatch } from '../../../store'

interface PendingCancellationResponse {
  subscription: Subscription;
}

type NavigationProps = NativeStackNavigationProp<
  RootStackParamList,
  'PricingProgramDetails'
>

interface Props {
  id: string;
  state: string;
}

const PricingProgramFarmerDetails = ({ id, state }: Props) => {
  const navigation = useNavigation<NavigationProps>()
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const [confirmModalVisible, setConfirmModalVisible] = useState(false)
  const [cancelledModalVisible, setCancelledModalVisible] = useState(false)
  const [modalPerformanceChartVisible, setModalPerformanceChartVisible] =
    useState(false)

  const [cancelledSubscription, setCancelledSubscription] =
    useState<Subscription>()

  const currentUser = useSelector(state => state.auth.currentUser)
  const farmerList = useSelector(state => state.subscriptions.farmerList)
  const userFirstName = useSelector(state => state.auth.currentUser?.firstName)
  const userLastName = useSelector(state => state.auth.currentUser?.lastName)
  const selectedSubscription = farmerList.find(
    subscription => subscription.id === id
  )
  const selectedPricingProgram = useSelector(state =>
    selectedSubscription
      ? state.pricingPrograms.pricingProgramDetails[
        selectedSubscription?.pricingProgram.id
      ]
      : null
  )

  const algorithmName =
    getTranslation(
      selectedPricingProgram?.algorithm.name as TextTranslationProp
    ) || ' '
  const cropName =
    getTranslation(
      selectedPricingProgram?.product.crop.name as TextTranslationProp
    ) || ' '
  const month = GetMonthName(selectedPricingProgram?.product.month || ' ')
  const year = selectedPricingProgram?.product.year.toString() || ' '

  const programName = cropName + ' ' + month + ' ' + year

  const marketData = useSelector(
    state =>
      state.marketData.listByPricingProgram[
        selectedSubscription?.pricingProgram.id || ''
      ] || []
  )

  const {
    filteredMarketData,
    setFilter,
    filter,
    startDate,
    endDate,
    ppStartMarketData,
    ppAvg,
    ppMinPrice,
    ppMaxPrice
  } = useFilteredMarketData(id)

  const marketDataValues = useMemo(
    () => filteredMarketData.map(mD => mD.value),
    [filteredMarketData]
  )

  const updateHistoricalPricesPPChartData = (filter: HistoricalDataFilter) => {
    setFilter(filter)
  }

  const pricingSignalsById = useSelector(
    state =>
      state.pricingSignals.pricingSignalsBySubscription[selectedSubscription?.id || ''] ||
      []
  )
  const pricingProgramSignalsExecutionPrice = pricingSignalsById.map(
    data => data.executionPrice
  )
  const pricingProgramSignalsExecutionDate = pricingSignalsById.map(
    data => data.date
  )
  const pricingProgramSteps = selectedPricingProgram?.algorithm.steps

  const isExecuted =
    selectedPricingProgram?.endDate &&
    isAfter(new Date(), new Date(selectedPricingProgram?.endDate))

  useEffect(() => {
    if (selectedSubscription?.pricingProgram) {
      dispatch(
        getMarketDataByPricingProgram(selectedSubscription?.pricingProgram.id)
      )
    }
    if (selectedSubscription) {
      dispatch(getPricingSignalsBySubscription(selectedSubscription?.id))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleCancelPress = () => {
    setConfirmModalVisible(false)
    setCancelledModalVisible(false)
  }

  const handleConfirmPress = async () => {
    let url = 'pending-cancellation'
    if (currentUser?.role === UserRole.broker_farmer) {
      url = 'auto-cancel-subscription'
    }
    const cancelSubscriptionBody: CancelSubscriptionRequest = {
      cancelledBy: `${userFirstName} ${userLastName}`,
      cancelledReason: 'Reason cancel subscription'
    }
    if (selectedSubscription) {
      try {
        dispatch(cancelSubscriptionPending())
        const response = await patch<APIResponse<PendingCancellationResponse>>(
          `/subscriptions/${id}/actions/${url}`,
          cancelSubscriptionBody
        )
        setConfirmModalVisible(false)
        setCancelledModalVisible(true)
        setCancelledSubscription(response.data.subscription)
      } catch (error) {
        dispatch(cancelSubscriptionFailed(error as string))
      }
    }
  }

  useEffect(() => {
    if (selectedSubscription) {
      dispatch(getPricingProgramsById(selectedSubscription.pricingProgram.id))
    }
  }, [dispatch, selectedSubscription])

  const subscriptionTime = selectedSubscription?.subscriptionTime
  const date = !subscriptionTime ? null : formatDate(new Date(subscriptionTime))
  const pricingSignalsTotalSteps = pricingProgramSteps || 1

  if (!selectedPricingProgram) return null

  if (!selectedSubscription) return null

  const benchmarkPrice = parseFloat(
    getMarkToMarket({
      marketPrice: marketData[marketData.length - 1]?.value || 0,
      signals: pricingSignalsById.map(s => s.referencePrice),
      steps: selectedPricingProgram.algorithm.steps
    }).toFixed(2)
  )

  const executionPrice = parseFloat(
    getMarkToMarket({
      marketPrice: marketData[marketData.length - 1]?.value || 0,
      signals: pricingSignalsById.map(s => s.executionPrice),
      steps: selectedPricingProgram.algorithm.steps
    }).toFixed(2)
  )

  const marketDataLastValue = marketData[marketData.length - 1]
  const marketDataPreviousValue = marketData[marketData.length - 2]

  const handleCancelledSubscription = () => {
    if (cancelledSubscription) {
      setCancelledModalVisible(false)
      dispatch(cancelSubscriptionFulfilled(cancelledSubscription))
      navigation.goBack()
    }
  }

  const ppStartDate = new Date(selectedPricingProgram.startDate)
  ppStartDate.setTime(ppStartDate.getTime() + ppStartDate.getTimezoneOffset() * 60 * 1000)

  const ppEndDate = new Date(selectedPricingProgram.endDate)
  ppEndDate.setTime(ppEndDate.getTime() + ppEndDate.getTimezoneOffset() * 60 * 1000)

  const pricingProgramSignalsReferencePrice = pricingSignalsById.map(
    data => data.referencePrice
  )

  const signals = filteredMarketData.map(mD => {
    const signal = pricingSignalsById.find(s =>
      isSameDay(new Date(s.date), new Date(mD.date))
    )

    if (signal) {
      return parseFloat(signal.referencePrice.toFixed(2))
    }
    return null
  })

  return (
    <Content>
      <SubContent>
        <View>
          <CardText>
            <ChartTitle>
              {t('Components.charts.titles.performance')}
              {' '}
              {format(ppStartDate, 'dd/MM/yy')}
              {' - '}
              {format(ppEndDate, 'dd/MM/yy')}
            </ChartTitle>
            <TouchableOpacity
              testID='pp-farmer-question-icon'
              onPress={() => setModalPerformanceChartVisible(true)}
            >
              <Question
                color={theme.colors.lightGray}
                width={24}
                height={24}
              />
            </TouchableOpacity>
          </CardText>
          <View style={{
            alignItems: 'center',
            paddingHorizontal: 16
          }}
          >
            <PerformanceCard
              destination={selectedPricingProgram.product.destination}
              broker={`${selectedSubscription.broker.firstName} ${selectedSubscription.broker.lastName}`}
              tons={selectedSubscription.tons}
              executionPrice={executionPrice || 0}
              lastMarketData={marketDataLastValue?.value || 0}
              subscriptionDate={date}
              subscriptionStatus={selectedSubscription.status}
              currency="USD"
              minPrice={ppMinPrice >= 0 ? ppMinPrice : 0}
              maxPrice={ppMaxPrice}
              avgPrice={ppAvg}
              benchmarkPrice={benchmarkPrice}
              algorithmTypeText={getTranslation(selectedPricingProgram.algorithm.name) || ''}
              coveragePercent={(pricingProgramSignalsExecutionPrice.length / pricingSignalsTotalSteps) * 100}
              received={pricingProgramSignalsExecutionPrice.length}
              total={pricingProgramSteps || 0}
              state={state as PricingProgramStatus}
            />
          </View>
        </View>
        <View>
          <PricingSignalPricingProgramChart
            state={state as PricingProgramStatus}
            steps={pricingProgramSteps || 0}
            data={pricingProgramSignalsReferencePrice || []}
            dates={pricingProgramSignalsExecutionDate || []}
            startDate={selectedSubscription.pricingProgram.startDate}
            endDate={selectedSubscription.pricingProgram.endDate}
          />
        </View>
        <View>
          <HistoricalPricesPricingProgramChart
            mDPreviousValue={marketDataPreviousValue?.value || 0}
            mDLastValue={parseFloat(marketDataLastValue?.value.toFixed(2)) || 0}
            data={marketDataValues}
            onFilter={updateHistoricalPricesPPChartData}
            filter={filter}
            startDate={startDate()}
            endDate={endDate}
            signals={signals}
            state={state as PricingProgramStatus}
            totalDataLength={marketData.length}
            ppStartMarketData={ppStartMarketData}
            pricingProgram={selectedSubscription?.pricingProgram}
            marketData={filteredMarketData}
          />
        </View>

        {[SubscriptionStatus.active, SubscriptionStatus.rejectCancellation].includes(selectedSubscription.status) &&
        !isExecuted
          ? (
            <ButtonContainer>
              <SharedButton
                testID="ppd-farmer-cancel-button"
                variant="primary"
                onPress={() => {
                  setConfirmModalVisible(true)
                }}
              >
                {t('Components.button.cancelProgram')}
              </SharedButton>

              <InputModal
                title={
                  t('Components.modal.pricingProgramDetails.farmer.cancelProgram.firstPart') +
                  programName.toUpperCase() +
                  t('Components.modal.pricingProgramDetails.farmer.cancelProgram.secondPart')
                }
                inputText={algorithmName}
                variant="error"
                requirementText={t('Components.modal.pricingProgramDetails.farmer.cancelProgram.textToCancel')}
                visible={confirmModalVisible}
                onCancelPress={handleCancelPress}
                onConfirmPress={handleConfirmPress}
              />
              <Modal
                title={
                  currentUser?.role === UserRole.broker_farmer
                    ? t('Components.modal.pricingProgramDetails.farmer.autoCancelledProgram.firstPart') +
                    programName.toUpperCase() +
                    t('Components.modal.pricingProgramDetails.farmer.autoCancelledProgram.secondPart')
                    : t('Components.modal.pricingProgramDetails.farmer.cancelledProgram.firstPart') +
                  programName.toUpperCase() +
                  t('Components.modal.pricingProgramDetails.farmer.cancelledProgram.secondPart')
                }
                variant="error"
                visible={cancelledModalVisible}
                buttons={[
                  {
                    label: t('Components.button.close'),
                    onPress: handleCancelledSubscription,
                    testID: 'close-modal-button',
                    variant: 'primary'
                  }
                ]}
              />
            </ButtonContainer>
            )
          : null}
        <HelpModal
          title={
            t(
              'Components.modal.pricingProgramDetails.farmer.performance.title'
            ) + programName.toUpperCase()
          }
          body={t(
            'Components.modal.pricingProgramDetails.farmer.performance.body'
          )}
          visible={modalPerformanceChartVisible}
          buttons={[
            {
              label: t('Components.modal.button.ok'),
              onPress: () => setModalPerformanceChartVisible(false),
              testID: 'help-modal-button',
              variant: 'primary'
            }
          ]}
        />
        <View style={{ height: '15vh' }} />
      </SubContent>
    </Content>
  )
}

export default PricingProgramFarmerDetails
