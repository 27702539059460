import styled from 'styled-components/native'

import Body from '@hedgit/lib/components/typography/body'
import Caption from '@hedgit/lib/components/typography/caption'
import Title from '@hedgit/lib/components/typography/title'

export const Content = styled.View`
  justify-content: center;
  flex: 1;
`

export const SubContent = styled.View`
  background-color: ${props => props.theme.colors.white};
  align-self: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  max-width: 600px;
`

export const Button = styled.TouchableOpacity`
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  width: 100%;
  font-size: 16px;
  font-weight: 600;
  color: ${props => props.theme.colors.white};
  background-color: ${props => props.theme.colors.lightBlue};
`

export const InputContainer = styled.View`
  margin-top: 30px;
  align-self: center;
  height: 80px;
  width: 100%;
`

export const InputBox = styled.View`
  display: flex;
  gap: 5px;
`

export const InputLabel = styled(Body)`
  text-align: center;
  margin: 15px 0 10px 0;
`

export const InputLabelBold = styled(Body)`
  font-weight: bold;
`

export const ErrorBox = styled.View`
  height: 30px;
  margin-bottom: 5px;
`

export const Container = styled.View`
  align-items: center;
  height: 100%;
  padding: 0 16px;
  gap: 4px;
  background-color: ${props => props.theme.colors.white};
`

export const RequirementText = styled(Caption)`
  color: ${props => props.theme.colors.gray};
  padding-left: 5px;
  margin-bottom: 10px;
`

export const Subtitle = styled.Text`
  margin-top: 0;
  padding-top: 0;
  font-weight: 800;
  font-family: 'Lato';
  text-align: center;
  color: ${props => props.theme.colors.dark};
`

export const Broker = styled.Text`
  margin: 10px;
  font-weight: 800;
  font-family: 'Lato';
  font-size: 24px;
  text-align: center;
  color: ${props => props.theme.colors.dark};
`

export const ButtonContainer = styled.TouchableOpacity`
  background-color: ${props => props.theme.colors.lightBlue};
  height: 52px;
  border-radius: 8px;
  flex-direction: row-reverse;
  align-items: center;
  width: 100%;
`
export const Image = styled.Image`
  width: 82px;
  height: 82px;
  margin-right: -15px;
`

export const ImageContainer = styled.View`
  align-content: center;
  position: absolute;
  margin-left: 10px;
`

export const ViewContent = styled.View`
  height: 597px;
  justify-content: space-between;
`

export const CardContent = styled.View`
  box-shadow: 0 3px 6px #0000001a;
  border-radius: 6px;
  max-width: 600px;
  color: #5a6371;
  padding-top: 16px;
  padding-right: 16px;
  padding-left: 17px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

export const TextBox = styled.View`
  align-items: flex-start;
  flex: 2;
`

export const CardTitle = styled(Title)`
  font-size: 25px;
  font-weight: 700;
  font-family: 'Lato';
  color: ${props => props.theme.colors.gray};
  align-self: center;
  text-align: center;
`

export const CardText = styled(Title)`
  font-weight: 600;
  font-size: 19px;
  align-self: center;
  text-align: center;
  width: 80%;
  margin: 15px;
`
