import React from 'react'
import { useTranslation } from 'react-i18next'
import { theme } from '@hedgit/lib/theme'
import { getDate } from '@hedgit/lib/translations/getTranslation'

import { SubscriptionStatus } from '@hedgit/lib/enums/subscription-status'
import { PricingProgramStatus } from '@hedgit/lib/enums/pricing-program-status'

import {
  PerformanceChart
} from '@hedgit/lib/components/charts/pricing-programs/performance/performance-chart'
import {
  CoverageRatioChart
} from '@hedgit/lib/components/charts/pricing-programs/performance/coverage-ratio'
import {
  SignalsReceived
} from '@hedgit/lib/components/charts/pricing-programs/performance/signals-received'
import TonsIcon from '@hedgit/lib/components/icons/tons'
import UserIcon from '@hedgit/lib/components/icons/user'
import PricingProgramIcon from '@hedgit/lib/components/icons/pricing-program'
import Calendar from '@hedgit/lib/components/icons/calendar'

import {
  CardText,
  Box,
  PerformanceChartContent,
  ChartGlobalContent,
  CoverageAndSignalContent,
  CoverageBox,
  LeftTextContent,
  TitleTextDetails,
  DataTextDetails,
  Card,
  CardContent,
  SmallIconBox,
  IconBox,
  getPPStatusColor,
  getStateIcon,
  getPPTextColor,
  getPPDataColor,
  getPPBPMarkerColor,
  getPPEPMarkerColor,
  getPPAPMarkerColor
} from './styled'

import ChartCircleIcon from '../../../icons/chart-circle-icon'
import Location from '../../../icons/location'
import { Destination } from '../../../../interfaces/destination'

interface PerformanceCardProps {
  destination: Destination;
  broker: string;
  tons: number;
  executionPrice: number;
  subscriptionDate: string | null;
  subscriptionStatus: SubscriptionStatus;
  currency: string;
  minPrice: number;
  maxPrice: number;
  avgPrice: number;
  benchmarkPrice: number;
  algorithmTypeText: string;
  coveragePercent: number;
  received: number;
  total: number;
  state: PricingProgramStatus;
  lastMarketData: number;
}

const PerformanceCard = ({
  destination,
  broker,
  tons,
  executionPrice,
  subscriptionDate,
  subscriptionStatus,
  currency,
  minPrice,
  maxPrice,
  avgPrice,
  benchmarkPrice,
  algorithmTypeText,
  coveragePercent,
  received,
  total,
  state,
  lastMarketData
} :PerformanceCardProps) => {
  const { t } = useTranslation()

  const color = getPPStatusColor(state as PricingProgramStatus)

  const icon = getStateIcon(state as PricingProgramStatus)

  const textColor = getPPTextColor(state as PricingProgramStatus)

  const dataColor = getPPDataColor(state as PricingProgramStatus)

  const BPColor = getPPBPMarkerColor(state as PricingProgramStatus)

  const EPColor = getPPEPMarkerColor(state as PricingProgramStatus)

  const APColor = getPPAPMarkerColor(state as PricingProgramStatus)

  const getStatusTranslation = (status: SubscriptionStatus) => {
    if (status === SubscriptionStatus.pendingCancellation) return t('Components.card.pendingCancellation')
    if (status === SubscriptionStatus.pending) return t('Components.card.pending')
    if (status === SubscriptionStatus.active) return t('Components.card.active')
    if (status === SubscriptionStatus.cancelled) return t('Components.card.cancelled')
    return null
  }

  return (
    <Card>
      <IconBox color={color}>
        <PricingProgramIcon color={color} />
      </IconBox>
      <ChartGlobalContent>
        <PerformanceChartContent>
          <PerformanceChart
            currency={currency}
            minPrice={minPrice}
            maxPrice={maxPrice}
            executionPrice={executionPrice}
            benchmarkPrice={benchmarkPrice}
            algorithmTypeText={algorithmTypeText}
            state={state}
            lastMarketData={lastMarketData}
          />
        </PerformanceChartContent>
        <CoverageAndSignalContent>
          <CoverageBox>
            <CoverageRatioChart
              coveragePercent={coveragePercent}
              state={state}
            />
          </CoverageBox>
          <Box>
            <SignalsReceived
              state={state}
              received={received}
              total={total}
            />
          </Box>
        </CoverageAndSignalContent>
      </ChartGlobalContent>
      <CardContent>
        <CardText>
          <LeftTextContent>
            <SmallIconBox>
              {icon}
            </SmallIconBox>
            <TitleTextDetails
              textColor={textColor}
            >
              {t('Components.card.status')}
            </TitleTextDetails>
          </LeftTextContent>
          <DataTextDetails
            textColor={dataColor}
          >
            {getStatusTranslation(subscriptionStatus)}
          </DataTextDetails>
        </CardText>
        <CardText>
          <LeftTextContent>
            <SmallIconBox>
              <Calendar
                color={theme.colors.lightGray}
                width={22}
                height={22}
              />
            </SmallIconBox>
            <TitleTextDetails
              textColor={textColor}
            >
              {t('Components.card.subscriptionDate')}
            </TitleTextDetails>
          </LeftTextContent>
          <DataTextDetails
            textColor={dataColor}
          >
            {getDate(subscriptionDate)}
          </DataTextDetails>
        </CardText>
        <CardText>
          <LeftTextContent>
            <SmallIconBox>
              <ChartCircleIcon
                color={EPColor}
                text='EP'
                shadowed={false}
                height={22}
                width={22}
                cx={11}
                cy={11}
                r={8}
                r2={10}
                fontS={8}
                tx={6}
                ty={14}
              />
            </SmallIconBox>
            <TitleTextDetails
              textColor={textColor}
            >
              {t('Components.card.executionPrice')}
            </TitleTextDetails>
          </LeftTextContent>
          <DataTextDetails
            textColor={dataColor}
          >
            {
              executionPrice ? `USD ${executionPrice}` : '-'
            }
          </DataTextDetails>
        </CardText>
        <CardText>
          <LeftTextContent>
            <SmallIconBox>
              <ChartCircleIcon
                color={BPColor}
                text='BP'
                shadowed={false}
                height={22}
                width={22}
                cx={11}
                cy={11}
                r={8}
                r2={10}
                fontS={8}
                tx={6}
                ty={14}
              />
            </SmallIconBox>
            <TitleTextDetails
              textColor={textColor}
            >
              {t('Components.card.benchmarkPrice')}
            </TitleTextDetails>
          </LeftTextContent>
          <DataTextDetails
            textColor={dataColor}
          >
            {
              benchmarkPrice ? `USD ${benchmarkPrice}` : '-'
            }
          </DataTextDetails>
        </CardText>
        <CardText>
          <LeftTextContent>
            <SmallIconBox>
              <ChartCircleIcon
                color={APColor}
                text='PM'
                shadowed={false}
                height={22}
                width={22}
                cx={11}
                cy={11}
                r={8}
                r2={10}
                fontS={8}
                tx={5}
                ty={14}
              />
            </SmallIconBox>
            <TitleTextDetails
              textColor={textColor}
            >
              {t('Components.card.avgPrice')}
            </TitleTextDetails>
          </LeftTextContent>
          <DataTextDetails
            textColor={dataColor}
          >
            {
              avgPrice ? `USD ${avgPrice.toFixed(2)}` : '-'
            }
          </DataTextDetails>
        </CardText>
        <CardText>
          <LeftTextContent>
            <SmallIconBox>
              <TonsIcon
                color={theme.colors.lightGray}
                width={19}
                height={19}
              />
            </SmallIconBox>
            <TitleTextDetails
              textColor={textColor}
            >
              {t('Components.card.tonsAbbr')}
            </TitleTextDetails>
          </LeftTextContent>
          <DataTextDetails
            textColor={dataColor}
          >
            {tons}
          </DataTextDetails>
        </CardText>
        <CardText>
          <LeftTextContent>
            <SmallIconBox>
              <UserIcon
                color={theme.colors.lightGray}
                width={19}
                height={19}
              />
            </SmallIconBox>
            <TitleTextDetails
              textColor={textColor}
            >
              {t('Components.card.broker')}
            </TitleTextDetails>
          </LeftTextContent>
          <DataTextDetails
            textColor={dataColor}
          >
            {broker}
          </DataTextDetails>
        </CardText>
        <CardText style={{ paddingBottom: 16 }}>
          <LeftTextContent>
            <SmallIconBox>
              <Location
                color={theme.colors.lightGray}
                width={19}
                height={19}
              />
            </SmallIconBox>
            <TitleTextDetails
              textColor={textColor}
            >
              {t('Components.card.destination')}
            </TitleTextDetails>
          </LeftTextContent>
          <DataTextDetails
            textColor={dataColor}
          >
            {destination.name}
          </DataTextDetails>
        </CardText>
      </CardContent>
    </Card>
  )
}

export default PerformanceCard
