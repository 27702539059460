/* eslint-disable max-len */
import React, { useCallback, useEffect } from 'react'
import {
  View,
  FlatList,
  ListRenderItem,
  ListRenderItemInfo
} from 'react-native'
import { useTranslation } from 'react-i18next'

import { getTutorials } from '@hedgit/lib/store/modules/tutorials/thunks'

import { Tutorial } from '@hedgit/lib/interfaces/tutorial'

import Title from '@hedgit/lib/components/typography/title'
import Body from '@hedgit/lib/components/typography/body'
import { Video } from '@hedgit/lib/components/video'
import { ContactInformation } from '@hedgit/lib/components/shared/contact-information'
import { ContactInformationType } from '@hedgit/lib/components/shared/contact-information/contact-information'

import { Container, SubContainer } from './styled'

import { useDispatch, useSelector, RootState } from '../../../store'

const TutorialsList = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const userRole = useSelector((state: RootState) => state.auth.currentUser?.role)

  const list = useSelector((state) => state.tutorials.list)

  useEffect(() => {
    dispatch(getTutorials())
  }, [dispatch])

  const renderVideo: ListRenderItem<Tutorial> = useCallback(
    (tutorial: ListRenderItemInfo<Tutorial>) => {
      return (
        <View style={{ height: '200px' }}>
          <Video
            videoSource={tutorial.item.src}
            videoPoster='https://firebasestorage.googleapis.com/v0/b/hedgit-51304.appspot.com/o/tutorials%2FScreen%20Shot%202022-08-11%20at%2014.20.09.png?alt=media&token=1b7f412e-a4bb-4ad8-be95-69dc50f39965'
            autoPlay={false}
            style={{ overflow: 'hidden', height: '200px' }}
          />
        </View>
      )
    },
    []
  )

  return (
    <Container>
      <SubContainer>
        <Title>{t('Tutorials.title')}</Title>
        <Body style={{ marginVertical: 10 }}>
          {t('Tutorials.description')}
        </Body>
        <FlatList
          testID='tu-tutorials-list-flatList'
          data={list}
          renderItem={renderVideo}
          keyExtractor={data => data.id}
        />
        <View
          style={{ marginTop: '30px' }}
        >
          <ContactInformation
            role={userRole}
            variant={ContactInformationType.faq}
            value="FAQ"
          />
        </View>
      </SubContainer>
    </Container>
  )
}

export default TutorialsList
