import React from 'react'
import { Pressable } from 'native-base'
import { theme } from '@hedgit/lib/theme'
import { useWindowDimensions } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { useTranslation } from 'react-i18next'

import { RootStackParamList } from '@hedgit/lib/interfaces/root-stack-params-list'

import GoBackIcon from '@hedgit/lib/components/icons/go-back'

import {
  Content,
  GradientContainer,
  HeaderBack,
  PlanInfo,
  Title,
  SubTitle,
  BackButtonContainer
} from './styled'

import { PlanName } from '../../enums/plan-name'

type NavigationType = NativeStackNavigationProp<RootStackParamList, 'Brokers'>

interface HeaderProps {
  planType: PlanName | undefined;
}
const Header = ({ planType }: HeaderProps) => {
  const navigation = useNavigation<NavigationType>()
  const { t } = useTranslation()
  const { height } = useWindowDimensions()

  const smallHeader = height <= 568
  const goBackDimension = smallHeader ? '20' : '36'

  return (
    <Content smallHeader={smallHeader}>
      <GradientContainer />
      <HeaderBack style={{ transform: 'translate(-50%, -51%)' } as unknown} />
      <BackButtonContainer>
        <Pressable testID='nav-bar-go-back' onPress={navigation.goBack}>
          <GoBackIcon color={theme.colors.dark} width={goBackDimension} height={goBackDimension} />
        </Pressable>
      </BackButtonContainer>
      <PlanInfo>
        <Title testID='sb-user-name-title' smallHeader={smallHeader}>
          {t('Header.title').toUpperCase()}
        </Title>
        <SubTitle smallHeader={smallHeader}>
          {planType}
        </SubTitle>
      </PlanInfo>
    </Content>
  )
}

export default Header
