import { createSelector } from 'reselect'
import { isBefore } from 'date-fns'

import { SubscriptionStatus } from '@hedgit/lib/enums/subscription-status'

import { RootState } from 'store'

const selectFarmerList = (state: RootState) => state.subscriptions.farmerList

const selectActivePricingProgramsFarmer = () => createSelector(
  selectFarmerList,
  (farmerList) => {
    return farmerList.filter(pp => {
      const activeSubscriptions =
        [
          SubscriptionStatus.active,
          SubscriptionStatus.pending,
          SubscriptionStatus.pendingCancellation,
          SubscriptionStatus.rejectCancellation,
          SubscriptionStatus.suspended
        ].includes(pp.status)
      const isNotFinished =
        isBefore(new Date(), new Date(pp.pricingProgram.endDate))
      return activeSubscriptions && isNotFinished
    }
    )
  })

export default selectActivePricingProgramsFarmer
