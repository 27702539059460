import React from 'react'
import { useForm, Controller, useFormState } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  Button,
  FormControl
} from 'native-base'
import { useTranslation } from 'react-i18next'
import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'

import { SignUpFarmerSchema } from '@hedgit/lib/utils/validations/user'

import { RootStackParamList } from '@hedgit/lib/interfaces/root-stack-params-list'

import {
  Container,
  InputsContainer,
  TermsAndConditionsContainer,
  TermsAndConditionsRow,
  TermsAndConditionsText,
  TermsAndConditionsLink,
  Requirements,
  ButtonContainer,
  Error,
  AreaCodeAndPhoneContainer,
  AreaCodeContainer,
  PhoneContainer,
  Br,
  CustomErrorWrapper,
  CustomErrorIconWrapper,
  CustomErrorText,
  TextContainer,
  TermsAndConditionsErrorWrapper
} from './styled'

import { SharedCheckbox } from '../../../shared/checkbox'
import { theme } from '../../../../theme'
import { AuthUser } from '../../../../interfaces/auth-user'
import { phoneRegex } from '../../../../utils/regex'
import { SharedInput } from '../../../shared/input'
import { SharedButton } from '../../../shared/button'
import H2 from '../../../typography/h2'
import ErrorIcon from '../../../icons/error'

interface ExtendedAuthUser extends AuthUser {
  termsAndConditions: boolean;
}

export interface SignUpProps {
  error?: string;
  isFetching?: boolean;
  onSubmit: (data: AuthUser) => void;
  clearError?: () => void;
}

export const formatNumber = (number: string) => {
  const formattedNumber = number.replace(/[^\d.]/g, '')
  return formattedNumber
}

type NavigationType = NativeStackNavigationProp<RootStackParamList, 'SignUp'>

const { colors } = theme

const FarmerSignUp = ({ onSubmit, error, clearError, isFetching }: SignUpProps) => {
  const { t } = useTranslation()
  const navigation = useNavigation<NavigationType>()

  const {
    control,
    handleSubmit: handleSubmitForm,
    formState: { errors },
    clearErrors,
    watch
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      areaCode: '',
      termsAndConditions: false
    },
    resolver: yupResolver(SignUpFarmerSchema)
  })

  const { isDirty } = useFormState({
    control
  })

  const isDisabled = !isDirty

  const handleSubmit = (data: ExtendedAuthUser) => {
    if (data.termsAndConditions) {
      try {
        const formatData = {
          ...data,
          phone: `+549${data.areaCode}${data.phone}`
        }
        return onSubmit(formatData)
      } catch (error) {
        return error
      }
    }
  }

  const handlePhoneInputFocus = () => {
    clearErrors('phone')
    clearError()
  }

  const areaCode = watch('areaCode')
  const phone = watch('phone')

  return (
    <Container>
      <InputsContainer>
        <H2
          style={{ textAlign: 'center', marginTop: 4, marginBottom: 36 }}
          testID="su-farmer-title-text"
        >
          {t('SignUpFarmer.createAccount')}
        </H2>
        <FormControl isInvalid={'firstName' in errors}>
          <Controller
            name="firstName"
            control={control}
            defaultValue=""
            render={({ field: { onChange, onBlur } }) => (
              <SharedInput
                testID="su-farmer-firstName-input"
                withLabel={false}
                onChange={onChange}
                onFocus={() => clearErrors('firstName')}
                onBlur={onBlur}
                keyboardType="default"
                placeholder={t('SignUpFarmer.form.firstName.placeholder')}
              />
            )}
          />
          <FormControl.ErrorMessage
            testID="su-farmer-firstName-error-message"
            leftIcon={<ErrorIcon width={14} height={14} color={colors.red} />}
            _text={{ style: { color: colors.red, fontSize: 12, fontFamily: 'Lato' } }}
          >
            {errors.firstName?.message}
          </FormControl.ErrorMessage>
        </FormControl>
        <FormControl isInvalid={'lastName' in errors}>
          <Controller
            name="lastName"
            control={control}
            defaultValue=""
            render={({ field: { onChange, onBlur } }) => (
              <SharedInput
                testID="su-farmer-lastName-input"
                withLabel={false}
                onChange={onChange}
                onFocus={() => clearErrors('lastName')}
                onBlur={onBlur}
                keyboardType="default"
                placeholder={t('SignUpFarmer.form.lastName.placeholder')}
              />
            )}
          />
          <FormControl.ErrorMessage
            testID="su-farmer-lastName-error-message"
            leftIcon={<ErrorIcon width={14} height={14} color={colors.red} />}
            _text={{ style: { color: colors.red, fontSize: 12, fontFamily: 'Lato' } }}
          >
            {errors.lastName?.message}
          </FormControl.ErrorMessage>
        </FormControl>
        <FormControl isInvalid={'email' in errors}>
          <Controller
            name="email"
            control={control}
            defaultValue=""
            render={({ field: { onChange, onBlur } }) => (
              <SharedInput
                testID="su-farmer-email-input"
                withLabel={false}
                onChange={onChange}
                onFocus={() => clearErrors('email')}
                onBlur={onBlur}
                keyboardType="email-address"
                placeholder={t('SignUpFarmer.form.email.placeholder')}
              />
            )}
          />
          <FormControl.ErrorMessage
            testID="su-farmer-email-error-message"
            leftIcon={<ErrorIcon width={14} height={14} color={colors.red} />}
            _text={{ style: { color: colors.red, fontSize: 12, fontFamily: 'Lato' } }}
          >
            {errors.email?.message}
          </FormControl.ErrorMessage>
        </FormControl>
        <AreaCodeAndPhoneContainer>
          <AreaCodeContainer>
            <FormControl isInvalid={'areaCode' in errors}>
              <Controller
                name="areaCode"
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <SharedInput
                    testID="su-farmer-area-code-input"
                    maxLength={
                      phone.length === 8
                        ? 2
                        : phone.length === 7
                          ? 3
                          : phone.length === 6 ? 4 : 4
                    }
                    withLabel={false}
                    value={formatNumber(value)}
                    onChange={onChange}
                    onFocus={() => clearErrors('areaCode')}
                    onBlur={onBlur}
                    keyboardType="numeric"
                    placeholder={t('SignUpFarmer.form.areaCode.placeholder')}
                  />
                )}
              />
              {'areaCode' in errors && (
              <CustomErrorWrapper testID="su-farmer-phone-error-message">
                <CustomErrorIconWrapper>
                  <ErrorIcon width={14} height={14} color={colors.red} />
                </CustomErrorIconWrapper>
                <CustomErrorText>
                  {errors.areaCode?.message}
                </CustomErrorText>
              </CustomErrorWrapper>
              )}
            </FormControl>
          </AreaCodeContainer>
          <PhoneContainer>
            <FormControl isInvalid={'phone' in errors}>
              <Controller
                name="phone"
                control={control}
                defaultValue=""
                rules={{ pattern: { value: phoneRegex, message: t('SignUpFarmer.form.phoneRegexError') } }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <SharedInput
                    leftComponent={15}
                    testID="su-farmer-phone-input"
                    maxLength={
                      areaCode.length === 2
                        ? 8
                        : areaCode.length === 3
                          ? 7
                          : areaCode.length === 4 ? 6 : 8
                    }
                    withLabel={false}
                    value={formatNumber(value)}
                    onChange={onChange}
                    onFocus={handlePhoneInputFocus}
                    onBlur={onBlur}
                    keyboardType="numeric"
                    placeholder={t('SignUpFarmer.form.phone.placeholder')}
                  />
                )}
              />
              <FormControl.ErrorMessage
                testID="su-farmer-phone-error-message"
                leftIcon={<ErrorIcon width={14} height={14} color={colors.red} />}
                _text={{ style: { color: colors.red, fontSize: 12, fontFamily: 'Lato' } }}
              >
                {errors.phone?.message}
              </FormControl.ErrorMessage>
            </FormControl>
          </PhoneContainer>
        </AreaCodeAndPhoneContainer>
        <Requirements>
          {t('SignUpFarmer.requirementsAreaCode.title')}
          <Br />
          {t('SignUpFarmer.requirementsAreaCode.noStart')}
        </Requirements>
        <Requirements>
          {t('SignUpFarmer.requirementsPhone.title')}
          <Br />
          {t('SignUpFarmer.requirementsPhone.have')}
          {
            areaCode.length === 2
              ? 8
              : areaCode.length === 3
                ? 7
                : areaCode.length === 4 ? 6 : 8
          }
          {' '}
          {t('SignUpFarmer.requirementsPhone.digits')}
          <Br />
          {t('SignUpFarmer.requirementsPhone.noStart')}
        </Requirements>
        <Error
          testID="su-farmer-errors-messages"
        >
          {error}
        </Error>
      </InputsContainer>
      <ButtonContainer>
        <TermsAndConditionsContainer>
          <FormControl isInvalid={'termsAndConditions' in errors}>
            <TermsAndConditionsRow>
              <Controller
                name="termsAndConditions"
                control={control}
                defaultValue={false}
                render={({ field: { onChange } }) => (
                  <SharedCheckbox
                    testID="su-farmer-terms-checkbox"
                    value="termsAndConditions"
                    onChange={value => {
                      onChange(value)
                      clearErrors('termsAndConditions')
                    }}
                    accessibilityLabel=""
                  />
                )}
              />
              <TextContainer>
                <TermsAndConditionsText>
                  {t('SignUpFarmer.termsAndConditionsText')}
                </TermsAndConditionsText>
                <Button
                  testID="su-farmer-terms-link"
                  variant="link"
                  onPress={() => navigation.navigate('TermsAndConditions')}
                >
                  <TermsAndConditionsLink>
                    {t('SignUpFarmer.termsAndConditionsLink')}
                  </TermsAndConditionsLink>
                </Button>
                {'termsAndConditions' in errors && (
                <TermsAndConditionsErrorWrapper testID="su-farmer-terms-and-conditions-error-message">
                  <CustomErrorIconWrapper>
                    <ErrorIcon width={14} height={14} color={colors.red} />
                  </CustomErrorIconWrapper>
                  <CustomErrorText>
                    {errors.termsAndConditions?.message}
                  </CustomErrorText>
                </TermsAndConditionsErrorWrapper>
                )}
              </TextContainer>
            </TermsAndConditionsRow>
          </FormControl>
        </TermsAndConditionsContainer>
        <SharedButton
          testID="su-farmer-signUp-button"
          variant="primary"
          onPress={handleSubmitForm(handleSubmit)}
          disabled={isDisabled}
          loading={isFetching}
        >
          {t('Components.button.signUp')}
        </SharedButton>
      </ButtonContainer>
    </Container>
  )
}

export default FarmerSignUp
