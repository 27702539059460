import styled from 'styled-components/native'

// import Title from '@hedgit/lib/components/typography/title'
import Caption from '@hedgit/lib/components/typography/caption'
import Button from '@hedgit/lib/components/typography/button-text'
import H3 from '@hedgit/lib/components/typography/h3'

export const Card = styled.View`
  background-color: #ffffff;
  width: 100%;
  height: 76px;
`
export const CardContent = styled.View`
  margin-right: 19px;
  margin-left: 19px;
  flex-direction: row;
  justify-content: space-between;
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-color: ${props => props.theme.colors.whiteMiddle};
  padding-top: 14px;
  padding-bottom: 14px;
`

export const SubscriberName = styled(H3)`
  font-size: 14px;
  padding-bottom: 6px;
`

export const SubscriberText = styled(Caption)`
  margin-top: -5px;
`

export const SeeBox = styled.View`
  margin-top: 8px;
  margin-right: -3px;
  border-radius: 10px;
  border-color: ${props => props.theme.colors.primary};
  border-width: 1px;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.theme.colors.white};
  width: 71px;
  height: 32px;
`

export const SeeText = styled(Button)`
  color: ${props => props.theme.colors.primary};
`

export const DetailsContent = styled.View`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 2px;
  gap: 16px;
`

export const DetailsText = styled.View`
`

export const UserIconContainer = styled.View`
  background-color: #33A3D9;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  justify-content: center;
  align-items: center;
  padding-bottom: 3px;
  margin-left: -3px;
`

export const UserIcon = styled.Text`
  font-size: 21px;
  color: #FFFFFF;
`

export const TonsText = styled.Text`
  font-weight: bold;
`

export const NewText = styled.Text`
  font-weight: bold;
  color: ${props => props.theme.colors.yellowDark};
`

export const ExpiredText = styled.Text`
  font-weight: bold;
  color: ${props => props.theme.colors.redDark};
`

export const CancelText = styled.Text`
  font-weight: bold;
  color: ${props => props.theme.colors.red};
`
