import styled from 'styled-components/native'

export const Container = styled.View`
  flex-direction: row;
  border-radius: 8px;
  overflow: hidden;
  margin-top: 8px;
  margin-bottom: 16px;
`

interface ButtonProps {
  isSelected: boolean;
}

export const Button = styled.TouchableOpacity<ButtonProps>`
  background-color: ${({ isSelected, theme }) => isSelected ? theme.colors.primary : theme.colors.whiteLight};
  color: ${({ theme }) => theme.colors.white};
  padding: 6px 8px;
`
