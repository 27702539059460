import React, { useState } from 'react'
import { ScrollView, TouchableOpacity, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import { format } from 'date-fns'
import { NativeStackScreenProps } from '@react-navigation/native-stack'
import { theme } from '@hedgit/lib/theme'
import { getTranslation, TextTranslationProp } from '@hedgit/lib/translations/getTranslation'
import useCustomToast from '@hedgit/web/src/hooks/use-custom-toast'

import { GetMonthName } from '@hedgit/lib/utils/format'

import { UserRole } from '@hedgit/lib/enums/user-role'

import { RootStackParamList } from '@hedgit/lib/interfaces/root-stack-params-list'

import QuestionIcon from '@hedgit/lib/components/icons/questions'
import { AveragePerformanceAlgorithmChart } from '@hedgit/lib/components/charts/algorithms/average-performance'
import { HistoricalPerformanceAlgorithmChart } from '@hedgit/lib/components/charts/algorithms/historical-performance'
import AlgorithmPerformanceIcon from '@hedgit/lib/components/icons/algorithm-performance'
import { HelpModal } from '@hedgit/lib/components/modals/help-modal'
import { WarnSubscriptionModal } from '@hedgit/lib/components/modals/warn-subscription-modal'
import { SecondGenAveragePerformance } from '@hedgit/lib/components/charts/algorithms/second-gen-average-performance'

import useSuspendedToast from 'hooks/use-suspended-toast'

import {
  Button, GraphContent, IconBox, ChartTitle,
  CardText, PerformanceCard, Content, SubContent, AveragePerformanceAlgorithmChartContainer
} from './styled'

import { useSelector } from '../../../store'

type Props = NativeStackScreenProps<RootStackParamList, 'AlgorithmDetails'>

const AlgorithmDetails = ({ route, navigation }: Props) => {
  const { id, name } = route.params
  const { t } = useTranslation()
  const { showToast } = useCustomToast()

  const [modalPerformanceChartVisible, setModalPerformanceChartVisible] = useState(false)
  const [modalHistoricalChartVisible, setModalHistoricalChartVisible] = useState(false)
  const [modalWarnVisible, setModalWarnVisible] = useState(false)
  const userRole = useSelector(state => state.auth.currentUser?.role)
  const currentUserGen = useSelector(state => state.subscriptionPlans?.activeSubscriptionPlan?.planType.gen)

  const { isSuspended, showSuspendedBrokerToast } = useSuspendedToast()

  const list = useSelector(state => state.pricingPrograms.list)
  const canSubscribe = useSelector(state =>
    [UserRole.farmer, UserRole.broker_farmer].includes(state.auth.currentUser?.role as UserRole)
  )

  const selectedPricingProgram = list.find(pp => pp.id === id)
  const algorithmDetails = useSelector(state =>
    state.algorithms.list.find(algo => algo.id === selectedPricingProgram?.algorithm.id)
  )

  const genSelectedProgram = selectedPricingProgram?.algorithm.gen
  const selectedProgramsSteps = selectedPricingProgram?.algorithm.steps

  const isSubscriptionDenied = !currentUserGen?.includes(genSelectedProgram ?? 1)

  const ppStartDate =
    selectedPricingProgram?.startDate && format(new Date(selectedPricingProgram?.startDate), 'dd/MM/yyyy')
  const ppEndDate = selectedPricingProgram?.endDate && format(new Date(selectedPricingProgram?.endDate), 'dd/MM/yyyy')

  const selectedAlgorithmHistoricalData = selectedPricingProgram?.algorithm.historicalData || []

  const sumPercentile = selectedAlgorithmHistoricalData.reduce((percentile, algorithm) => {
    return percentile + algorithm.algorithmPercentile
  }, 0)
  const averagePercentile = sumPercentile / selectedAlgorithmHistoricalData.length
  const lastTenYears =
    (algorithmDetails?.historicalData || []).filter(function (item) {
      return new Date().getFullYear() - parseInt(item.year) <= 10
    }) || []

  const algorithmHistoricalPrices = lastTenYears.map(hs => hs.algorithmAverageExecutedPrice)
  const algorithmLastYears = lastTenYears.map(ys => parseInt(ys.year))
  const algorithmHistoricalPercentile = lastTenYears.map(hs => hs.yearMedianPrice)

  const cropName = getTranslation(selectedPricingProgram?.product.crop.name as TextTranslationProp) || ' '
  const month = GetMonthName(selectedPricingProgram?.product.month || ' ')
  const year = selectedPricingProgram?.product.year.toString() || ' '

  const programName = cropName + ' ' + month + ' ' + year

  const isSecondGeneration = selectedPricingProgram?.algorithm.gen === 2

  const openModal = () => {
    setModalWarnVisible(true)
  }

  const closeModal = () => {
    setModalWarnVisible(false)
  }

  const handleConfirm = async () => {
    if (isSubscriptionDenied) {
      showToast(t('AlgorithmSubscription.subscriptionCode.errorGen'), 'danger')
      setModalWarnVisible(false)
      return
    }
    navigation.navigate('AlgorithmSubscription', {
      ppId: id,
      algorithmName: name,
      program: programName
    })
    setModalWarnVisible(false)
  }

  const handleSubscribe = () => {
    if (isSuspended) {
      showSuspendedBrokerToast()
    } else {
      if (userRole === UserRole.broker_farmer) {
        openModal()
      } else {
        navigation.navigate('AlgorithmSubscriptionOptions', {
          id: id,
          name: name
        })
      }
    }
  }

  return (
    <Content>
      <SubContent>
        <ScrollView style={{ backgroundColor: '#FFF' }}>
          <CardText>
            <ChartTitle>
              {t('AlgorithmDetails.chart.performance.title')}
              {ppStartDate}
              {' - '}
              {ppEndDate}
            </ChartTitle>
            <TouchableOpacity onPress={() => setModalPerformanceChartVisible(true)}>
              <QuestionIcon testID="alg-performance-question-icon" color={theme.colors.lightGray} />
            </TouchableOpacity>
          </CardText>
          <PerformanceCard>
            <IconBox>
              <AlgorithmPerformanceIcon color="#adb1b8" />
            </IconBox>
            {isSecondGeneration
              ? (
                <AveragePerformanceAlgorithmChartContainer>
                  <SecondGenAveragePerformance
                    averagePercentile={90}
                    legend={t('Components.modal.algorithmDetails.averagePerformanceFirstStart') +
                      selectedProgramsSteps + t('Components.modal.algorithmDetails.averagePerformanceFirstEnd')}
                  />
                  <SecondGenAveragePerformance
                    averagePercentile={70}
                    legend={t('Components.modal.algorithmDetails.averagePerformanceSecondStart') +
                    selectedProgramsSteps + t('Components.modal.algorithmDetails.averagePerformanceSecondEnd')}
                  />
                </AveragePerformanceAlgorithmChartContainer>
                )
              : (
                <GraphContent>
                  <AveragePerformanceAlgorithmChart
                    averagePercentile={averagePercentile}
                    legend={getTranslation(selectedPricingProgram?.algorithm.legend as TextTranslationProp) || ''}
                  />
                </GraphContent>
                )}
          </PerformanceCard>
          <CardText>
            <ChartTitle>
              {getTranslation(selectedPricingProgram?.algorithm.name as TextTranslationProp)}
              {' '}
              vs
              {' '}
              {t('AlgorithmDetails.chart.historical.title')}
            </ChartTitle>
            <TouchableOpacity onPress={() => setModalHistoricalChartVisible(true)}>
              <QuestionIcon testID="alg-historical-question-icon" color={theme.colors.lightGray} />
            </TouchableOpacity>
          </CardText>
          <HistoricalPerformanceAlgorithmChart
            algorithm={getTranslation(selectedPricingProgram?.algorithm.name as TextTranslationProp) || ''}
            data={algorithmHistoricalPrices}
            dates={algorithmLastYears}
            data2={algorithmHistoricalPercentile}
          />
          {canSubscribe
            ? (
              <Button
                style={{
                  top: 35
                }}
                testID="ald-farmer-subscribe-button"
                onPress={handleSubscribe}
              >
                {t('Components.button.subscribe')}
              </Button>
              )
            : null}
          <HelpModal
            title={t('Components.modal.algorithmDetails.performanceChart.title') + programName.toUpperCase()}
            body={t('Components.modal.algorithmDetails.performanceChart.body')}
            visible={modalPerformanceChartVisible}
            buttons={[
              {
                label: t('Components.button.ok'),
                onPress: () => setModalPerformanceChartVisible(false),
                testID: 'help-modal-button',
                variant: 'primary'
              }
            ]}
          />
          <HelpModal
            title={t('Components.modal.algorithmDetails.historicalChart.title') + programName.toUpperCase()}
            body={t('Components.modal.algorithmDetails.historicalChart.body')}
            visible={modalHistoricalChartVisible}
            buttons={[
              {
                label: t('Components.button.ok'),
                onPress: () => setModalHistoricalChartVisible(false),
                testID: 'help-modal-button',
                variant: 'primary'
              }
            ]}
          />
          <View style={{ height: '15vh' }} />
          {userRole === UserRole.broker_farmer &&
          <WarnSubscriptionModal visible={modalWarnVisible} onPress={closeModal} onConfirm={handleConfirm} />}
        </ScrollView>
      </SubContent>
    </Content>
  )
}

export default AlgorithmDetails
